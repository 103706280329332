import { useIntl } from 'react-intl'
import { useAuth } from '../../../context/Auth'
import { Agency } from '../../../interfaces/Agecies'
import { AgencyFilters } from './AgencyFilters'

type Props = {
  startDate: string
  setStartDate: (date: string) => void
  endDate: string
  setEndDate: (date: string) => void
  fetchData: () => void
  agencies?: Agency[]
} & React.ComponentProps<typeof AgencyFilters>

export const DashboardFilters = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  fetchData,
  agencies,
  selectedObject,
  onSelectedObject
}: Props) => {
  const intl = useIntl()
  // @ts-ignore
  const [{ token } = {}] = useAuth()

  const checkDate = (date: Date): boolean => {
    return !isNaN(date.getTime())
  }

  return (
    <div
      className="modal fade"
      id="filtersModal"
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title text-2xl font-semibold text-gray-700 light:text-gray-200">
              {intl.formatMessage({ id: 'DASHBOARD.FILTERS' })}
            </h1>
          </div>
          <div className="modal-body">
            <form className="form">
              <div className="form-group column">
                <label className="col-form-label col-lg-3 col-sm-12">
                  {intl.formatMessage({ id: 'DASHBOARD.START_DATE' })}
                </label>
                <div className="col-12">
                  <input
                    value={startDate}
                    type="date"
                    className="form-control-lg"
                    max={endDate}
                    onChange={e => {
                      const newStartDate = new Date(e.target.value)
                      if (checkDate(newStartDate)) {
                        setStartDate(e.target.value)
                      }
                    }}
                  />
                </div>
              </div>
              <div className="form-group column">
                <label className="col-form-label col-lg-3 col-sm-12">
                  {intl.formatMessage({ id: 'DASHBOARD.END_DATE' })}
                </label>
                <div className="col-12">
                  <input
                    value={endDate}
                    type="date"
                    className="form-control-lg"
                    min={startDate}
                    onChange={e => {
                      const newEndDate = new Date(e.target.value)
                      if (checkDate(newEndDate)) {
                        setEndDate(e.target.value)
                      }
                    }}
                  />
                </div>
              </div>
              {agencies && (
                <div className="form-group column">
                  <label className="col-form-label col-lg-3 col-sm-12">
                    {intl.formatMessage({ id: 'DASHBOARD.AGENCIES' })}
                  </label>
                  <AgencyFilters
                    agencies={agencies}
                    onSelectedObject={onSelectedObject}
                    selectedObject={selectedObject}
                  />
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold"
              data-bs-dismiss="modal">
              {intl.formatMessage({ id: 'COMMON.CLOSE' })}
            </button>
            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              data-bs-dismiss="modal"
              onClick={fetchData}>
              {intl.formatMessage({ id: 'COMMON.SAVE' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
