// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useAuth } from '../../../context/Auth'
import { create } from '../../../lib/fetch/agencies'
import { useLoading } from '../../../context/LoadingContext'
import { Navigate, useNavigate } from 'react-router-dom'
import NuovaSedePresentational from './NuovaSedePresentational'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'

function NuovaSede({ currentPermissions, location }) {
  const canWrite = currentPermissions.can('write', 'nets')
  const { showLoading, hideLoading } = useLoading()
  const [auth = {}] = useAuth()
  const { token } = auth
  const formContext = useForm()
  const intl = useIntl()
  const alert = useAlert()
  const history = useNavigate()

  async function onSubmit(values) {
    showLoading()
    const { error } = await create({
      data: values,
      token
    })

    hideLoading()
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    formContext.reset()
    history(-1)
  }

  useEffect(() => {
    document.title = 'Control Room - Nuova sede'
  }, [])

  if (!canWrite) {
    let pathname = '/'
    if (location && location.pathname) {
      pathname = location.pathname.split('/')
      pathname.pop()
      pathname = pathname.join('/')
    }
    return <Navigate push={false} to={{ pathname }} />
  }

  return (
    <FormProvider {...formContext}>
      <NuovaSedePresentational
        currentPermissions={currentPermissions}
        onSubmit={onSubmit}
      />
    </FormProvider>
  )
}

export default NuovaSede
