import React from 'react'
import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate, useParams } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from '../modules/auth'
import GestisciBackoffice from '../pages/Agenzia/Backoffice'
import GestisciCanaliVendita from '../pages/Agenzia/CanaliCategorie'
import GestisciImpostazioni from '../pages/Agenzia/Impostazioni'
import NuovoUtente from '../pages/Agenzia/NuovoUtente'
import ModificaUtente from '../pages/Agenzia/ModificaUtente'
import NuovoAdmin from '../pages/Agenzia/Backoffice/NuovoAdmin'
import ModificaAdmin from '../pages/Agenzia/Backoffice/ModificaAdmin'
import NuovoCanale from '../pages/Agenzia/NuovoCanale'
import PosizioniFiscali from '../pages/Rete/PosizioniFiscali'
import ListaQualificheRuoli from '../pages/Rete/ListaQualificheRuoli'
import NuovaQualifica from '../pages/Rete/NuovaQualifica'
import NuovoRuolo from '../pages/Rete/NuovoRuolo'
import ModificaQualifica from '../pages/Rete/ModificaQualifica'
import ModificaRuolo from '../pages/Rete/ModificaRuolo'
import Sedi from '../pages/Rete/Sedi'
import NuovaSede from '../pages/Rete/NuovaSede'
import ModificaSede from '../pages/Rete/ModificaSede'
import NavigaStruttura from '../pages/Rete/NavigaStruttura'
import ModificaCanale from '../pages/Agenzia/ModificaCanale'
import Partner from '../pages/Catalogo/Partner'
import NuovoPartner from '../pages/Catalogo/NuovoPartner'
import ModificaPartner from '../pages/Catalogo/ModificaPartner'
import NuovaOfferta from '../pages/Catalogo/NuovaOfferta'
import ModificaOfferta from '../pages/Catalogo/ModificaOfferta'
import NuovaAnagrafica from '../pages/Contratti/NuovaAnagrafica'
import EsitiLavorazione from '../pages/Contratti/EsitiLavorazione'
import NuovoEsito from '../pages/Contratti/EsitiLavorazione/NuovoEsito'
import ElaborazioneDati from '../pages/Contratti/ElaborazioneDati'
import EstrattiConto from '../pages/EstrattoConto/EstrattiConto'
import NuovoEstratto from '../pages/EstrattoConto/NuovoEstratto'
import DettaglioEstratto from '../pages/EstrattoConto/DettaglioEstratto'
import PianiCompenso from '../pages/EstrattoConto/PianiCompenso'
import ModificaPianoCompenso from '../pages/EstrattoConto/ModificaPianoCompenso'
import OfferteArticoli from '../pages/Catalogo/OfferteArticoli'
import { GestisciAgentiListWrapper } from '../pages/Agenzia/GestisciAgenti/gestisci-agenti-list/GestisciAgentiList'
import WorkflowsPage from '../pages/Contratti/Workflows'
import NewWorkflowPage from '../pages/Contratti/Workflows/NewWorkflow'
import Contract from '../pages/contracts/Contract'
import NewContract from '../pages/contracts/NewContract'
import Contracts from '../pages/contracts/Contracts'
import EditUser from '../pages/Anagrafica/users-list/EditUser'
import { UsersListWrapper } from '../pages/Anagrafica/users-list/Customers'
import NewUser from '../pages/Anagrafica/users-list/NewUser'
import EditWorkflowPage from '../pages/Contratti/Workflows/EditWorkflow'

const getPolicies = (policies: any, isAdmin: boolean): any => {
  // const profilePermissionsWithName = policies.map(
  //   (profilePermission: any) => {
  //     const { policies } = profilePermission
  //     const { name, section, read, write } = policies
  //     return {
  //       name,
  //       sections: section.split(','),
  //       read,
  //       write
  //     }
  //   }
  // )
  return {
    isAdmin: true,
    can: (action: any, permission: any) => true
  }
}

const RedirectCustomer = () => {
  const { customerId } = useParams()
  return <Navigate to={`/crafted/pages/profile/edit/${customerId}`} />
}

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const { policies, currentUser, user } = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route
          path="agenzia/gestisci-agenti"
          element={
            <SuspensedView>
              <GestisciAgentiListWrapper
                currentPermissions={getPolicies(
                  policies,
                  currentUser?.isAdmin ? currentUser?.isAdmin : false
                )}
              />
            </SuspensedView>
          }
        />
        <Route
          path="agenzia/gestisci-agenti/nuovo-utente"
          element={
            <NuovoUtente
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="agenzia/gestisci-agenti/modifica-utente/:id"
          element={
            <ModificaUtente
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="agenzia/back-office"
          element={
            <GestisciBackoffice
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="agenzia/back-office/nuovo-utente"
          element={
            <NuovoAdmin
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="agenzia/back-office/modifica-utente/:id"
          element={
            <ModificaAdmin
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="agenzia/canali-vendita"
          element={
            <GestisciCanaliVendita
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="agenzia/canali-vendita/nuovo-canale"
          element={
            <NuovoCanale
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="agenzia/canali-vendita/modifica-canale/:id"
          element={
            <ModificaCanale
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="agenzia/impostazioni"
          element={
            <GestisciImpostazioni
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />

        <Route
          path="rete/agenti"
          element={
            <SuspensedView>
              <GestisciAgentiListWrapper
                currentPermissions={getPolicies(
                  policies,
                  currentUser?.isAdmin ? currentUser?.isAdmin : false
                )}
              />
            </SuspensedView>
          }
        />
        <Route
          path="rete/posizioni-fiscali"
          element={
            <PosizioniFiscali
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />

        <Route
          path="rete/lista-qualifiche"
          element={
            <ListaQualificheRuoli
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="rete/nuova-qualifica"
          element={
            <NuovaQualifica
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="rete/nuovo-ruolo"
          element={
            <NuovoRuolo
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="rete/modifica-qualifica/:id"
          element={
            <ModificaQualifica
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="rete/modifica-ruolo/:id"
          element={
            <ModificaRuolo
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="rete/lista-sedi"
          element={
            <Sedi
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="rete/nuova-sede"
          element={
            <NuovaSede
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="rete/modifica-sede/:id"
          element={
            <ModificaSede
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route path="rete/naviga-struttura" element={<NavigaStruttura />} />

        <Route
          path="catalogo/partner"
          element={
            <Partner
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="catalogo/partner/nuovo-partner"
          element={
            <NuovoPartner
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="catalogo/partner/modifica-partner/:id"
          element={
            <ModificaPartner
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="catalogo/offerte-articoli"
          element={
            <OfferteArticoli
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="catalogo/offerte-articoli/nuova-offerta"
          element={
            <NuovaOfferta
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="catalogo/offerte-articoli/modifica-offerta/:id"
          element={
            <ModificaOfferta
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />

        <Route path="customers/:customerId" element={<RedirectCustomer />} />

        <Route path="contracts" element={<Contracts />} />
        <Route
          path="contracts/new"
          element={
            <NuovaAnagrafica
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route path="contracts/new/:customerId" element={<NewContract />} />
        <Route path="contracts/:contractId" element={<Contract />} />

        <Route
          path="contratti/nuova-anagrafica"
          element={
            <NuovaAnagrafica
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route path="contratti/contratti-vendite" element={<Contracts />} />
        <Route
          path="contratti/esiti-lavorazione"
          element={
            <EsitiLavorazione
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />

        <Route path="workflows" element={<WorkflowsPage />} />
        <Route path="workflows/new" element={<NewWorkflowPage />} />
        <Route path="workflows/:id" element={<EditWorkflowPage />} />

        <Route
          path="contratti/esiti-lavorazione/nuovo-esito"
          element={
            <NuovoEsito
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="contratti/elaborazione-dati"
          element={
            <ElaborazioneDati
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />

        <Route
          path="estratto-conto/estratti-conto"
          element={
            <EstrattiConto
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="estratto-conto/estratti-conto/nuovo-estratto"
          element={
            <NuovoEstratto
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
              location=""
            />
          }
        />
        <Route
          path="estratto-conto/estratti-conto/dettaglio-estratto/:id"
          element={
            <DettaglioEstratto
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="estratto-conto/piani-compenso"
          element={
            <PianiCompenso
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />
        <Route
          path="estratto-conto/piani-compenso/modifica-piano/:id"
          element={
            <ModificaPianoCompenso
              currentPermissions={getPolicies(
                policies,
                currentUser?.isAdmin ? currentUser?.isAdmin : false
              )}
            />
          }
        />

        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage id={user} />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="customers"
          element={
            <SuspensedView>
              <UsersListWrapper />
            </SuspensedView>
          }
        />
        <Route path="customers/:tab/:id" element={<EditUser />} />
        <Route path="customers/new" element={<NewUser />} />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
