import axios from 'axios'
import { AuthenticatedQuery } from '../../interfaces/PaginatedQuery'

export const list = async ({ token }: AuthenticatedQuery<{}>) => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/roles`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

export const listRolesLabels = async ({ token }: AuthenticatedQuery<{}>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/roles`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface GetRole {
  id: number
}

export const get = async ({ id, token }: AuthenticatedQuery<GetRole>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/roles/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface GetLabel {
  id: number
}

export const getLabel = async ({ id, token }: AuthenticatedQuery<GetLabel>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/roles/labels/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreateRole {
  data: any
}

export const create = async ({
  data,
  token
}: AuthenticatedQuery<CreateRole>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/roles`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreateLabel {
  data: any
}

export const createLabel = async ({
  data,
  token
}: AuthenticatedQuery<CreateLabel>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/roles/labels`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface SaveRole {
  id: number
  data: any
}

export const save = async ({
  id,
  data,
  token
}: AuthenticatedQuery<SaveRole>) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/roles/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface SaveLabel {
  id: number
  data: any
}

export const saveLabel = async ({
  id,
  data,
  token
}: AuthenticatedQuery<SaveLabel>) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/roles/labels/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface RemoveRole {
  id: number
}

export const remove = async ({ id, token }: AuthenticatedQuery<RemoveRole>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/roles/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface RemoveLabel {
  id: number
}

export const removeLabel = async ({
  id,
  token
}: AuthenticatedQuery<RemoveLabel>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/roles/labels/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
