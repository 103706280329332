// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { useIntl } from 'react-intl'
import PianiCompensoFilters from '../../../components/PianiCompenso/PianiCompensoFilters'
import PianiCompensoTable from '../../../components/PianiCompenso/PianiCompensoTable'

function PianiCompensoPresentational({
  currentPermissions,
  offers,
  loading,
  partners,
  partnersLoading,
  onSetFilters,
  onClearFilters,
  totalResults,
  resultsPerPage,
  onPageChange
}) {
  const intl = useIntl()

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="flex items-center justify-between">
          <PageTitle>
            {intl.formatMessage({ id: 'PIANI_COMPENSO.TITLE' })}
          </PageTitle>
        </div>
      </div>

      <PianiCompensoFilters
        partners={partners}
        partnersLoading={partnersLoading}
        onSetFilters={onSetFilters}
        onClearFilters={onClearFilters}
      />

      <PianiCompensoTable
        currentPermissions={currentPermissions}
        offers={offers}
        loading={loading}
        totalResults={totalResults}
        resultsPerPage={resultsPerPage}
        onPageChange={onPageChange}
      />
    </>
  )
}

export default PianiCompensoPresentational
