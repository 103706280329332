// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import AgencyForm from '../../../components/Rete/Sedi/AgencyForm'

function ModificaSedePresentational({ currentPermissions, agency, onSubmit }) {
  const canWrite = currentPermissions.can('write', 'nets')
  const intl = useIntl()
  const history = useNavigate()

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>{intl.formatMessage({ id: 'EDIT_AGENCY.TITLE' })}</PageTitle>
      </div>
      <AgencyForm canWrite={canWrite} onSubmit={onSubmit} />
    </>
  )
}

export default ModificaSedePresentational
