import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '../../../components/Typography/PageTitle'
import { useIntl } from 'react-intl'
import { KTCard } from '../../../../_metronic/helpers'
import { QualificheTable } from './Qualifiche/table/QualificheTable'
import { KTSVG } from '../../../../_metronic/helpers'
import { useAuth } from '../../../modules/auth/core/Auth'

function ListaQualifiche({ currentPermissions }: { currentPermissions: any }) {
  const canWrite = currentPermissions.can('write', 'roles')
  const intl = useIntl()
  const { setCanWrite } = useAuth()

  useEffect(() => {
    setCanWrite(currentPermissions?.isAdmin)
  }, [])

  useEffect(() => {
    document.title = 'Control Room - Lista qualifiche'
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-stack">
        <PageTitle>{intl.formatMessage({ id: 'LIST_ROLES.TITLE' })}</PageTitle>
        <div
          className="d-flex justify-content-end"
          data-kt-user-table-toolbar="base">
          {canWrite && (
            <Link to="/rete/nuova-qualifica">
              <button type="button" className="btn btn-primary">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {intl.formatMessage({ id: 'LIST_ROLES.NEW_ROLE' })}
              </button>
            </Link>
          )}
        </div>
      </div>
      <KTCard className="mb-8">
        <QualificheTable />
      </KTCard>
    </>
  )
}

export default ListaQualifiche
