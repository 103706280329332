import { APIError } from './errors'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { DateTime } from 'luxon'
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import qs from 'qs'
import jsonParse from '../../../helpers/jsonParse'

const getAPIBaseURL = () => {
  const rawBaseURL = process.env.REACT_APP_API_URL as string
  if (rawBaseURL.endsWith('/api')) return rawBaseURL.replace(/\/api$/, '')
  return rawBaseURL
}

export const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Time-Zone': DateTime.now().zoneName
  },
  baseURL: getAPIBaseURL(),
  paramsSerializer: params => {
    const a = qs.stringify(params, {
      arrayFormat: 'repeat',
      filter: (_, value: unknown) => {
        if (value instanceof DateTime) return value.toISO()
        return value
      }
    })
    return qs.stringify(params, {
      arrayFormat: 'repeat',
      filter: (_, value: unknown) => {
        if (value instanceof DateTime) return value.toISO()
        return value
      }
    })
  },
  transformResponse: (data, headers) => {
    const contentType = headers.getContentType()

    if (contentType && contentType.includes('application/json')) {
      return jsonParse(data as string)
    }
    return data as unknown
  }
})

interface Config<D = undefined> {
  url: string
  method?: AxiosRequestConfig['method']
  data?: AxiosRequestConfig<D>['data']
  body?: AxiosRequestConfig<D>['data']
  params?: AxiosRequestConfig['params']
  headers?: AxiosRequestConfig['headers'] | Record<string, AxiosHeaders>
}

type QueryFn<R, D = undefined> = BaseQueryFn<
  Config<D> | string,
  R,
  APIError,
  unknown,
  { result: AxiosResponse<R, D> }
>
type QueryFnArgs<R, D = undefined> = Parameters<QueryFn<R, D>>
type QueryFnReturn<R, D = undefined> = QueryReturnValue<
  R,
  APIError,
  { result: AxiosResponse<R, D> }
>

export default async <R, D = undefined>(
  config: QueryFnArgs<R, D>[0]
): Promise<QueryFnReturn<R, D>> => {
  config = typeof config === 'string' ? { url: config } : config
  config = { ...config, data: config.body ?? config.data }

  // const token = store.getState().auth.token
  // if (token)
  //   config = {
  //     ...config,
  //     headers: {
  //       ...config.headers,
  //       Authorization: `Bearer ${token}`
  //     } as RawAxiosRequestHeaders
  //   }

  try {
    const result = await apiClient.request(config)

    return {
      data: (result.status === 204 ? undefined : result.data) as R,
      meta: { result }
    }
  } catch (axiosError) {
    const e = axiosError as AxiosError<APIError, unknown>

    // if (
    //   e.response !== undefined &&
    //   e.response.status === StatusCodes.UNAUTHORIZED &&
    //   token
    // ) {
    //   //store.dispatch(resetAuth())
    // }

    if (e.response !== undefined) {
      return {
        error: {
          ...e.response.data,
          status: e.response.status
        }
      }
    }

    return {
      error: {
        detail: e.message,
        status: e.code !== undefined ? parseInt(e.code) : 0,
        title: e.name,
        type: 'unknown'
      }
    }
  }
}
