import React, { useState } from 'react'
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { User } from '../../core/_models'
import { useAuth as useAuthoriz } from '../../../../context/Auth'
import { useAuth } from '../../../../modules/auth'
import { download } from '../../../../lib/fetch/accountBalances'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import { downloadFile } from '../../../../utils/download'

type Props = {
  user: User
}

const EstrattiContoActionsCell: FC<Props> = ({ user }) => {
  const [auth = {}] = useAuthoriz()
  const { currentUser } = useAuth()
  const { token }: any = auth
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const alert = useAlert()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  async function onDownloadBalance(userId: any, balanceId: any) {
    setLoading(true)
    const { error, headers, data }: any = await download({
      token,
      userId,
      balanceId
    })

    setLoading(false)
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    try {
      const fileName = headers['content-disposition']
        .split(';')[1]
        .split('=')[1]
        .replace('"', '')
        .replace('"', '')
      const fileType = headers['content-type']
      downloadFile(fileName, fileType, data)
    } catch (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
  }
  return (
    <>
      <div style={{ display: 'flex' }}>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            onDownloadBalance(currentUser?.id, user.id)
          }}>
          {!loading && 'Scarica estratto'}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Caricamento...{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
      </div>
    </>
  )
}

export { EstrattiContoActionsCell }
