import React, { useEffect } from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import { KTCard } from '../../../../_metronic/helpers'
import { EsitiLavorazioneTable } from '../EsitiLavorazione/table/EsitiLavorazioneTable'
import { useAuth } from '../../../modules/auth/core/Auth'

function EsitiLavorazionePresentational({ currentPermissions }: any) {
  const canWrite = currentPermissions.can('write', 'statuses')
  const intl = useIntl()
  const { setCanWrite } = useAuth()

  useEffect(() => {
    setCanWrite(canWrite)
  }, [])

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="flex items-center justify-between">
          <PageTitle>
            {intl.formatMessage({ id: 'LIST_STATUSES.TITLE' })}
          </PageTitle>
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base">
            {canWrite && (
              <Link to="/contratti/esiti-lavorazione/nuovo-esito">
                <button type="button" className="btn btn-primary">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {intl.formatMessage({ id: 'LIST_STATUSES.NEW_STATUS' })}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <KTCard className="mb-8">
        <EsitiLavorazioneTable />
      </KTCard>
    </>
  )
}

export default EsitiLavorazionePresentational
