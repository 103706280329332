import React, { useEffect, useState } from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import ContractsFilters from './ContractsFilters'
import { GetContractsListApiArg } from '../../../store/api/contracts'
import ContractsTable from './ContractsTable'

export default () => {
  const alert = useAlert()
  const intl = useIntl()

  const [filters, setFilters] = useState<GetContractsListApiArg>({})

  useEffect(() => {
    console.log(filters)
  }, [filters])

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="flex items-center justify-between">
          <PageTitle>{intl.formatMessage({ id: 'CONTRACTS.TITLE' })}</PageTitle>
          {/*<div
            className="d-flex justify-content-end hidden"
            data-kt-user-table-toolbar="base">
            <button
              type="button"
              className="btn btn-light-primary me-3"
              onClick={onDownloadExcel}>
              <KTSVG
                path="/media/icons/duotune/arrows/arr078.svg"
                className="svg-icon-2"
              />
              {intl.formatMessage({ id: 'COMMON.DOWNLOAD_EXCEL' })}
            </button>
          </div>*/}
        </div>
      </div>

      <ContractsFilters onChange={setFilters} />

      <ContractsTable filters={filters} />
    </>
  )
}
