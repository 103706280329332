import React, { useDeferredValue, useEffect, useState } from 'react'
import { Autocomplete, AutocompleteProps } from '@mui/material'
import { KTCard } from '../../../../_metronic/helpers'

type BaseObject = { id: number | string }

export type AutoSelectProps<T extends BaseObject> = {
  onSearchChange?: (value?: string) => void
} & Pick<
  AutocompleteProps<T, false, false, false>,
  | 'isOptionEqualToValue'
  | 'getOptionLabel'
  | 'value'
  | 'options'
  | 'renderOption'
  | 'onChange'
  | 'onBlur'
  | 'loading'
>

export default <T extends BaseObject>({
  onSearchChange = () => {},
  ...props
}: AutoSelectProps<T>) => {
  const [searchValue, setSearchValue] = useState<string>()

  const defferedSerachValue = useDeferredValue(searchValue)
  useEffect(() => {
    onSearchChange(defferedSerachValue)
  }, [defferedSerachValue])

  return (
    <Autocomplete
      PaperComponent={KTCard}
      onInputChange={(_, newInputValue) => {
        setSearchValue(newInputValue)
      }}
      filterOptions={x => x /* filtering is demanded to the server*/}
      renderInput={({ inputProps, InputProps: { ref } }) => (
        <div ref={ref}>
          <input
            {...inputProps}
            className="form-control form-control-lg mt-1 form-control-solid"
          />
        </div>
      )}
      renderOption={(props, option, _a, { getOptionLabel }) => (
        <li {...props} key={option.id}>
          {getOptionLabel(option)}
        </li>
      )}
      {...props}
    />
  )
}
