import { PaginatedResponseDto } from '../store/api/contracts'

export type PaginatedResponse<T> = {
  items: T[]
} & PaginatedResponseDto

export interface PaginationMeta {
  totalItems: number
  itemCount: number
  itemsPerPage: number
  totalPages: number
  currentPage: number
}

export const defaultPaginationMeta: PaginationMeta = {
  totalItems: 0,
  itemCount: 0,
  itemsPerPage: 10,
  totalPages: 0,
  currentPage: 1
}
