import React, { useCallback, useRef } from 'react'
import { useMemo } from 'react'

import { backOfficeColumns } from './columns/_columns'
import { Admin } from '../core/_models'
import GenericTable, { GenericTableHandle } from '../../GenericTable'
import {
  PaginatedResponse,
  PaginationMeta
} from '../../../interfaces/PaginatedResponse'
import { useAuth } from '../../../context/Auth'
import { list } from '../../../lib/fetch/users'

interface BackOfficeTableProps {
  filters: any
}

const BackOfficeTable = ({ filters }: BackOfficeTableProps) => {
  const ref = useRef<GenericTableHandle>(null)
  const [{ token }] = useAuth()
  const refetch = useCallback(() => {
    ref.current?.refetch()
  }, [ref])
  const columns = useMemo(() => backOfficeColumns(refetch), [refetch])

  const fetch = useCallback(
    async (
      paginationMeta: PaginationMeta
    ): Promise<PaginatedResponse<Admin> | string> => {
      try {
        return await list({
          token,
          page: paginationMeta.currentPage,
          limit: paginationMeta.itemsPerPage,
          type: 'backOffice',
          ...filters
        })
      } catch (error) {
        if (error instanceof Error) return error.message

        return 'Error'
      }
    },
    [filters, token]
  )

  return <GenericTable columns={columns} onFetch={fetch} ref={ref} />
}

export { BackOfficeTable }
