import React from 'react'
import { UsersListToolbar } from './UserListToolbar'

const GestisciAgentiListHeader = ({
  currentPermissions
}: {
  currentPermissions: any
}) => {
  return (
    <div className="card-header border-0 pt-6">
      <div className="card-title">
        <div className="d-flex align-items-center position-relative my-1" />
      </div>
      <div className="card-toolbar" style={{ width: '100%' }}>
        <UsersListToolbar currentPermissions={currentPermissions} />
      </div>
    </div>
  )
}

export { GestisciAgentiListHeader }
