import React, { useEffect, useState } from 'react'
import PageTitle from '../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../context/Auth'
import { save } from '../../lib/fetch/partners'
import UploadImage from '../../components/UploadImage'
import { uploadPartnerImage } from '../../lib/fetch/uploads'
import { useLazyGetPartnerQuery } from '../../store/api/partners'

function ModificaPartner({ currentPermissions }: any) {
  const canWrite = currentPermissions.can('write', 'partners')
  const intl = useIntl()
  const alert = useAlert()
  const history = useNavigate()
  const [auth = {}] = useAuth()
  const { token } = auth
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm()
  const [image, setImage] = useState<string>()
  const [imageFile, setImageFile] = useState<File>()
  const { id: idParam } = useParams()
  const id = idParam ? parseInt(idParam) : undefined
  const [getPartner, { data: partner }] = useLazyGetPartnerQuery()

  useEffect(() => {
    if (!id) return
    getPartner(id)
  }, [id])

  useEffect(() => {
    if (!partner) return
    reset({
      name: partner.name,
      status: partner.status.toString()
    })
    setImage(partner.image)
  }, [partner])

  async function onSubmit(values: any) {
    const { name, status } = values
    if (!id) return
    //@ts-ignore
    const { error } = await save({
      id,
      data: {
        name,
        status: status === 'true'
      },
      token
    })

    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    imageFile && onUploadImage(imageFile, id)
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    history(-1)
  }

  async function onUploadImage(file: any, id: any) {
    const { error } = await uploadPartnerImage({
      token,
      partnerId: id,
      file
    })
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.UPLOAD_IMAGE_ERROR' }))
      return
    }
    setImageFile(undefined)
  }

  useEffect(() => {
    document.title = 'Control Room - Modifica Partner'
  }, [])

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({
            id: `EDIT_PARTNER.${canWrite ? 'TITLE' : 'TITLE_READONLY'}`
          })}
        </PageTitle>
      </div>
      <div className="card mb-5 mb-xl-10">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="card-body pt-3">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.NAME' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="mt-1 form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('name', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                    maxLength: {
                      value: 100,
                      message: intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })
                    }
                  })}
                />
                {errors.name && errors.name.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.name.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.IMAGE' })}
              </label>

              <div className="col-lg-8 fv-row">
                <UploadImage
                  defaultImage={image}
                  onImageSelected={file => setImageFile(file)}
                />
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.ACTIVE' })}
              </label>

              <div className="col-lg-8 fv-row">
                <select
                  className="mt-1 form-select form-select-lg form-select-solid"
                  {...register('status', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}>
                  <option value="true">
                    {intl.formatMessage({ id: 'COMMON.YES' })}
                  </option>
                  <option value="false">
                    {intl.formatMessage({ id: 'COMMON.NO' })}
                  </option>
                </select>
                {errors.status && errors.status.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.status.message.toString()}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              {intl.formatMessage({ id: 'COMMON.SAVE' })}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ModificaPartner
