export const downloadFile = (fileName: any, fileType: any, buffer: any) => {
  const blob = new Blob([buffer], { type: fileType })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.setTimeout(function () {
    window.URL.revokeObjectURL(url)
  }, 5000)
}

export const openFile = (fileType: any, buffer: any) => {
  const blob = new Blob([buffer], { type: fileType })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('target', '_blank')
  link.setAttribute('rel', 'noopener noreferrer')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.setTimeout(function () {
    window.URL.revokeObjectURL(url)
  }, 10000)
}
