import { ColumnDef } from '@tanstack/react-table'
import Status from '../../../../../interfaces/Status'
import { EsitiLavorazioneActionsCell } from './EsitiLavorazioneActionsCell'

const esitiLavorazioneColumns = (
  refetch: () => void = () => {}
): ColumnDef<Status>[] => [
  {
    header: 'Nome',
    accessorKey: 'name'
  },
  {
    header: 'Descrizione',
    accessorKey: 'description'
  },
  {
    header: 'Workflow',
    accessorKey: 'workflow.name'
  },
  {
    header: 'Azioni',
    id: 'actions',
    cell: ({ row }) => (
      <EsitiLavorazioneActionsCell status={row.original} onDelete={refetch} />
    )
  }
]

export { esitiLavorazioneColumns }
