import { api } from './index'
export const addTagTypes = ['Statuses'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      createStatus: build.mutation<CreateStatusApiResponse, CreateStatusApiArg>(
        {
          query: queryArg => ({
            url: `/api/statuses`,
            method: 'POST',
            body: queryArg
          }),
          invalidatesTags: ['Statuses']
        }
      ),
      getStatusesList: build.query<
        GetStatusesListApiResponse,
        GetStatusesListApiArg
      >({
        query: queryArg => ({
          url: `/api/statuses`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            name: queryArg.name
          }
        }),
        providesTags: ['Statuses']
      }),
      getStatus: build.query<GetStatusApiResponse, GetStatusApiArg>({
        query: queryArg => ({ url: `/api/statuses/${queryArg}` }),
        providesTags: ['Statuses']
      }),
      deleteStatus: build.mutation<DeleteStatusApiResponse, DeleteStatusApiArg>(
        {
          query: queryArg => ({
            url: `/api/statuses/${queryArg}`,
            method: 'DELETE'
          }),
          invalidatesTags: ['Statuses']
        }
      )
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type CreateStatusApiResponse = /** status 200  */ ResponseStatusDto
export type CreateStatusApiArg = CreateStatusDto
export type GetStatusesListApiResponse =
  /** status 200  */ PaginatedResponseDto & {
    items?: ResponseStatusDto[]
  }
export type GetStatusesListApiArg = {
  page?: number
  limit?: number
  name?: string
}
export type GetStatusApiResponse = /** status 200  */ ResponseStatusDto
export type GetStatusApiArg = number
export type DeleteStatusApiResponse = unknown
export type DeleteStatusApiArg = number
export type ResponseWorkflowDto = {
  id: number
  name: string
  isValidForStatistics: boolean
  isValidForTarget: boolean
  isValidForRanking: boolean
}
export type ResponseStatusDto = {
  id: number
  name: string
  description: string
  workflow: ResponseWorkflowDto
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type CreateStatusDto = {
  name: string
  workflow: number
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  items: string[]
  meta: IPaginationMetaResponseDto
}
export const {
  useCreateStatusMutation,
  useGetStatusesListQuery,
  useLazyGetStatusesListQuery,
  useGetStatusQuery,
  useLazyGetStatusQuery,
  useDeleteStatusMutation
} = injectedRtkApi
