import axios from 'axios'
import { AuthenticatedQuery, PSAQuery } from '../../interfaces/PaginatedQuery'

export interface ListUsersFilters {
  search?: string
  first_name?: string
  last_name?: string
  username?: string
  email?: string
  csv?: boolean
  type?: 'all' | 'backOffice' | 'frontOffice'
}

export const list = async ({
  token,
  ...params
}: PSAQuery<ListUsersFilters>) => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/users`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: params
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

interface GetUsersParams {
  id: number
}

export const get = async ({
  id,
  token
}: AuthenticatedQuery<GetUsersParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreateUsersParams {
  data: any
}

export const create = async ({
  data,
  token
}: AuthenticatedQuery<CreateUsersParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/users`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface UpdateUsersParams {
  id: number
  data: any
}

export const save = async ({
  id,
  data,
  token
}: AuthenticatedQuery<UpdateUsersParams>) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/users/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface RemoveUsersParams {
  id: number
}

export const remove = async ({
  id,
  token
}: AuthenticatedQuery<RemoveUsersParams>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/users/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface GetSponsorParams {
  key: string
}

export const getSponsors = async ({
  key,
  token
}: AuthenticatedQuery<GetSponsorParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users/sponsors/${key}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface ResendCredentialsParams {
  id: number
}

export const resendCredentials = async ({
  id,
  token
}: AuthenticatedQuery<ResendCredentialsParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users/resendCredentials/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface GetUsersBalancesParams {
  id: number
  csv?: boolean
  month?: string
  year?: string
}

export const getUserBalances = async ({
  id,
  token,
  ...queryString
}: PSAQuery<GetUsersBalancesParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users/balances/agent/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: queryString
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface GetUsersDocumentsParams {
  id: number
  csv?: boolean
}

export const downloadDocument = async ({
  id,
  token
}: AuthenticatedQuery<GetUsersDocumentsParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users/download/document/${id}`,
      responseType: 'blob'
    })

    if (response instanceof Error) {
      return { error: response }
    }
    console.log(response)
    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
