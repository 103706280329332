import { useParams } from 'react-router-dom'
import { useGetCustomerQuery } from '../../../../store/api/customers'
import { useGetContractsListQuery } from '../../../../store/api/contracts'
import { ProfileHeader } from './Header'
import { useMemo } from 'react'
import EditTab from './tabs/Edit'
import { ContractsTab } from './tabs/Contracts'

export default function EditUser() {
  const { id: idParam, tab: openTab } = useParams()
  const id = useMemo(() => parseInt(idParam ?? '0'), [idParam])
  const { data: customer } = useGetCustomerQuery(id)
  const { data: contracts } = useGetContractsListQuery({
    customer: [id]
  })
  const TabComponents: Record<string, JSX.Element | null> = {
    edit: customer ? <EditTab customer={customer} /> : null,
    contracts: <ContractsTab id={id} />,
    questionary: <div>Questionary</div>,
    privacy: <div>Privacy</div>
  }

  const renderTab = useMemo(() => {
    if (!customer) return null
    if (!openTab) return null
    return TabComponents[openTab] || null
  }, [openTab, customer])

  return (
    <>
      <ProfileHeader
        numberContracts={contracts?.meta.totalItems}
        customer={customer}
      />
      {renderTab}
    </>
  )
}
