import React, { useEffect } from 'react'
import { NewUserForm } from '../../../components/GestioneUtenze/NewUserForm'
import { useNavigate, useParams } from 'react-router-dom'
import {
  UpdateUserApiArg,
  useGetUserQuery,
  useUpdateUserMutation
} from '../../../store/api/users'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import PageTitle from '../../../components/Typography/PageTitle'

function ModificaUtente({ currentPermissions }: any) {
  const { id: idParam } = useParams()
  const history = useNavigate()
  const id = parseInt(idParam ?? '0')
  const { data: user } = useGetUserQuery(id)
  const [updateUser, { isSuccess }] = useUpdateUserMutation()
  useEffect(() => {
    document.title = 'Control Room - Modifica Utenze'
  }, [])

  const handleUpdate = (valuesObject: UpdateUserApiArg) => {
    updateUser({
      id: valuesObject.id,
      updateUserDto: {
        ...valuesObject.updateUserDto,
        businessName: ' ',
        zipCode: '00000',
        mobile: '3333333333',
        fiscalPositionId: 13
      }
    })
  }

  useEffect(() => {
    if (isSuccess) {
      history(-1)
    }
  }, [isSuccess, history])

  useEffect(() => {
    if (!idParam) history(-1)
  }, [idParam, history])

  return user ? (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>Modifica anagrafica</PageTitle>
      </div>
      <NewUserForm
        type="update"
        user={user}
        loading={false}
        onUpdate={handleUpdate}
        isBackOffice={false}
      />
    </>
  ) : null
}

export default ModificaUtente
