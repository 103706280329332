// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import Step1Form from '../../../components/NuovaAnagrafica/Step1Form'
import { useIntl } from 'react-intl'

const NuovaAnagraficaPresentational = ({
  canWriteCustomer,
  contract,
  onSubmitStep1,
  onRemoveDraft
}) => {
  const history = useNavigate()
  const intl = useIntl()

  return (
    <>
      <div className="d-flex items-center g-5 g-xl-10 mb-5 mb-xl-10 w-100">
        <Button
          className="lightblue-btn rounded-full mr-3 "
          style={{ padding: '0' }}
          onClick={() => history(-1)}>
          <ArrowLeftIcon style={{ width: '1.5rem', height: '1.5rem' }} />
        </Button>

        <PageTitle>
          {intl.formatMessage({ id: 'NEW_CONTRACT.TITLE' })}
        </PageTitle>
        <Button className="lightblue-btn ml-auto" onClick={onRemoveDraft}>
          {intl.formatMessage({ id: 'NEW_CONTRACT.CLEAR_DRAFT' })}
        </Button>
      </div>
      {canWriteCustomer && contract.currentStep < 2 && (
        <Step1Form onSubmit={onSubmitStep1} />
      )}
    </>
  )
}

export default NuovaAnagraficaPresentational
