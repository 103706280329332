// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../context/Auth'
import {
  create as createContract,
  downloadAttachment,
  get as getContract,
  save as saveContract
} from '../../../lib/fetch/contracts'
import { get, create as createCustomer } from '../../../lib/fetch/customers'
import NuovaAnagraficaPresentational from './NuovaAnagraficaPresentational'
import {
  getSponsors,
  get as getUser,
  list as getAllUsers
} from '../../../lib/fetch/users'
import { list as listPartners } from '../../../lib/fetch/partners'
import { list as listOffers } from '../../../lib/fetch/offers'
import { list as listStatuses } from '../../../lib/fetch/statuses'
import { Navigate, useNavigate } from 'react-router-dom'
import { openFile } from '../../../utils/download'

function NuovaAnagrafica({ currentPermissions, location }) {
  const { can } = currentPermissions
  const canWriteCustomer = can('write', 'customers')
  const canWriteContract = can('write', 'contracts')
  const [{ token, profile } = {}] = useAuth()
  const alert = useAlert()
  //const { showLoading, hideLoading } = useLoading();
  const intl = useIntl()
  const history = useNavigate()
  //const [customerId, setCustomerId] = useQueryParam('customerId', NumberParam);
  const [customerId, setCustomerId] = useState<string>()
  const [users, setUsers] = useState([])
  const [partners, setPartners] = useState([])
  const [allUsers, setAllUsers] = useState([])

  const [offers, setOffers] = useState([])
  const [statuses, setStatuses] = useState([])
  const [attachments, setAttachments] = useState([])
  const [contractAttachments, setContractAttachments] = useState([])

  const [contract, setContract] = useState({
    currentStep: 1,
    step1: null,
    step2: null,
    step3: null
  })

  const [step2Edit, setStep2Edit] = useState(false)
  const [step3Edit, setStep3Edit] = useState(false)
  const onStep2Edit = () => setStep2Edit(true)
  const onStep3Edit = () => setStep3Edit(true)

  async function fetchContract() {
    //showLoading();
    const { error, data: _contract } = await getContract({
      id: contract.id,
      token
    })

    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    const { customer, user, offer, status, attachments } = _contract
    setContract(contract => ({
      ...contract,
      step1: customer,
      step2: {
        userId: user && user.id,
        offerId: offer && offer.id,
        partnerId: offer && offer.partners && offer.partners.id
      },
      step3: {
        code: contract.code,
        contract_date: contract.contract_date,
        insertion_date: contract.created_at,
        pod_pdr: contract.pod_pdr,
        statusId: status && status.id
      }
    }))
    setContractAttachments(attachments)
  }

  async function fetchCustomer() {
    //showLoading();
    const { error, data } = await get({ id: customerId, token })

    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    setContract(contract => ({
      ...contract,
      currentStep: contract.currentStep > 1 ? contract.currentStep : 2,
      step1: data.customer
    }))
  }

  async function fetchUser(id) {
    //showLoading();
    const { error, data: response } = await getUser({ token, id })
    //hideLoading();
    if (error) {
      setUsers([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    if (!response) return
    setUsers([response.user])
  }

  async function onSearchUser(key) {
    const { error, data } = await getSponsors({ key, token })

    if (error) {
      setUsers([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    if (!data) return
    setUsers(data.users)
    return data.users
  }

  async function fetchPartners() {
    let result
    try {
      result = await listPartners({ token })
    } catch (error) {
      setPartners([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    if (!result) return
    setPartners(result.items)
  }

  async function fetchAllUsers() {
    let result
    try {
      result = await getAllUsers({ token })
    } catch (error) {
      setAllUsers([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    if (!result) return
    setAllUsers(result.items)
  }

  async function fetchOffers() {
    let result
    try {
      result = await listOffers({ token })
    } catch (error) {
      setOffers([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    if (!result) return
    setOffers(result.items)
  }

  async function fetchStatuses() {
    let result
    try {
      result = await listStatuses({ token })
    } catch (error) {
      setStatuses([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    if (!result) return
    setStatuses(result.items)
    setContract(contract => ({
      ...contract,
      step3: { ...contract.step3, statusId: result.items[0].id }
    }))
  }

  async function submitContract(contract = {}) {
    //showLoading();
    const { error, data } = await createContract({
      token,
      data: {
        code: contract.step3.code,
        pod_pdr: contract.step3.pod_pdr,
        contract_date: contract.step3.contract_date,
        userId: Number(contract.step2.userId),
        statusId: Number(contract.step3.statusId),
        offerId: Number(contract.step2.offerId),
        customerId: contract.step1.id
      }
    })
    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    localStorage.removeItem('contract_draft')
    setContract(contract => ({ ...contract, id: data.id }))
    alert.success(intl.formatMessage({ id: 'NEW_CONTRACT.CONTRACT_SUCCESS' }))
  }

  async function updateContract(contract = {}) {
    //showLoading();
    const { error } = await saveContract({
      token,
      id: contract.id,
      data: {
        code: contract.step3.code,
        pod_pdr: contract.step3.pod_pdr,
        contract_date: contract.step3.contract_date,
        userId: Number(contract.step2.userId),
        statusId: Number(contract.step3.statusId),
        offerId: Number(contract.step2.offerId),
        customerId: contract.step1.id
      }
    })
    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    fetchContract()
    alert.success(intl.formatMessage({ id: 'EDIT_CONTRACT.CONTRACT_SUCCESS' }))
  }

  async function onSubmitCustomer(values) {
    const {
      fiscal_code,
      first_name,
      last_name,
      company,
      birth_date,
      birth_place,
      email,
      phone,
      mobile,
      fax,
      iban,
      country,
      region,
      province,
      city,
      address,
      zip_code,
      notes
    } = values

    //showLoading();
    const { error } = await createCustomer({
      data: {
        fiscal_code,
        first_name,
        last_name,
        company,
        birth_date,
        birth_place,
        email: email || null,
        phone,
        mobile,
        fax,
        iban,
        country,
        region,
        province,
        city,
        address,
        zip_code,
        notes
      },
      token
    })

    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'NEW_CONTRACT.CUSTOMER_SUCCESS' }))
    history('/contr')
  }

  async function onSubmitStep1(values) {
    const {
      customerId,
      fiscal_code,
      first_name,
      last_name,
      company,
      birth_date,
      birth_place,
      email,
      phone,
      mobile,
      fax,
      iban,
      country,
      region,
      province,
      city,
      address,
      zip_code,
      notes
    } = values

    if (customerId) {
      setCustomerId(customerId)
      return
    }

    //showLoading();

    const { error, data } = await createCustomer({
      data: {
        fiscal_code,
        first_name,
        last_name,
        company,
        birth_date,
        birth_place,
        email: email || null,
        phone,
        mobile,
        fax,
        iban,
        country,
        region,
        province,
        city,
        address,
        zip_code,
        notes
      },
      token
    })

    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    history(`/contracts/new/${data.customer.id}`)
  }

  function onSubmitStep2(values) {
    const { userId, partnerId, offerId } = values
    const mContract = {
      ...contract,
      currentStep: contract.currentStep > 2 ? contract.currentStep : 3,
      step2: { userId, partnerId, offerId }
    }

    setContract(mContract)
    setStep2Edit(false)
    if (contract.id) {
      updateContract(mContract)
    } else {
      localStorage.setItem('contract_draft', JSON.stringify(mContract))
    }
  }

  function onSubmitStep3(values) {
    const mContract = {
      ...contract,
      currentStep: contract.currentStep > 3 ? contract.currentStep : 4,
      step3: values
    }
    setContract(mContract)
    setStep3Edit(false)
    if (!contract.id) {
      submitContract(mContract)
    } else {
      updateContract(mContract)
    }
  }

  const onRemoveAttachment = attachment => {
    setAttachments(attachments =>
      attachments.filter(
        file =>
          !(
            file.name === attachment.name &&
            file.uploadDate === attachment.uploadDate
          )
      )
    )
  }

  const onAddAttachment = attachment => {
    setAttachments([...attachments, attachment])
  }

  async function onFileUpload(files) {
    if (!contract.id) return
    const uploadPromises = files.map(async file => {
      return await uploadContractAttachment({
        token,
        contractId: contract.id,
        file
      })
    })
    const responses = await Promise.all(uploadPromises)

    responses.forEach(({ error, file }) => {
      if (error) {
        alert.error(`${file.name} Errore nel caricamento`)
        return
      }
      alert.success(`${file.name} Caricamento completato`)
      onRemoveAttachment(file)
    })
    fetchContract()
  }

  async function onOpenAttachment(attachment) {
    const { id } = attachment
    //showLoading();
    const { error, headers, data } = await downloadAttachment({
      token,
      id
    })

    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    try {
      const fileType = headers['content-type']
      openFile(fileType, data)
    } catch (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      console.log(error)
      return
    }
  }

  function onRemoveDraft() {
    localStorage.removeItem('contract_draft')
    setContract({
      currentStep: 1,
      step1: null,
      step2: null,
      step3: null
    })
  }

  useEffect(() => {
    fetchPartners()
    fetchOffers()
    fetchStatuses()
    fetchAllUsers()
    try {
      const contract = JSON.parse(localStorage.getItem('contract_draft'))
      if (contract) {
        setContract(contract)
        if (contract.step2 && contract.step2.userId)
          fetchUser(contract.step2.userId)
      }
    } catch (error) {}
  }, [])

  useEffect(() => {
    if (!customerId) {
      return
    }
    fetchCustomer()
  }, [customerId])

  useEffect(() => {
    if (!contract.id) return
    fetchContract()
  }, [contract.id])

  useEffect(() => {
    document.title = 'Control Room - Nuova anagrafica'
  }, [])

  if (!(canWriteCustomer || canWriteContract)) {
    let pathname = location.pathname.split('/')
    pathname.pop()
    pathname = pathname.join('/')
    return <Navigate push={false} to={{ pathname }} />
  }

  return (
    <NuovaAnagraficaPresentational
      canWriteCustomer={canWriteCustomer}
      canWriteContract={canWriteContract}
      contract={contract}
      users={users}
      allUsers={allUsers}
      profile={profile}
      onSearchUser={onSearchUser}
      partners={partners}
      offers={offers}
      statuses={statuses}
      attachments={attachments}
      contractAttachments={contractAttachments}
      step2Edit={step2Edit}
      step3Edit={step3Edit}
      onStep2Edit={onStep2Edit}
      onStep3Edit={onStep3Edit}
      onSubmitStep1={onSubmitStep1}
      onSubmitCustomer={onSubmitCustomer}
      onSubmitStep2={onSubmitStep2}
      onSubmitStep3={onSubmitStep3}
      onAddAttachment={onAddAttachment}
      onRemoveAttachment={onRemoveAttachment}
      onRemoveDraft={onRemoveDraft}
      onFileUpload={onFileUpload}
      onOpenAttachment={onOpenAttachment}
    />
  )
}

export default NuovaAnagrafica
