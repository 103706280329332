import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const AgencyForm = ({ canWrite, onSubmit }: any) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useFormContext()
  const intl = useIntl()

  return (
    <div className="card mb-5 mb-xl-10">
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <fieldset disabled={!canWrite}>
          <div className="card-body pt-3">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.NAME' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="mt-1 text-gray-800 form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'COMMON.NAME' })}
                  {...register('name', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                />

                {errors.name && errors.name.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.name.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.CITY' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="mt-1 text-gray-800 form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'COMMON.CITY' })}
                  {...register('city', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                />
                {errors.city && errors.city.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.city.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.ADDRESS' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="mt-1 text-gray-800 form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'COMMON.ADDRESS' })}
                  {...register('address', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                />
                {errors.address && errors.address.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.address.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.CAP' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="mt-1 text-gray-800 form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'COMMON.CAP' })}
                  {...register('zip_code', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                    maxLength: {
                      value: 9,
                      message: 'Massimo 9 caratteri'
                    },
                    pattern: {
                      value: /^\d{5}$/,
                      message: 'Cap non valido'
                    }
                  })}
                />
                {errors.zip_code && errors.zip_code.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.zip_code.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.PHONE_NUMBER' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="mt-1 text-gray-800 form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'COMMON.PHONE_NUMBER'
                  })}
                  {...register('phone', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                />
                {errors.phone && errors.phone.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.phone.message.toString()}
                  </span>
                )}
              </div>
            </div>
          </div>
        </fieldset>
        {canWrite && (
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              {intl.formatMessage({ id: 'COMMON.SAVE' })}
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default AgencyForm
