import React, { useEffect } from 'react'
import PosizioniFiscaliPresentational from './PosizioniFiscaliPresentational'

function OfferteArticoli({ currentPermissions }: { currentPermissions: any }) {
  useEffect(() => {
    document.title = 'Control Room - Posizioni Fiscali'
  }, [])

  return (
    <PosizioniFiscaliPresentational currentPermissions={currentPermissions} />
  )
}

export default OfferteArticoli
