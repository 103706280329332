import { useEffect } from 'react'
import {
  CreateCustomerApiArg,
  useCreateCustomerMutation
} from '../../../../store/api/customers'
import { AnagraficaForm } from '../AnagraficaForm'
import { useNavigate } from 'react-router-dom'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import PageTitle from '../../../../components/Typography/PageTitle'

export default function NewUser() {
  const [createCustomer, { isLoading: loading, isSuccess }] =
    useCreateCustomerMutation()
  const history = useNavigate()
  const handleSubmit = (createCustomerDto: CreateCustomerApiArg) => {
    if (!createCustomerDto) return
    createCustomer(createCustomerDto)
  }

  useEffect(() => {
    if (isSuccess) history(-1)
  }, [isSuccess])

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>Nuova anagrafica</PageTitle>
      </div>
      <AnagraficaForm
        onSubmit={({ createCustomerDto }) => {
          if (!createCustomerDto) return
          handleSubmit(createCustomerDto)
        }}
        loading={loading}
      />
    </>
  )
}
