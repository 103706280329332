// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect, createContext } from 'react'

import { useIntl } from 'react-intl'
import { css } from '@emotion/react'
import { PianoCompensiTable } from './table/PianoCompensiTable'
import { KTCard } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth/core/Auth'

export const canWritePianiCompenso = createContext<boolean>(false)

function PianiCompensoTable({
  loading = false,
  offers = [],
  totalResults,
  resultsPerPage,
  onPageChange,
  currentPermissions
}: any) {
  //TODO controllare se workflows è il giusto permesso
  const canWrite = currentPermissions?.can('write', 'workflows')
  const { setCanWrite } = useAuth()
  const intl = useIntl()

  const clipLoaderCss = css`
    height: 20px;
    width: 20px;
  `

  useEffect(() => {
    setCanWrite(canWrite)
  }, [])

  return (
    <>
      <KTCard
        className="mb-8"
        style={{
          padding: '1rem',
          boxShadow: '0 0 0 1px rgb(0 0 0 / 5%)',
          borderRadius: '0.5rem'
        }}>
        <canWritePianiCompenso.Provider value={canWrite}>
          <PianoCompensiTable data={offers} />
        </canWritePianiCompenso.Provider>
      </KTCard>
    </>
  )
}

export default PianiCompensoTable
