import React, { useMemo, useState } from 'react'

import AutoSelect, { AutoSelectProps } from '../AutoSelect'
import {
  ResponseStatusDto,
  useGetStatusesListQuery
} from '../../../store/api/statuses'

export type StatusSelectProps = Pick<
  AutoSelectProps<ResponseStatusDto>,
  'value' | 'onChange'
>

export default ({ ...props }: StatusSelectProps) => {
  const [searchValue, setSearchValue] = useState<string>()

  const args = useMemo(
    () => ({
      ...(searchValue && { name: searchValue }),
      limit: 10
    }),
    [searchValue]
  )

  const { data, isFetching } = useGetStatusesListQuery(args)

  return (
    <AutoSelect
      options={data?.items ?? []}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onSearchChange={setSearchValue}
      loading={isFetching}
      getOptionLabel={o => o.name}
      {...props}
    />
  )
}
