import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { RankingItem, list as listRanking } from '../../../lib/fetch/ranking'
import './styles.css'
import { Tooltip } from 'bootstrap'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { SalesChannel } from '../../../interfaces/SalesChannel'
import { Metric } from '../../../lib/fetch/statistics'

type Props = {
  channel: SalesChannel
}

export const Ranking = ({ channel }: Props) => {
  const intl = useIntl()
  const [loading, setLoading] = React.useState(false)
  const [ranking, setRanking] = React.useState<RankingItem[]>()

  const startDate = moment().startOf('month').format('YYYY-MM-DD')
  const endDate = moment().endOf('month').format('YYYY-MM-DD')

  const fetchRanking = useCallback(async () => {
    setLoading(true)
    setRanking(undefined)
    const response = await listRanking({
      startDate: startDate,
      endDate: endDate,
      salesChannels: [channel.id],
      metrics: channel.rankingMetrics
    })
    if ('error' in response) {
      //alert.error(intl.formatMessage({id: 'COMMON.GENERIC_ERROR'}));
      return
    }
    setRanking(response.ranking)
    setLoading(false)
  }, [setRanking, setLoading, startDate, endDate, channel])

  const rearrengedRanking: Map<number, RankingItem[]> = useMemo(() => {
    if (!ranking) {
      return new Map()
    }
    let rankingMap = new Map<number, RankingItem[]>()
    ranking.forEach(rank => {
      if (!rankingMap.has(rank.rank)) {
        rankingMap.set(rank.rank, [])
      }
      rankingMap.get(rank.rank)?.push(rank)
      rankingMap.get(rank.rank)?.sort(() => Math.random() - 0.5)
    })
    return rankingMap
  }, [ranking])

  useEffect(() => {
    fetchRanking()
  }, [fetchRanking])

  return (
    <div key={channel.id} className="col-4 mb-5">
      <div className="card h-100">
        <div className="card-header pt-5">
          <div className="card-title">
            <h3 className="card-label text-gray-800 fw-bold">
              {channel.code}
              <small className="ml-2">
                {channel.rankingMetrics === 'value'
                  ? intl.formatMessage({ id: 'DASHBOARD.VALUE_CONTRACTS' })
                  : intl.formatMessage({ id: 'DASHBOARD.COUNT_CONTRACTS' })}
              </small>
            </h3>
          </div>
          <div className="card-toolbar" />
        </div>
        <div className="card-body pt-5">
          {rearrengedRanking.size === 0 && (
            <h4 className="text-center">
              {intl.formatMessage({ id: 'DASHBOARD.NO_DATA' })}
            </h4>
          )}
          {Array.from(rearrengedRanking).map(([rank, items]) => (
            <Fragment key={`rows-${rank}`}>
              {items.length > 0 && (
                <RenderRow
                  items={items}
                  rank={rank}
                  metric={channel.rankingMetrics}
                />
              )}
              {rearrengedRanking.size > rank && (
                <div className="separator separator-dashed my-3" />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

const RenderRow = React.memo(
  ({
    rank,
    items,
    metric
  }: {
    rank: number
    items: RankingItem[]
    metric: Metric
  }) => {
    const intl = useIntl()

    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    )
    const tooltipList = Array.from(tooltipTriggerList).map(
      function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
      }
    )

    const [first, second, ...rest] = items
    return (
      <div className="d-flex w-100 align-items-center justify-content-between ">
        <div className="d-flex align-items-center">
          {rank === 1 && <span style={{ fontSize: '2rem' }}>🥇</span>}
          {rank === 2 && <span style={{ fontSize: '2rem' }}>🥈</span>}
          {rank > 2 && <span style={{ fontSize: '2rem' }}>🥉</span>}
          <h6
            className="ml-3"
            style={{ fontWeight: rank === 1 ? '600' : 'normal' }}>
            {first.user.firstName + ' ' + first.user.lastName}
            {second &&
              ', ' + second.user.firstName + ' ' + second.user.lastName}
            {rest.length > 0 && (
              <button
                className=""
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={rest
                  .map(item => item.user.firstName + ' ' + item.user.lastName)
                  .join(', ')}>
                &nbsp;
                {intl.formatMessage(
                  { id: 'DASHBOARD.OTHERS' },
                  { count: rest.length }
                )}
              </button>
            )}
          </h6>
        </div>
        <h6 className="text-gray-500 fw-bold" style={{ fontSize: '0.9rem' }}>
          {first.value}
          {metric === 'value' && '€'}
        </h6>
      </div>
    )
  }
)
