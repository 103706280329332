import { api } from './index'
export const addTagTypes = ['Customers'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      createCustomer: build.mutation<
        CreateCustomerApiResponse,
        CreateCustomerApiArg
      >({
        query: queryArg => ({
          url: `/api/customers`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Customers']
      }),
      getCustomersList: build.query<
        GetCustomersListApiResponse,
        GetCustomersListApiArg
      >({
        query: queryArg => ({
          url: `/api/customers`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            firstName: queryArg.firstName,
            lastName: queryArg.lastName,
            fiscalCode: queryArg.fiscalCode,
            company: queryArg.company,
            ordering: queryArg.ordering,
            sort: queryArg.sort,
            csv: queryArg.csv
          }
        }),
        providesTags: ['Customers']
      }),
      getCustomer: build.query<GetCustomerApiResponse, GetCustomerApiArg>({
        query: queryArg => ({ url: `/api/customers/${queryArg}` }),
        providesTags: ['Customers']
      }),
      updateCustomer: build.mutation<
        UpdateCustomerApiResponse,
        UpdateCustomerApiArg
      >({
        query: queryArg => ({
          url: `/api/customers/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.createCustomerDto
        }),
        invalidatesTags: ['Customers']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type CreateCustomerApiResponse = /** status 200  */ ResponseCustomerDto
export type CreateCustomerApiArg = CreateCustomerDto
export type GetCustomersListApiResponse =
  /** status 200  */ PaginatedResponseDto & {
    items?: ResponseCustomerDto[]
  }
export type GetCustomersListApiArg = {
  page?: number
  limit?: number
  search?: string
  firstName?: string
  lastName?: string
  fiscalCode?: string
  company?: string
  ordering?: string
  sort?: string
  csv?: boolean
}
export type GetCustomerApiResponse = /** status 200  */ ResponseCustomerDto
export type GetCustomerApiArg = number
export type UpdateCustomerApiResponse = /** status 200  */ ResponseCustomerDto
export type UpdateCustomerApiArg = {
  id: number
  createCustomerDto: CreateCustomerDto
}
export type ResponseCustomerDto = {
  id: number
  fiscalCode: string
  firstName: string
  lastName: string
  company: string
  birthDate: string
  birthPlace: string
  phone: string
  mobile: string
  fax: string
  iban: string
  country: string
  region: string
  province: string
  city: string
  address: string
  zipCode: string
  notes: string
  customerType: string
  gender: string
  job: string
  maritalStatus: string
  educationalQualification: string
  linkedin: string
  email: string
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type CreateCustomerDto = {
  fiscalCode: string
  firstName: string
  lastName: string
  company?: string
  birthDate?: string
  birthPlace?: string
  email: string
  phone?: string
  mobile?: string
  fax?: string
  iban?: string
  country?: string
  region?: string
  province?: string
  city?: string
  address?: string
  zipCode?: string
  notes?: string
  customerType?: string
  gender?: string
  job?: string
  maritalStatus?: string
  educationalQualification?: string
  linkedin?: string
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  items: string[]
  meta: IPaginationMetaResponseDto
}
export const {
  useCreateCustomerMutation,
  useGetCustomersListQuery,
  useLazyGetCustomersListQuery,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useUpdateCustomerMutation
} = injectedRtkApi
