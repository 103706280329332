import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useForm, FormProvider } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../context/Auth'
//import { useLoading } from '../../../context/LoadingContext';
import NuovaQualificaPresentational from './NuovaQualificaPresentational'
import { Navigate, useNavigate } from 'react-router-dom'
import { CreateRoleArg, useCreateRoleMutation } from '../../../store/api/roles'

function NuovaQualifica({ currentPermissions, location }: any) {
  const canWrite = currentPermissions.can('write', 'roles')
  //const { showLoading, hideLoading } = useLoading();
  const [auth = {}] = useAuth()
  const { token } = auth
  const formContext = useForm()
  const intl = useIntl()
  const alert = useAlert()
  const history = useNavigate()

  const [createRole, { isSuccess, isLoading, isError }] =
    useCreateRoleMutation()

  async function onSubmit(values: CreateRoleArg) {
    createRole(values)
  }

  useEffect(() => {
    if (isSuccess) {
      alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
      history(-1)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    }
  }, [isError])

  useEffect(() => {
    document.title = 'Control Room - Nuova qualifica'
  }, [])

  if (!canWrite) {
    let pathname = '/'
    if (location && location.pathname) {
      pathname = location.pathname.split('/')
      if (Array.isArray(pathname)) {
        pathname.pop()
        pathname = pathname.join('/')
      }
    }
    return <Navigate to={{ pathname }} />
  }

  return (
    <FormProvider {...formContext}>
      <NuovaQualificaPresentational
        currentPermissions={currentPermissions}
        loading={isLoading}
        onSubmit={onSubmit}
      />
    </FormProvider>
  )
}

export default NuovaQualifica
