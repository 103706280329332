import React from 'react'
import { UserActionsCell } from './UserActionsCell'
import { Customer } from '../../core/_models'
import Moment from 'moment'
import { ColumnDef } from '@tanstack/react-table'

const customersColumns = (
  refetch: () => void = () => {}
): ColumnDef<Customer>[] => [
  {
    header: 'Nome',
    accessorKey: 'firstName'
  },
  {
    header: 'Cognome',
    accessorKey: 'lastName'
  },
  {
    header: 'Codice fiscale',
    accessorKey: 'fiscalCode'
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'Data di nascita',
    accessorKey: 'birthDate',
    cell: ({ getValue }) =>
      getValue() ? Moment(getValue() as string).format('DD/MM/YYYY') : ''
  },
  {
    header: 'Azienda',
    accessorKey: 'company'
  },
  {
    header: 'Cellulare',
    accessorKey: 'mobile'
  },
  {
    header: 'Azioni',
    id: 'actions',
    cell: ({ row }) => (
      <UserActionsCell id={row.original.id} onDelete={refetch} />
    )
  }
]

export { customersColumns }
