import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Button } from '@windmill/react-ui'
import { DateTime } from 'luxon'
import { useAlert } from 'react-alert'
import { CircularProgress } from '@mui/material'
import {
  ResponseContractAttachmentDto,
  useDeleteContractAttachmentMutation,
  useLazyGetContractSignedAttachmentQuery
} from '../../../../../store/api/contracts'
import useLogin from '../../../../../hooks/useLogin'

type ContractAttachmentBoxProps = {
  contractAttachment: ResponseContractAttachmentDto
  contractId: number
  onDelete?: () => void
}

export default ({
  contractAttachment,
  contractId
}: ContractAttachmentBoxProps) => {
  const alert = useAlert()

  const { isLogged, can } = useLogin()
  const [isWaitingToOpen, setIsWaitingToOpen] = useState(false)

  const canDelete = useMemo(
    () => isLogged && can('Delete', 'contracts|attachments'),
    [isLogged, can]
  )

  const [deleteContractAttachment, { isSuccess, isError, isLoading }] =
    useDeleteContractAttachmentMutation()
  const [
    getSignedAttachmentUrl,
    {
      data: signedUrl,
      isSuccess: isLoadedSignedUrl,
      isLoading: isLoadingSignedUrl
    }
  ] = useLazyGetContractSignedAttachmentQuery()

  useEffect(() => {
    if (!isError) return
    alert.error(`Impossibile eliminare l'allegato`)
  }, [isError])

  const handleRemove = useCallback<MouseEventHandler>(e => {
    e.preventDefault()

    deleteContractAttachment({
      contractId,
      attachmentId: contractAttachment.id
    })
  }, [])

  const handleViewAttachment = () => {
    if (!isLoadedSignedUrl || !signedUrl) {
      setIsWaitingToOpen(true)
      getSignedAttachmentUrl({
        contractId,
        attachmentId: contractAttachment.id
      })
      return
    }
    window.open(signedUrl.url, '_blank')
  }

  useEffect(() => {
    if (!isLoadedSignedUrl || !signedUrl || !isWaitingToOpen) return
    window.open(signedUrl.url, '_blank')
    setIsWaitingToOpen(false)
  }, [isLoadedSignedUrl, signedUrl, isWaitingToOpen])

  return (
    <a onClick={handleViewAttachment} className="cursor-pointer">
      <div className="p-4 border-1 border-slate-600 rounded-xl">
        <div className="flex items-center justify-between w-fulls">
          <div className="flex items-center">
            <div className=" flex content-center justify-center items-center w-16 h-16">
              <i className="bi bi-file-earmark-text-fill fs-1" />
            </div>
            <div className="ml-2">
              <p className="font-medium text-sm">
                {contractAttachment.original_filename}
              </p>
              <p className="text-gray-600 text-sm">
                {DateTime.fromISO(contractAttachment.created_at).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </p>
            </div>
          </div>
          {canDelete && (
            <Button
              className="ml-2"
              disabled={isLoading}
              icon={() =>
                isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <i className="bi bi-trash-fill fs-4" />
                )
              }
              layout="link"
              onClick={handleRemove}
            />
          )}
        </div>
      </div>
    </a>
  )
}
