import React, { useMemo, useState } from 'react'
import {
  GetSalesChannelsListApiArg,
  ResponseSalesChannelDto,
  useGetSalesChannelsListQuery
} from '../../../store/api/salesChannels'

import AutoSelect, { AutoSelectProps } from '../AutoSelect'

export type SalesChannelSelectProps = Pick<
  AutoSelectProps<ResponseSalesChannelDto>,
  'value' | 'onChange'
>

export default ({ ...props }: SalesChannelSelectProps) => {
  const [searchValue, setSearchValue] = useState<string>()

  const args = useMemo<GetSalesChannelsListApiArg>(
    () => ({
      ...(searchValue && { code: searchValue }),
      limit: 10
    }),
    [searchValue]
  )

  const { data, isFetching } = useGetSalesChannelsListQuery(args)

  return (
    <AutoSelect
      options={data?.items ?? []}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onSearchChange={setSearchValue}
      loading={isFetching}
      getOptionLabel={o => o.description}
      {...props}
    />
  )
}
