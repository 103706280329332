import React, { useState, useEffect } from 'react'
//import { useAlert } from 'react-alert';
//import {useIntl} from 'react-intl'
import { useAuth } from '../../../context/Auth'
import EsitiLavorazionePresentational from './EsitiLavorazionePresentational'

function EsitiLavorazione({ currentPermissions }: any) {
  const [{ token }] = useAuth()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = 'Control Room - Esiti Lavorazione'
  }, [])

  return (
    <EsitiLavorazionePresentational currentPermissions={currentPermissions} />
  )
}

export default EsitiLavorazione
