// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect, useState } from 'react'

import PageTitle from '../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../context/Auth'
import { list as listPartners } from '../../lib/fetch/partners'
import { list as listChannels } from '../../lib/fetch/salesChannels'
import { get, save } from '../../lib/fetch/offers'
//import { useLoading } from '../../context/LoadingContext';
import OfferForm from '../../components/OfferForm'
import { uploadOfferImage } from '../../lib/fetch/uploads'
import { useIntl } from 'react-intl'

function ModificaOfferta({ currentPermissions }) {
  const canWrite = currentPermissions.can('write', 'offers')
  const [loading, setLoading] = useState(false)
  const alert = useAlert()
  const history = useNavigate()
  const [auth = {}] = useAuth()
  const { token } = auth
  const formContext = useForm()
  const { id } = useParams()
  const [partners, setPartners] = useState([])
  const [channels, setChannels] = useState([])
  const [image, setImage] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const intl = useIntl()

  async function fetchPartners() {
    let result
    try {
      result = await listPartners({ token })
    } catch (error) {
      setPartners([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    if (!result) return
    setPartners(result.items)
  }

  async function fetchChannels() {
    setLoading(true)
    setChannels([])
    try {
      const data = await listChannels({ token })
      setChannels(data.items)
    } catch (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    } finally {
      setLoading(false)
    }
  }

  async function fetchOffer() {
    // showLoading()
    const { error, data } = await get({
      token,
      id
    })

    // hideLoading()
    if (error) {
      formContext.reset({})
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    if (!data) return
    formContext.reset({
      name: data.name,
      value: data.value,
      business: data.business ? 'true' : 'false',
      status: data.status ? 'true' : 'false',
      partnerId: data.partner.id,
      channelId: data.salesChannel && data.salesChannel.id
    })
    setImage(data.image)
  }

  function getPartnerImage(partnerId) {
    return partners.find((({ id }) => id === partnerId) || {}).image
  }

  async function onSubmit(values) {
    const { name, status, business, value, partnerId, channelId } = values

    // showLoading()
    const { error } = await save({
      id,
      data: {
        name,
        status: status === 'true',
        business: business === 'true',
        value: +value,
        partnerId,
        id_sales_channel: channelId
      },
      token
    })

    // hideLoading()
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    imageFile && onUploadImage(imageFile, id)
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    fetchOffer()
  }

  async function onUploadImage(file, id) {
    const { error } = await uploadOfferImage({
      token,
      offerId: id,
      file
    })
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.UPLOAD_IMAGE_ERROR' }))
      return
    }
    setImageFile(null)
  }

  function onPartnerChange(partnerId) {
    setImage(getPartnerImage(Number(partnerId)))
  }

  function onSetImage(file) {
    setImageFile(file)
  }

  useEffect(() => {
    fetchPartners()
    fetchChannels()
  }, [])

  useEffect(() => {
    fetchOffer()
  }, [id])

  useEffect(() => {
    document.title = 'Control Room - Modifica Offerta'
  }, [])

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({
            id: `EDIT_OFFER.${canWrite ? 'TITLE' : 'TITLE_READONLY'}`
          })}
        </PageTitle>
      </div>
      <FormProvider {...formContext}>
        {partners && channels && (
          <OfferForm
            image={image}
            onPartnerChange={onPartnerChange}
            onSetImage={onSetImage}
            canWrite={canWrite}
            partners={partners}
            channels={channels}
            onSubmit={onSubmit}
          />
        )}
      </FormProvider>
    </>
  )
}

export default ModificaOfferta
