import React, { useMemo, useState } from 'react'
import {
  ResponseOfferDto,
  useGetOffersListQuery
} from '../../../store/api/offers'
import AutoSelect, { AutoSelectProps } from '../AutoSelect'

export type OfferSelectProps = Pick<
  AutoSelectProps<ResponseOfferDto>,
  'value' | 'onChange'
> & {
  partnerId?: number
  salesChannelId?: number
}

export default ({ partnerId, salesChannelId, ...props }: OfferSelectProps) => {
  const [searchValue, setSearchValue] = useState<string>()

  const args = useMemo(
    () => ({
      ...(searchValue && { name: searchValue }),
      ...(partnerId && { partner: [partnerId] }),
      ...(salesChannelId && { salesChannel: [salesChannelId] }),
      limit: 10
    }),
    [searchValue, partnerId, salesChannelId]
  )

  const { data, isFetching } = useGetOffersListQuery(args)

  return (
    <AutoSelect
      options={data?.items ?? []}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onSearchChange={setSearchValue}
      loading={isFetching}
      getOptionLabel={o => o.name}
      {...props}
    />
  )
}
