import { skipToken } from '@reduxjs/toolkit/query'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetCustomerQuery } from '../../../store/api/customers'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import PageTitle from '../../../components/Typography/PageTitle'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Stack } from '@mui/material'
import {
  ResponseStatusDto,
  useCreateContractMutation
} from '../../../store/api/contracts'
import useLogin from '../../../hooks/useLogin'
import { useAlert } from 'react-alert'
import { useForm } from 'react-hook-form'
import { ResponseUserDto } from '../../../store/api/users'
import { ResponseOfferDto } from '../../../store/api/offers'
import NullableProprieties from '../../../helpers/typescript/NullableProprieties'
import NonNullableProprieties from '../../../helpers/typescript/NonNullableProprieties'
import CustomerSection from '../components/CustomerSection'
import OfferSection from '../components/OfferSection'
import DataSection from '../components/DataSection'
import UserSection from '../components/UserSection'

export type NewContractFormStaus = NullableProprieties<{
  user: ResponseUserDto
  offer: ResponseOfferDto
  status: ResponseStatusDto
  contractDate: string
  podPdr: string
  code: string
}>

export type ContractParams = {
  customerId: string
}

export default () => {
  const intl = useIntl()
  const history = useNavigate()
  const alert = useAlert()
  const { isLogged, can } = useLogin()

  const { customerId } = useParams<ContractParams>()

  const canSetUser = useMemo(
    () =>
      isLogged &&
      can('Get', 'users@*') &&
      (can('Create', 'contracts@all') || can('Create', 'contracts@agency')),
    [isLogged, can]
  )

  const { error } = useGetCustomerQuery(
    customerId ? parseInt(customerId) : skipToken
  )
  useEffect(() => {
    if (!error) return
    if (!('status' in error)) return
    if (error.status !== 404) return
    alert.error('Customer not found')
    history(-1)
  }, [error])

  const form = useForm<NewContractFormStaus>({
    defaultValues: {
      status: null,
      user: null,
      offer: null,
      contractDate: null,
      podPdr: null,
      code: null
    }
  })

  const [createContract, createContractStatus] = useCreateContractMutation()

  useEffect(() => {
    if (!createContractStatus.isSuccess) return
    alert.success('Contratto creato con successo')
    history(`/contracts/${createContractStatus.data?.id}`, { replace: true })
  }, [createContractStatus])

  const onSubmit = useCallback(
    ({
      user,
      offer,
      status,
      ...data
    }: NonNullableProprieties<NewContractFormStaus>) => {
      createContract({
        customerId: parseInt(customerId as string),
        userId: user.id,
        offerId: offer.id,
        statusId: status.id,
        ...data
      })
    },
    [customerId]
  )

  return (
    <>
      <form
        onSubmit={form.handleSubmit(d =>
          onSubmit(d as NonNullableProprieties<NewContractFormStaus>)
        )}>
        <div className="d-flex items-center g-5 g-xl-10 mb-5 mb-xl-10 w-100">
          <Button
            className="lightblue-btn rounded-full mr-3 "
            style={{ padding: '0' }}
            onClick={() => history(-1)}>
            <ArrowLeftIcon style={{ width: '1.5rem', height: '1.5rem' }} />
          </Button>

          <PageTitle>
            {intl.formatMessage({ id: 'NEW_CONTRACT.TITLE' })}
          </PageTitle>
        </div>

        <Stack spacing={2}>
          {customerId && <CustomerSection customerId={parseInt(customerId)} />}

          {canSetUser && <UserSection form={form} />}

          <OfferSection form={form} />

          <DataSection form={form} />

          <div className="flex flex-row-reverse">
            <Button type="submit">Crea contratto</Button>
          </div>
        </Stack>
      </form>
    </>
  )
}
