// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PageTitle from '../../../components/Typography/PageTitle'
import {
  TableBody,
  TableContainer,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Button
} from '@windmill/react-ui'
import { useIntl } from 'react-intl'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import BonusValue from '../../../components/PianiCompenso/BonusValue'

function ModificaPianoCompensoPresentational({
  currentPermissions,
  offer,
  roles,
  offerBonuses,
  onChangeOfferBonus,
  onSubmitOffer
  //sumMatch
}) {
  const canWrite = currentPermissions?.can('write', 'workflows')
  const history = useNavigate()
  const intl = useIntl()

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({
            id: `MODIFICA_PIANO.${canWrite ? 'TITLE' : 'TITLE_READONLY'}`
          })}
          {offer?.name}
        </PageTitle>
        {canWrite && (
          <Button
            className="ml-auto lightblue-btn"
            onClick={onSubmitOffer}
            //disabled={!sumMatch}
          >
            {intl.formatMessage({ id: 'MODIFICA_PIANO.SAVE' })}
          </Button>
        )}
      </div>

      <TableContainer className="mb-8">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell className="w-1/2 sm:w-2/3 xl:w-4/5">
                {intl.formatMessage({ id: 'MODIFICA_PIANO.ROLE' })}
              </TableCell>
              <TableCell>
                {' '}
                {intl.formatMessage({ id: 'MODIFICA_PIANO.BONUS' })}
              </TableCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {roles &&
              roles.map(role => {
                return (
                  <TableRow key={`role-${role.id}`}>
                    <TableCell>
                      <span className="text-sm">{role.description}</span>
                    </TableCell>
                    <TableCell>
                      <BonusValue
                        offer={offer}
                        role={role}
                        value={offerBonuses[role.id] || 0}
                        onChangeOfferBonus={onChangeOfferBonus}
                        canWrite={canWrite}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        {/* <TableFooter>
          <div
            className={classNames(
              'flex items-center justify-end text-xs font-medium',
              sumMatch ? 'text-blue-600' : 'text-red-600'
            )}
          >
            <span>{sumMatch ? t('SUM_CHECK_OK') : t('SUM_CHECK_NOT_OK')}</span>
            <span className="ml-2 w-3 mr-2">
              {sumMatch ? (
                <CheckRoundIcon className="w-full" />
              ) : (
                <CrossRoundIcon className="w-full" />
              )}
            </span>
          </div>
        </TableFooter> */}
      </TableContainer>
    </>
  )
}

export default ModificaPianoCompensoPresentational
