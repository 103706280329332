import axios from 'axios'
import { AuthenticatedQuery, PSAQuery } from '../../interfaces/PaginatedQuery'

export interface ListPartnersParams {
  name?: string
}

export const list = async ({
  token,
  ...params
}: PSAQuery<ListPartnersParams>) => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/partners`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: params
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

interface GetPartnerParams {
  id: string
}

export const get = async ({
  id,
  token
}: AuthenticatedQuery<GetPartnerParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/partners/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreatePartnerParams {
  data: any
}
export const create = async ({
  data,
  token
}: AuthenticatedQuery<CreatePartnerParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/partners`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface SavePartnerParams {
  id: number
  data: any
}

export const save = async ({
  id,
  data,
  token
}: AuthenticatedQuery<SavePartnerParams>) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/partners/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
