import axios from 'axios'
import { AuthenticatedQuery } from '../../interfaces/PaginatedQuery'

export interface ListFiscalPositionsFilters {
  name?: string
}

export const list = async ({
  token,
  ...filters
}: AuthenticatedQuery<ListFiscalPositionsFilters>) => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/fiscalPositions`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: filters
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

interface getFiscalPositionsParams {
  id: number
}

export const get = async ({
  id,
  token
}: AuthenticatedQuery<getFiscalPositionsParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/fiscalPositions/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface createFiscalPositionParams {
  data: any
}

export const create = async ({
  data,
  token
}: AuthenticatedQuery<createFiscalPositionParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/fiscalPositions`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface saveFiscalPositionParams {
  id: number
  data: string
}

export const save = async ({
  id,
  data,
  token
}: AuthenticatedQuery<saveFiscalPositionParams>) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/fiscalPositions/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface getProspectusParams {
  id: number
}

export const getProspectus = async ({
  id,
  token
}: AuthenticatedQuery<getProspectusParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/fiscalPositions/prospectus/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
