import React from 'react'
import { UserTwoStepsCell } from './UserTwoStepsCell'
import { UserActionsCell } from './UserActionsCell'
import { User } from '../../core/_models'
import Moment from 'moment'
import { ColumnDef } from '@tanstack/react-table'

const gestisciAgentiColumns = (
  refetch: () => void = () => {}
): ColumnDef<User>[] => [
  {
    header: 'Data di creazione',
    accessorKey: 'createdAt',
    cell: ({ getValue }) =>
      Moment(getValue() as string).format('DD-MM-YYYY HH:mm')
  },
  {
    header: 'Nome agente',
    accessorKey: 'username'
  },
  {
    header: 'Nome',
    accessorKey: 'firstName'
  },
  {
    header: 'Cognome',
    accessorKey: 'lastName'
  },
  {
    header: 'Ruolo',
    accessorKey: 'role.description'
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'Stato',
    accessorKey: 'status',
    cell: ({ getValue }) => (
      <UserTwoStepsCell two_steps={getValue() as boolean} />
    )
  },
  {
    header: 'Azioni',
    cell: ({ row }) => (
      <UserActionsCell
        user={row.original}
        onChangeUserStatus={refetch}
        onDeleteUser={refetch}
      />
    )
  }
]

export { gestisciAgentiColumns }
