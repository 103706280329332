import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef
} from 'react'
import { Button } from '@windmill/react-ui'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import { CircularProgress } from '@mui/material'
import { useUploadContractAttachmentMutation } from '../../../../../store/api/contracts'

type AddContractAttachmentButtonProps = {
  contractId: number
}

export default ({ contractId }: AddContractAttachmentButtonProps) => {
  const alert = useAlert()
  const intl = useIntl()

  const inputFile = useRef<HTMLInputElement>(null)

  const handleClick = useCallback(() => {
    inputFile.current?.click()
  }, [inputFile])

  const [uploadContractAttachment, { isLoading, isError, isSuccess }] =
    useUploadContractAttachmentMutation()

  useEffect(() => {
    if (!isError) return
    alert.error(`Errore nel caricamento del file`)
  }, [isError])

  const handleFileChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    e => {
      e.preventDefault()
      e.stopPropagation()

      const file = e.target.files?.[0]
      if (!file) return
      uploadContractAttachment({ contractId, file })
    },
    [inputFile, uploadContractAttachment, contractId]
  )

  return (
    <>
      <Button
        className="rounded-full mr-3 fs-6 text-white"
        onClick={handleClick}
        disabled={isLoading}>
        {isLoading ? (
          <CircularProgress size={20} color="error" className="mr-2" />
        ) : (
          <i className="bi bi-upload fs-2 pr-2 text-white" />
        )}
        {intl.formatMessage({ id: 'NEW_CONTRACT.NEW_ATTACHMENT' })}
      </Button>
      <input
        id="file"
        type="file"
        accept="application/pdf"
        ref={inputFile}
        className="hidden"
        onChange={handleFileChange}
      />
    </>
  )
}
