import React, { useEffect, createContext } from 'react'
import { useAuth } from '../../../context/Auth'
import SediPresentational from './SediPresentational'

export const fetchAgenciesContext = createContext<() => void>(() => {})

function ListaQualifiche({ currentPermissions }: any) {
  const [{ token }] = useAuth()

  useEffect(() => {
    document.title = 'Control Room - Lista Sedi'
  }, [])

  return <SediPresentational currentPermissions={currentPermissions} />
}

export default ListaQualifiche
