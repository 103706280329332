import PageTitle from '../../../../components/Typography/PageTitle'
import { useGetWorkflowQuery } from '../../../../store/api/workflows'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { ComponentProps, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import WorkflowForm from '../WorkflowForm'

export default function EditWorkflowPage() {
  const history = useNavigate()

  const { id: idParam } = useParams()
  const id = parseInt(idParam || '')
  console.log(id, idParam)
  const {
    data: workflow,
    isLoading,
    error,
    isSuccess
  } = useGetWorkflowQuery(id)

  useEffect(() => {
    if (isNaN(id)) {
      history(-1)
    }
  }, [id])

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon as ComponentProps<typeof Button>['icon']}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>Modifica Workflow</PageTitle>
      </div>
      <div className="card mb-5 mb-xl-10">
        {(workflow && <WorkflowForm workflow={workflow} />) || 'Caricamento...'}
      </div>
    </>
  )
}
