import React from 'react'
import { CanaliActionsCell } from './CanaliActionsCell'
import ComparaTuLogo from '../../../../../assets/logo_tucloud.png'
import { ColumnDef } from '@tanstack/react-table'
import { SalesChannel } from '../../../../../interfaces/SalesChannel'
import TableThumbnail from '../../../../../components/TableThumbnail'

const canaliColumns = (refetch: () => void): ColumnDef<SalesChannel>[] => [
  {
    header: 'Logo',
    id: 'image',
    cell: ({ row }) => (
      <TableThumbnail
        src={row.original.image ?? ComparaTuLogo}
        alt={row.original.code}
      />
    )
  },
  {
    header: 'Codice univoco',
    accessorKey: 'code'
  },
  {
    header: 'Descrizione',
    accessorKey: 'description'
  },
  {
    header: 'Azioni',
    id: 'actions',
    cell: ({ row }) => (
      <CanaliActionsCell channel={row.original} onDelete={refetch} />
    )
  }
]

export { canaliColumns }
