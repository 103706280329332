import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import {
  CreateUserApiArg,
  CreateUserDto,
  ResponseUserDto,
  UpdateUserApiArg,
  UpdateUserDto
} from '../../store/api/users'
import { useAuth } from '../../context/Auth'
import { useAlert } from 'react-alert'
import { useIntl } from 'react-intl'
import { list as listFiscalPositions } from '../../lib/fetch/fiscalPositions'
import { list as listAgencies } from '../../lib/fetch/agencies'
import { useGetSalesChannelsListQuery } from '../../store/api/salesChannels'
import { DISTRICTS } from '../../lib/constants'
import { userToUpdateUser } from '../../helpers/userToUpdateUser'
import { ResponseRoleDto, useGetAllRolesQuery } from '../../store/api/roles'

const updateUserSchema = Yup.object().shape({
  email: Yup.string().required('Email è richiesto'),
  firstName: Yup.string().required('Nome è richiesto'),
  lastName: Yup.string().required('Cognome è richiesto'),
  salesChannelIds: Yup.array().required('Canali di vendita è richiesto'),
  mobile: Yup.string(),
  businessName: Yup.string(),
  fiscalCode: Yup.string().required('Codice fiscale è richiesto'),
  // budget: Yup.number().required('Budget è richiesto'),
  province: Yup.string().required('Provincia è richiesta'),
  city: Yup.string().required('Città è richiesta'),
  address: Yup.string().required('Indirizzo è richiesto'),
  zipCode: Yup.string(),
  iban: Yup.string().required('IBAN è richiesto'),
  roleId: Yup.number().required('Ruolo è richiesto'),
  fiscalPositionId: Yup.number(),
  agencyId: Yup.number().required('Agenzia è richiesta')
})

const newUserSchema = updateUserSchema.shape({
  ...updateUserSchema.fields,
  password: Yup.string().required('Password è richiesta'),
  mobile: Yup.string().required('Cellulare è richiesto'),
  businessName: Yup.string().required('Ragione sociale è richiesta'),
  fiscalPositionId: Yup.number().required('Posizione fiscale è richiesta'),
  zipCode: Yup.string().required('CAP è richiesto')
})

const baseUserValues = {
  email: '',
  firstName: '',
  lastName: '',
  salesChannelIds: [],
  mobile: '',
  businessName: '',
  fiscalCode: '',
  // budget: 0,
  province: '',
  city: '',
  address: '',
  zipCode: '',
  iban: '',
  roleId: 0,
  fiscalPositionId: 0,
  agencyId: 0,
  status: true
}

const createUserValues = {
  ...baseUserValues,
  password: ''
}

interface Props {
  user?: ResponseUserDto
  onUpdate?: (values: UpdateUserApiArg) => void
  onCreate?: (values: CreateUserApiArg) => void
  loading: boolean
}

type NewProps = (
  | {
      type: 'create'
      user?: never
      onCreate: (values: CreateUserApiArg) => void
      onUpdate?: never
    }
  | {
      type: 'update'
      user: ResponseUserDto
      onUpdate: (values: UpdateUserApiArg) => void
      onCreate?: never
    }
) & {
  loading: boolean
  isBackOffice: boolean
}

export function NewUserForm(props: NewProps) {
  const { type, loading, isBackOffice, user, onCreate, onUpdate } = props
  const [agencies, setAgencies] = useState([])
  const [fiscalPositions, setFiscalPositions] = useState([])
  const [saved, setSaved] = useState(false)
  const intl = useIntl()
  const [auth = {}] = useAuth()
  const { token = '' } = auth
  const alert = useAlert()
  const [confirmPassword, setConfirmPassword] = useState('')
  const values: CreateUserDto | UpdateUserDto =
    type === 'create' ? createUserValues : userToUpdateUser(user).updateUserDto

  const formik = useFormik<CreateUserDto | UpdateUserDto>({
    initialValues: values,
    validationSchema: type === 'update' ? updateUserSchema : newUserSchema,
    onSubmit: values => {
      if (user && !('password' in values)) {
        let newValues = Object.fromEntries(
          Object.entries(values).filter(([_, v]) => v)
        )
        onUpdate &&
          onUpdate({ id: user.id, updateUserDto: newValues as UpdateUserDto })
      } else if (
        'password' in values &&
        values.password &&
        values.password === confirmPassword
      ) {
        onCreate &&
          onCreate({
            address: values.address,
            businessName: values.businessName,
            // budget: values.budget,
            city: values.city,
            email: values.email,
            fiscalCode: values.fiscalCode,
            firstName: values.firstName,
            iban: values.iban,
            agencyId: values.agencyId,
            fiscalPositionId: values.fiscalPositionId,
            // parentId: values.parentId,
            lastName: values.lastName,
            mobile: values.mobile,
            password: values.password,
            province: values.province,
            salesChannelIds: values.salesChannelIds,
            zipCode: values.zipCode,
            roleId: values.roleId
          })
      }
    }
  })

  const { data: salesChannels } = useGetSalesChannelsListQuery({})
  const { data: roles } = useGetAllRolesQuery({})

  async function fetchAgencies() {
    let result
    try {
      result = await listAgencies({ token, page: 1, limit: 100 })
    } catch (error) {
      setAgencies([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    }
    setAgencies(result.items)
  }

  async function fetchFiscalPositions() {
    let result
    try {
      result = await listFiscalPositions({ token })
    } catch (error) {
      setFiscalPositions([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    }
    setFiscalPositions(result.items)
  }

  function generatePassword() {
    const pwd = Math.random().toString(36).slice(-10)
    formik.setFieldValue('password', pwd)
    setConfirmPassword(pwd)
  }

  useEffect(() => {
    fetchAgencies()
    fetchFiscalPositions()
    !user && generatePassword()
  }, [])

  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div id="kt_account_profile_details">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <div className="card-body pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Email
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Email"
                    {...formik.getFieldProps('email')}
                  />
                  {(formik.touched.email || saved) && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>

              {!user && (
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Password
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="password"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Password"
                      {...formik.getFieldProps('password')}
                    />
                    {'password' in formik.touched &&
                      'password' in formik.errors &&
                      (formik.touched.password || saved) &&
                      formik.errors.password && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.password}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}

              {!user && (
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Conferma Password
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="password"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Conferma Password"
                      value={confirmPassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                    />
                    {'password' in formik.values &&
                      confirmPassword !== formik.values.password && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            Le password non corrispondono
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Nome
                </label>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Nome"
                        {...formik.getFieldProps('firstName')}
                      />
                      {(formik.touched.firstName || saved) &&
                        formik.errors.firstName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.firstName}
                            </div>
                          </div>
                        )}
                    </div>

                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Cognome"
                        {...formik.getFieldProps('lastName')}
                      />
                      {(formik.touched.lastName || saved) &&
                        formik.errors.lastName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.lastName}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* sponsor is disabled for now */}

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Ruolo
                </label>
                {roles ? (
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select form-select-lg form-select-solid"
                      {...formik.getFieldProps('roleId')}>
                      <option value="">Seleziona ruolo</option>
                      {roles.items
                        .filter(
                          (role: ResponseRoleDto) =>
                            role.isBackOfficeRole === isBackOffice
                        )
                        .map((role: ResponseRoleDto) => (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                    </select>
                    {'roleId' in formik.touched &&
                      'roleId' in formik.errors &&
                      (formik.touched.roleId || saved) &&
                      formik.errors.roleId && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.roleId}
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <div>Caricando...</div>
                )}
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Agenzia
                </label>
                {agencies ? (
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select form-select-lg form-select-solid"
                      {...formik.getFieldProps('agencyId')}>
                      <option value="">Seleziona agenzia</option>
                      {agencies.map((agency: any) => (
                        <option key={agency.id} value={agency.id}>
                          {agency.name}
                        </option>
                      ))}
                    </select>
                    {(formik.touched.agencyId || saved) &&
                      formik.errors.agencyId && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.agencyId}
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <div>Caricando...</div>
                )}
              </div>
            </div>

            {/* <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Budget
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="number"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Budget"
                    {...formik.getFieldProps('budget')}
                  />
                  {(formik.touched.budget || saved) && formik.errors.budget && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.budget}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Posizione fiscale
                </label>
                {fiscalPositions ? (
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select form-select-lg form-select-solid"
                      {...formik.getFieldProps('fiscalPositionId')}>
                      <option value="">Seleziona posizione fiscale</option>
                      {fiscalPositions.map((fiscalPosition: any) => (
                        <option
                          key={fiscalPosition.id}
                          value={fiscalPosition.id}>
                          {fiscalPosition.name}
                        </option>
                      ))}
                    </select>
                    {(formik.touched.fiscalPositionId || saved) &&
                      formik.errors.fiscalPositionId && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.fiscalPositionId}
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <div>Caricando...</div>
                )}
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Canali di vendita
                </label>
                <div className="col-lg-8 fv-row">
                  {salesChannels ? (
                    salesChannels.items.map((channel: any) => (
                      <div
                        key={channel.id}
                        className="form-check form-check-inline mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={channel.id}
                          onChange={e => {
                            const value = parseInt(e.target.value)
                            const salesChannelIds = [
                              ...formik.values.salesChannelIds
                            ]
                            if (salesChannelIds.includes(value)) {
                              formik.setFieldValue(
                                'salesChannelIds',
                                salesChannelIds.filter((v: any) => v !== value)
                              )
                            } else {
                              formik.setFieldValue(
                                'salesChannelIds',
                                salesChannelIds.concat(value)
                              )
                            }
                          }}
                          name="salesChannels"
                          checked={formik.values.salesChannelIds.includes(
                            channel.id
                          )}
                        />
                        <label className="form-check-label fw-bold">
                          {channel.code}
                        </label>
                      </div>
                    ))
                  ) : (
                    <div>Caricando...</div>
                  )}
                  {(formik.touched.salesChannelIds || saved) &&
                    formik.errors.salesChannelIds && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.salesChannelIds as string}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Ragione sociale
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Ragione sociale"
                    {...formik.getFieldProps('businessName')}
                  />
                  {(formik.touched.businessName || saved) &&
                    formik.errors.businessName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.businessName}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Partita IVA / Codice Fiscale
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Partita IVA / Codice Fiscale"
                    {...formik.getFieldProps('fiscalCode')}
                  />
                  {(formik.touched.fiscalCode || saved) &&
                    formik.errors.fiscalCode && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.fiscalCode}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Cellulare
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Cellulare"
                    {...formik.getFieldProps('mobile')}
                  />
                  {(formik.touched.mobile || saved) && formik.errors.mobile && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.mobile}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Via
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Via"
                    {...formik.getFieldProps('address')}
                  />
                  {(formik.touched.address || saved) &&
                    formik.errors.address && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.address}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Città
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Città"
                    {...formik.getFieldProps('city')}
                  />
                  {(formik.touched.city || saved) && formik.errors.city && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.city}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Provincia
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    className="form-select form-select-solid"
                    size={6}
                    {...formik.getFieldProps('province')}>
                    <option value="">Seleziona provincia</option>
                    {DISTRICTS.map(({ nome, sigla }) => (
                      <option key={`district-${sigla}`} value={sigla}>
                        {`${nome} (${sigla})`}
                      </option>
                    ))}
                  </select>
                  {(formik.touched.province || saved) &&
                    formik.errors.province && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.province}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  CAP
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="CAP"
                    {...formik.getFieldProps('zipCode')}
                  />
                  {(formik.touched.zipCode || saved) &&
                    formik.errors.zipCode && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.zipCode}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  IBAN
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="IBAN"
                    {...formik.getFieldProps('iban')}
                  />
                  {(formik.touched.iban || saved) && formik.errors.iban && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.iban}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  setSaved(true)
                }}
                disabled={loading}>
                {!loading && (user ? 'Salva' : 'Crea')}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
