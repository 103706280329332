// @ts-nocheck
import React from 'react'
import { Column } from 'react-table'
import { PianiCompensiActionsCell } from './PianiCompensiActionsCell'
import { UserCustomHeader } from './UserCustomHeader'
import { formatCurrency } from '../../../../lib/helpers/formatters'

const pianiCompensiColumns: ReadonlyArray<Column<any>> = [
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Partner"
        className="min-w-125px"
      />
    ),
    accessor: 'partners.name',
    Cell: ({ ...props }) => {
      return props.data[props.row.index]?.partners?.name
    }
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Offerta"
        className="min-w-125px"
      />
    ),
    accessor: 'name'
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Valore aziendale"
        className="min-w-125px"
      />
    ),
    accessor: 'value',
    Cell: ({ ...props }) => {
      return formatCurrency(props.data[props.row.index]?.value)
    }
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Azioni"
        className="text-end min-w-100px"
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <PianiCompensiActionsCell user={props.data[props.row.index]} />
    )
  }
]

export { pianiCompensiColumns }
