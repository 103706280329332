import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { KTCard } from '../../../../../_metronic/helpers'
import useLogin from '../../../../hooks/useLogin'
import AddContractAttachmentButton from './AddContractAttachmentButton'
import ContractAttachementBox from './ContractAttachementBox'
import { ResponseContractAttachmentDto } from '../../../../store/api/contracts'

export type AttachmentsSectionProps = {
  contractAttachments: ResponseContractAttachmentDto[]
  contractId: number
}

export default ({
  contractAttachments,
  contractId
}: AttachmentsSectionProps) => {
  const intl = useIntl()

  const { isLogged, can } = useLogin()

  const canAdd = useMemo(
    () => isLogged && can('Create', 'contracts|attachments'),
    [isLogged, can]
  )

  return (
    <KTCard className="p-4">
      <div className="flex items-center justify-between mb-5">
        <p className="font-semibold text-gray-500 text-lg">
          {intl.formatMessage({ id: 'CONTRACT.ATTACHMENTS.TITLE' })}
        </p>
      </div>

      <div className="grid gap-2 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3">
        {contractAttachments.map(a => (
          <ContractAttachementBox
            key={a.id}
            contractId={contractId}
            contractAttachment={a}
          />
        ))}
      </div>

      {canAdd && (
        <div className="flex items-center mt-4">
          <AddContractAttachmentButton contractId={contractId} />
        </div>
      )}
    </KTCard>
  )
}
