import React, { useContext } from 'react'
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useAuth as useAuthoriz } from '../../../../../context/Auth'
import { Link } from 'react-router-dom'
import { remove } from '../../../../../lib/fetch/agencies'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../../../modules/auth'
import { fetchAgenciesContext } from '../..'
import { Sedi } from '../../core/_models'

type Props = {
  sede: Sedi
  onDelete?: () => void
}

const SediActionsCell: FC<Props> = ({ sede, onDelete = () => {} }) => {
  const [auth = {}] = useAuthoriz()
  const { token }: any = auth
  const intl = useIntl()
  const alert = useAlert()
  const { canWrite } = useAuth()
  const fetchAgencies = useContext(fetchAgenciesContext)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  async function onDeleteRole(id: number) {
    const response = await remove({ id, token })

    if ('data' in response && 'error' in response.data) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    onDelete()
  }

  return (
    <>
      {canWrite && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Link to={`/rete/modifica-sede/${sede.id}`}>
            <button
              type="button"
              className="btn btn-light-primary me-3"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end">
              <KTSVG
                path="/media/icons/duotune/general/gen055.svg"
                className="svg-icon-2"
              />
              Modifica
            </button>
          </Link>
          <button
            type="button"
            className="btn btn-light-danger"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-users-table-filter="delete_row"
            data-bs-toggle="modal"
            data-bs-target={'#kt_modal_2' + sede.id}>
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-2"
            />
            Elimina
          </button>
        </div>
      )}
      <div className="modal fade" tabIndex={-1} id={'kt_modal_2' + sede.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Eliminazione qualifica</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>
                Sei sicuro di voler eliminare la qualifica {sede.name}
                ?L'operazione è irreversibile
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                data-bs-target={'#kt_modal_2' + sede.id}
                onClick={() => {
                  onDeleteRole(sede.id)
                }}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { SediActionsCell }
