import { Link, useLocation } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import moment from 'moment'
import { ResponseCustomerDto } from '../../../../../store/api/customers'
import { useCallback } from 'react'

interface Props {
  customer?: ResponseCustomerDto
  numberContracts?: number
}

export function ProfileHeader({ customer, numberContracts }: Props) {
  const location = useLocation()

  const getPath = useCallback(
    (subPath: string) => {
      return `/customers/${subPath}/${customer?.id}`
    },
    [customer]
  )

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                    {customer?.firstName} {customer?.lastName}
                  </a>
                  <a href="#">
                    <KTSVG
                      path="/media/icons/duotune/general/gen026.svg"
                      className="svg-icon-1 svg-icon-primary"
                    />
                  </a>
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  {customer?.job && (
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                      <KTSVG
                        path="/media/icons/duotune/communication/com006.svg"
                        className="svg-icon-4 me-1"
                      />
                      {customer?.job}
                    </a>
                  )}
                  {customer?.birthPlace && (
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                      <KTSVG
                        path="/media/icons/duotune/general/gen018.svg"
                        className="svg-icon-4 me-1"
                      />
                      {moment(customer?.birthDate).format('DD-MM-YYYY')} (
                      {customer?.birthPlace})
                    </a>
                  )}
                  {customer?.email && (
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                      <KTSVG
                        path="/media/icons/duotune/communication/com011.svg"
                        className="svg-icon-4 me-1"
                      />
                      {customer?.email}
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex flex-wrap flex-stack">
              <div className="d-flex flex-column flex-grow-1 pe-8">
                <div className="d-flex flex-wrap">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <KTSVG
                        path="/media/icons/duotune/files/fil025.svg"
                        className="svg-icon-1  me-2"
                      />
                      <div className="fs-2 fw-bolder">
                        {numberContracts ?? 0}
                      </div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">Contratti</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === getPath('edit') && 'active')
                }
                to={getPath('edit')}>
                Dettagli profilo
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === getPath('contracts') && 'active')
                }
                to={getPath('contracts')}>
                Contratti
              </Link>
            </li>
            {false && (
              <>
                <li className="nav-item">
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === getPath('questionary') && 'active')
                    }
                    to={getPath('questionary')}>
                    Questionario
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === getPath('privacy') && 'active')
                    }
                    to={getPath('privacy')}>
                    Privacy
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
