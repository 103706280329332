import React from 'react'
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { deleteUser } from '../../core/_requests'
import { Link } from 'react-router-dom'
import { User } from '../../core/_models'
import { useIntl } from 'react-intl'
import { saveUser } from '../../../../../../components/GestioneUtenze/core/_requests'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../../../../modules/auth'

type Props = {
  user: User
  onChangeUserStatus: () => void
  onDeleteUser: () => void
}

const UserActionsCell: FC<Props> = ({
  user,
  onDeleteUser,
  onChangeUserStatus
}) => {
  const { setItemIdForUpdate } = useListView()
  const intl = useIntl()
  const alert = useAlert()
  const { canWrite } = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  async function handleChangeUserStatus(user: any) {
    try {
      const response = await saveUser({
        data: {
          status: !user.status
        },
        id: user.id
      })
    } catch (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    onChangeUserStatus()
  }

  async function handleDeleteUser(id: ID) {
    const response = await deleteUser(id)

    if (!response) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    onDeleteUser()
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {canWrite && (
          <Link to={`/agenzia/gestisci-agenti/modifica-utente/${user.id}`}>
            <button
              type="button"
              className="btn btn-light-primary me-3"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end">
              <KTSVG
                path="/media/icons/duotune/general/gen055.svg"
                className="svg-icon-2"
              />
              Modifica
            </button>
          </Link>
        )}
        {false && (
          <>
            <button
              type="button"
              className="btn btn-light-primary me-3"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-users-table-filter="edit_row"
              data-bs-toggle="modal"
              data-bs-target={'#kt_modal_1' + user.id}>
              <KTSVG
                path="/media/icons/duotune/general/gen040.svg"
                className="svg-icon-2"
              />
              {user.status ? 'Disattiva' : 'Attiva'}
            </button>
            <button
              type="button"
              className="btn btn-light-primary me-3"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-users-table-filter="delete_row"
              data-bs-toggle="modal"
              data-bs-target={'#kt_modal_2' + user.id}>
              <KTSVG
                path="/media/icons/duotune/general/gen027.svg"
                className="svg-icon-2"
              />
              Elimina
            </button>
          </>
        )}
      </div>
      <div className="modal fade" tabIndex={-1} id={'kt_modal_1' + user.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {user.status ? 'Disabilita' : 'Abilita'} agente
              </h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>
                Sei sicuro di voler {user.status ? 'disattivare' : 'attivare'}{' '}
                l'agente {user.username} {user.first_name} {user.last_name}?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleChangeUserStatus(user)
                }}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id={'kt_modal_2' + user.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Elimina agente</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>
                Sei sicuro di voler eliminare l'agente {user.username}{' '}
                {user.first_name}
                {user.last_name}?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleDeleteUser(user.id)
                }}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { UserActionsCell }
