import axios from 'axios'

export const list = async (params = {}) => {
  const { token, page, limit, ordering, sort, csv, month, year, fiscal_code } =
    params

  const queryString = {
    page,
    limit,
    ordering,
    sort,
    csv,
    month,
    year,
    fiscal_code
  }

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/balances`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: queryString
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const getItems = async (params = {}) => {
  const {
    id,
    token,
    page,
    limit,
    ordering,
    sort,
    first_name,
    last_name,
    username,
    csv
  } = params

  const queryString = {
    page,
    limit,
    ordering,
    sort,
    csv,
    first_name,
    last_name,
    username
  }

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/balances/${id}/items`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: queryString
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const get = async ({ token, id }) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/balances/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const create = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/balances`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const download = async ({ userId, balanceId, token } = {}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users/download/balance/${userId}/${balanceId}`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const update = async ({ id, data, token } = {}) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/balances/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const remove = async ({ id, token } = {}) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/balances/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
