// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { useCookies } from 'react-cookie'
import { useAlert } from 'react-alert'
import createInterceptor from '../../lib/helpers/interceptor'
import { fetchMe } from '../../lib/fetch/me'
import { initialState, authReducer } from './authReducer'
import { useIntl } from 'react-intl'

interface AuthState {
  token?: string
}

const AuthContext = createContext<[AuthState, () => {}]>([{}, () => {}])

const AuthProvider = ({ children }: any) => {
  const alert = useAlert()
  const intl = useIntl()
  const [cookies = {}, setCookie, removeCookie] = useCookies(['token'])
  const { token } = cookies
  const isAuthenticated = !!(token && token.length > 0)
  const [state, dispatch] = useReducer(authReducer, {
    ...initialState,
    token,
    isAuthenticated
  })

  const dispatchFunction = (action = {}) => {
    const { type, token, maxAge } = action

    switch (type) {
      case 'login':
        setCookie('token', token, {
          path: '/',
          maxAge: maxAge || process.env.REACT_APP_TOKEN_EXPIRATION
        })
        break
      case 'logout':
        console.error('logout')
        removeCookie('token')
        break
      default:
        break
    }
    console.log('dispatch', action)
    return dispatch(action)
  }

  useEffect(() => {
    createInterceptor(401, () => {
      alert.error(intl.formatMessage({ id: 'COMMON.UNAUTHORIZED' }))
      console.error('logout')
      dispatch({ type: 'logout' })
    })
  }, [])

  useEffect(() => {
    const callMe = async () => {
      const { error, data } = await fetchMe(token)
      if (error) {
        console.error(error)
        dispatch({ type: 'logout' })
        alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
        return
      }
      dispatch({ type: 'setProfile', profile: data })
    }
    if (isAuthenticated) {
      callMe()
    }
  }, [token])

  return (
    <AuthContext.Provider value={[state, dispatchFunction]}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, AuthContext, useAuth }
