import React from 'react'
import { PosizioniFiscaliActionsCell } from './PosizioniFiscaliActionsCell'
import { FiscalPosition } from '../../core/_models'
import { ColumnDef } from '@tanstack/react-table'

const posizioniFiscaliColumns = (
  refetch: () => void = () => {}
): ColumnDef<FiscalPosition>[] => [
  {
    header: '#',
    accessorKey: 'order'
  },
  {
    header: 'Descrizione',
    accessorKey: 'name'
  },
  {
    header: 'Azioni',
    cell: ({ row }) => <PosizioniFiscaliActionsCell user={row.original} />
  }
]

export { posizioniFiscaliColumns }
