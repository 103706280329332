// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { list } from '../../../lib/fetch/roles'
import { useAuth } from '../../../context/Auth'
import ModificaPianoCompensoPresentational from './ModificaPianoPresentational'
import { useLoading } from '../../../context/LoadingContext'
import { get, save } from '../../../lib/fetch/offers'
import { useIntl } from 'react-intl'

function ModificaPianoCompenso({ currentPermissions }) {
  const alert = useAlert()
  const { showLoading, hideLoading } = useLoading()
  const [{ token } = {}] = useAuth()
  const { id } = useParams()
  const [offer, setOffer] = useState(null)
  const [roles, setRoles] = useState([])
  const [offerBonuses, setOfferBonuses] = useState({})
  //const [sumMatch, setSumMatch] = useState(true);
  const intl = useIntl()

  const fetchRoles = async () => {
    const { error, data } = await list(token)

    if (error) {
      setRoles([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    setRoles(data)
  }

  const fetchOffer = async () => {
    const { error, data } = await get({
      token,
      id
    })

    if (error) {
      setOffer(null)
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    const { offer = {} } = data
    setOffer(offer)
    try {
      const bonuses = JSON.parse(offer.per_agent_values)
      setOfferBonuses(bonuses || {})
      // const match =
      //   Object.values(bonuses).reduce((sum, val) => sum + val, 0) ===
      //   Number(offer.value);
      //  setSumMatch(match);
    } catch (err) {
      setOfferBonuses({})

      // setSumMatch(false);
    }
  }

  const onSubmitOffer = async () => {
    // if (!sumMatch) {
    //   alert.error(t('SUM_CHECK_NOT_OK'));
    //   return;
    // }
    const { error } = await save({
      id,
      token,
      data: {
        name: offer.name,
        image: offer.image,
        status: offer.status,
        business: offer.business,
        partnerId: offer.partners && offer.partners.id,
        value: offer.value,
        id_sales_channel: offer.salesChannels && offer.salesChannels.id,
        per_agent_values: JSON.stringify(offerBonuses)
      }
    })
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    fetchOffer()
  }

  const onChangeOfferBonus = (role, value) => {
    setOfferBonuses(offerBonuses => {
      const newBonuses = { ...offerBonuses, [role.id]: Number(value) }
      //const sum = Object.values(newBonuses).reduce((sum, val) => sum + val, 0);
      // const match = sum === Number(offer.value);
      // setSumMatch(match);
      return newBonuses
    })
  }

  useEffect(() => {
    showLoading()
    fetchOffer()
      .then(() => fetchRoles())
      .then(() => hideLoading())
  }, [])

  useEffect(() => {
    document.title = 'Control Room - Modifica piano'
  }, [])

  return (
    <ModificaPianoCompensoPresentational
      currentPermissions={currentPermissions}
      offer={offer}
      roles={roles}
      offerBonuses={offerBonuses}
      onChangeOfferBonus={onChangeOfferBonus}
      onSubmitOffer={onSubmitOffer}
      // sumMatch={sumMatch}
    />
  )
}

export default ModificaPianoCompenso
