import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { KTCard } from '../../../../../_metronic/helpers'
import UserSelect from '../../../../components/selects/UserSelect'
import { NewContractFormStaus } from '../../NewContract'

export type UserSectionProps = {
  form: UseFormReturn<NewContractFormStaus>
  readonly?: boolean
}

export default ({ form: { control, watch }, readonly }: UserSectionProps) => {
  const intl = useIntl()

  const user = watch('user')

  return (
    <KTCard className="p-4">
      <div className="flex items-center justify-between mb-5">
        <p className="font-semibold text-gray-500 text-lg">
          {intl.formatMessage({ id: 'CONTRACT.USER.TITLE' })}
        </p>
      </div>

      {readonly ? (
        <>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <p className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'COMMON.NAME' })}
            </p>
            <p>
              {user?.firstName} {user?.lastName}
            </p>
          </div>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <p className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'COMMON.FISCAL_CODE' })}
            </p>
            <p className="font-mono">{user?.fiscalCode}</p>
          </div>
        </>
      ) : (
        <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
          <label
            className="font-medium text-gray-500"
            style={{ minWidth: '200px' }}>
            <span className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.USER.TITLE' })}
            </span>
            <Controller
              render={({ field: { value, onChange } }) => (
                <UserSelect value={value} onChange={(_, v) => onChange(v)} />
              )}
              name="user"
              rules={{ required: true }}
              control={control}
            />
          </label>
        </div>
      )}
    </KTCard>
  )
}
