import { skipToken } from '@reduxjs/toolkit/query'
import { Link } from 'react-router-dom'
import { Button, CardBody } from '@windmill/react-ui'
import React from 'react'
import { useIntl } from 'react-intl'
import { useGetCustomerQuery } from '../../../../store/api/customers'
import { CircularProgress } from '@mui/material'
import { KTCard } from '../../../../../_metronic/helpers'

export type CustomerSectionProps = {
  customerId: number
}

export default ({ customerId }: CustomerSectionProps) => {
  const intl = useIntl()

  const {
    data: customer,
    isSuccess,
    isError,
    isLoading,
    refetch
  } = useGetCustomerQuery(customerId ?? skipToken)

  return (
    <KTCard>
      <CardBody>
        <div className="flex items-center justify-between mb-5">
          <p className="mb-5 font-semibold text-gray-500 text-lg">
            {intl.formatMessage({ id: 'CONTRACT.CUSTOMER.TITLE' })}
          </p>
          <p>
            <Link to={`/customers/${customerId}`}>
              <Button
                size="regular"
                className="rounded-full mr-3 fs-6 text-white">
                <i className="text-2xl bi bi-file-earmark-person-fill fs-2 text-white" />
              </Button>
            </Link>
          </p>
        </div>
        {isSuccess && (
          <>
            <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
              <p className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'COMMON.FIRST_NAME' })}
              </p>
              <p>{customer.firstName}</p>
            </div>
            <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
              <p className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'COMMON.LAST_NAME' })}
              </p>
              <p>{customer.lastName}</p>
            </div>
            <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
              <p className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CUSTOMER_FORM.FISCAL_CODE' })}
              </p>
              <p className="font-mono uppercase">{customer.fiscalCode}</p>
            </div>
            <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
              <p className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CUSTOMER_FORM.COMPANY_NAME' })}
              </p>
              <p>{customer.company}</p>
            </div>
            <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
              <p className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CUSTOMER_FORM.PHONE' })}
              </p>
              <p>{customer.phone}</p>
            </div>
            <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
              <p className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CUSTOMER_FORM.MOBILE' })}
              </p>
              <p>{customer.mobile ?? '-'}</p>
            </div>
          </>
        )}
        {isLoading && (
          <div
            style={{ height: 100 }}
            className="flex flex-column justify-evenly items-center">
            <CircularProgress />
          </div>
        )}
        {isError && (
          <div
            style={{ height: 100 }}
            className="flex flex-column justify-evenly items-center">
            <p>Errore in fase di caricamento</p>
            <Button className="lightblue-btn mx-auto" onClick={refetch}>
              Riprova
            </Button>
          </div>
        )}
      </CardBody>
    </KTCard>
  )
}
