import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import RoleForm from '../../../components/Roles/RoleForm'
import PoliciesForm from '../../../components/Roles/PoliciesForm'
import { useIntl } from 'react-intl'
import { ResponseRoleDto } from '../../../store/api/roles'

type Props = {
  currentPermissions: any
  role: ResponseRoleDto
  loading?: boolean
} & Pick<React.ComponentProps<typeof PoliciesForm>, 'onSave'>

function ModificaQualificaPresentational({
  currentPermissions,
  role,
  loading,
  onSave
}: Props) {
  const { canWrite, can = () => {} } = currentPermissions
  const canReadPolicies = can('read', 'policies')
  const canWritePolicies = can('write', 'policies')
  const history = useNavigate()
  const intl = useIntl()

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon as any}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({
            id: `EDIT_ROLE.${canWrite ? 'TITLE' : 'TITLE_READONLY'}`
          })}
        </PageTitle>
      </div>
      <RoleForm
        type="update"
        canWrite={canWritePolicies}
        onUpdate={onSave}
        role={role}
        loading={loading}
      />
    </>
  )
}

export default ModificaQualificaPresentational
