import axios from 'axios'

export const list = async ({
  token,
  startDate,
  endDate,
  salesChannels,
  metrics,
  agencies,
  users
}: {
  token: string
  startDate: string
  endDate: string
  salesChannels?: number[]
  metrics?: Metric
  agencies?: number[]
  users?: number[]
}): Promise<Statistics | { error: Error }> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/statistics/contracts/partners-workflows`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        startDate,
        endDate,
        salesChannels,
        metrics,
        agencies,
        users
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response && (response.data as Statistics)
  } catch (error) {
    // Sentry TODO
    return { error: error as Error }
  }
}

export interface Statistics {
  metrics: Metric
  workflows: Workflow[]
  data: Data[]
  totals: Record<string, number>[]
}

export type Metric = 'count' | 'value'

export interface Workflow {
  id: number
  name: string
  isValidForStatistics: boolean
  isValidForTarget: boolean
  isValidForRanking: boolean
}

export interface Data {
  partner: Partner
  values: Values
  total: number
}

export interface Partner {
  id: string
  name: string
  image: string
  status: boolean
}

export type Values = Record<number, number>
