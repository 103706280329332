// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import { Card, CardBody, Input, Button, Select } from '@windmill/react-ui'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'

const RoleFormLabels = ({ canWrite, onSubmit, roles }) => {
  const intl = useIntl()

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <Card className="mb-2">
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={!canWrite}>
            <div className="grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
              <label className="mb-2">
                <span>
                  {intl.formatMessage({ id: 'ROLE_FORM_LABELS.NEW_ROLE' })}
                </span>
                <br />
                <Input
                  className="mt-1 text-gray-800"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'ROLE_FORM_LABELS.NEW_ROLE'
                  })}
                  {...register('name', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                    maxLength: {
                      value: 30,
                      message: 'Max 30'
                    }
                  })}
                />
                {errors.name && errors.name.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
            <div className="grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
              <label className="mb-2">
                <span>
                  {intl.formatMessage({
                    id: 'ROLE_FORM_LABELS.QUALIFICA_ASSOCIATA'
                  })}
                </span>
                <br />
                <Select
                  className="mt-1"
                  {...register('roleId', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}>
                  {roles.map(role => (
                    <option key={`role-${role.id}`} value={role.id}>
                      {role.description}
                    </option>
                  ))}
                </Select>
                {errors.roledId && errors.roledId.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.roledId.message}
                  </span>
                )}
              </label>
            </div>
          </fieldset>
          {canWrite && (
            <div className="grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
              <div className="mt-2">
                <Button type="submit" className="lightblue-btn">
                  {intl.formatMessage({ id: 'COMMON.SAVE' })}
                </Button>
              </div>
            </div>
          )}
        </form>
      </CardBody>
    </Card>
  )
}

export default RoleFormLabels
