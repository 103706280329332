// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import { CardBody } from '@windmill/react-ui'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import ClipLoader from 'react-spinners/ClipLoader'
import { css } from '@emotion/react'
import { KTCard } from '../../../_metronic/helpers'

function PianiCompensoFilters({
  partners,
  partnersLoading,
  onSetFilters,
  onClearFilters
}: any) {
  const intl = useIntl()

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      partnerId: []
    }
  })

  const clipLoaderCss = css`
    height: 20px;
    width: 20px;
  `

  return (
    <KTCard className="mb-8">
      <CardBody>
        <p className="mb-4 font-semibold text-gray-500 text-lg">
          {intl.formatMessage({ id: 'COMMON.FILTERS' })}
        </p>

        <label className="mb-1">
          <span>
            {intl.formatMessage({ id: 'PIANI_COMPENSO.PARTNER_FILTER' })}
          </span>
        </label>
        <form onSubmit={handleSubmit(onSetFilters)}>
          <div>
            <ClipLoader
              color="#3263e9"
              css={clipLoaderCss}
              loading={partnersLoading}
            />
            {partners.map(({ id, name }: any) => (
              <label key={`partner-${id}`} check className="mr-4">
                <input type="checkbox" value={id} {...register('partner')} />
                <span style={{ marginLeft: '3px' }}>{name}</span>
              </label>
            ))}
          </div>
          <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-5 items-end mt-2">
            <label>
              <span>{intl.formatMessage({ id: 'COMMON.NAME' })}</span>
              <input
                type="text"
                className="form-control form-control-lg mt-1"
                {...register('name')}
              />
            </label>
            <div className="flex mt-5">
              <button type="submit" className="btn btn-primary fw-bold px-6">
                {intl.formatMessage({ id: 'COMMON.FILTER' })}
              </button>
              <button
                layout="outline"
                className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  onClearFilters()
                  reset()
                }}>
                {intl.formatMessage({ id: 'COMMON.CLEAR_FILTER' })}
              </button>
            </div>
          </div>
        </form>
      </CardBody>
    </KTCard>
  )
}

export default PianiCompensoFilters
