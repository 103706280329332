import React from 'react'

import { EstrattoContoBackofficeTable } from './table_backoffice/EstrattoContoBackofficeTable'
import { KTCard } from '../../../_metronic/helpers'

function EstrattiContoBackofficeTable() {
  return (
    <>
      <KTCard className="mb-8">
        <EstrattoContoBackofficeTable />
      </KTCard>
    </>
  )
}

export default EstrattiContoBackofficeTable
