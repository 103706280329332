// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { Button, Card, CardBody, Select } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import {
  getAvailableDates,
  getAvailableMonths,
  getAvailableYears
} from '../../../lib/helpers/dates'
import { MONTHS } from '../../../lib/constants'

const START_DATE = moment('2021-09-01')

function NuovoEstrattoPresentational({ onSubmit }) {
  const intl = useIntl()
  const history = useNavigate()
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue
  } = useForm()
  const yearWatch = watch('year')

  const availableDates = getAvailableDates(START_DATE, moment())
  const availableYears = getAvailableYears(availableDates)
  const availableMonths = getAvailableMonths(availableDates, yearWatch)

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== 'year') return
      setValue('month', '')
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({ id: 'NUOVO_ESTRATTO.TITLE' })}
        </PageTitle>
      </div>
      <Card className="mb-2">
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
              <label className="mb-2">
                <span>{intl.formatMessage({ id: 'NUOVO_ESTRATTO.YEAR' })}</span>
                <br />
                <Select
                  className="mt-1"
                  {...register('year', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                  defaultValue="">
                  <option disabled value="" />
                  {availableYears.map(y => (
                    <option key={y} value={y}>
                      {y}
                    </option>
                  ))}
                </Select>
                {errors.year && errors.year.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.year.message}
                  </span>
                )}
              </label>
            </div>
            <div className="grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
              <label className="mb-2">
                <span>
                  {intl.formatMessage({ id: 'NUOVO_ESTRATTO.MONTH' })}
                </span>
                <br />
                <Select
                  className="mt-1"
                  {...register('month', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                  defaultValue="">
                  <option disabled value="" />
                  {availableMonths.map(m => (
                    <option key={m} value={MONTHS[m].en}>
                      {intl.formatMessage({ id: `MONTHS.${m}` })}
                    </option>
                  ))}
                </Select>
                {errors.month && errors.month.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.month.message}
                  </span>
                )}
              </label>
            </div>
            <div className="grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
              <div className="mt-2">
                <Button type="submit" className="lightblue-btn">
                  {intl.formatMessage({ id: 'NUOVO_ESTRATTO.CREATE' })}
                </Button>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  )
}

export default NuovoEstrattoPresentational
