import { api } from './index'
export const addTagTypes = ['SalesChannels'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      createSalesChannel: build.mutation<
        CreateSalesChannelApiResponse,
        CreateSalesChannelApiArg
      >({
        query: queryArg => ({
          url: `/api/salesChannels`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['SalesChannels']
      }),
      getSalesChannelsList: build.query<
        GetSalesChannelsListApiResponse,
        GetSalesChannelsListApiArg
      >({
        query: queryArg => ({
          url: `/api/salesChannels`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            code: queryArg.code,
            ordering: queryArg.ordering,
            sort: queryArg.sort,
            csv: queryArg.csv
          }
        }),
        providesTags: ['SalesChannels']
      }),
      getSalesChannel: build.query<
        GetSalesChannelApiResponse,
        GetSalesChannelApiArg
      >({
        query: queryArg => ({ url: `/api/salesChannels/${queryArg}` }),
        providesTags: ['SalesChannels']
      }),
      updateSalesChannel: build.mutation<
        UpdateSalesChannelApiResponse,
        UpdateSalesChannelApiArg
      >({
        query: queryArg => ({
          url: `/api/salesChannels/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.createSalesChannelDto
        }),
        invalidatesTags: ['SalesChannels']
      }),
      deleteSalesChannel: build.mutation<
        DeleteSalesChannelApiResponse,
        DeleteSalesChannelApiArg
      >({
        query: queryArg => ({
          url: `/api/salesChannels/${queryArg}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['SalesChannels']
      }),
      uploadSalesChannelImage: build.mutation<
        UploadSalesChannelImageApiResponse,
        UploadSalesChannelImageApiArg
      >({
        query: queryArg => ({
          url: `/api/salesChannels/uploads/image`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['SalesChannels']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type CreateSalesChannelApiResponse =
  /** status 200  */ ResponseWithStatusDto & {
    salesChannel: ResponseSalesChannelDto
  }
export type CreateSalesChannelApiArg = CreateSalesChannelDto
export type GetSalesChannelsListApiResponse =
  /** status 200  */ PaginatedResponseDto & {
    items?: ResponseSalesChannelDto[]
  }
export type GetSalesChannelsListApiArg = {
  page?: number
  limit?: number
  code?: string
  ordering?: string
  sort?: string
  csv?: boolean
}
export type GetSalesChannelApiResponse =
  /** status 200  */ ResponseSalesChannelDto
export type GetSalesChannelApiArg = number
export type UpdateSalesChannelApiResponse =
  /** status 200  */ ResponseSalesChannelDto
export type UpdateSalesChannelApiArg = {
  id: number
  createSalesChannelDto: CreateSalesChannelDto
}
export type DeleteSalesChannelApiResponse = unknown
export type DeleteSalesChannelApiArg = number
export type UploadSalesChannelImageApiResponse = unknown
export type UploadSalesChannelImageApiArg = UploadSaleChannelImageDto
export type ResponseWithStatusDto = {
  status: number
}
export type ResponseSalesChannelDto = {
  id: number
  code: string
  description: string
  image: string
  rankingMetrics: string
  isRankingValueVisible: boolean
  isRankingVisible: boolean
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type CreateSalesChannelDto = {
  code: string
  description: string
  rankingMetrics?: 'count' | 'value'
  isRankingValueVisible?: boolean
  isRankingVisible?: boolean
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  items: string[]
  meta: IPaginationMetaResponseDto
}
export type UploadSaleChannelImageDto = {
  id_sale_channel: number
  file: Blob
}
export const {
  useCreateSalesChannelMutation,
  useGetSalesChannelsListQuery,
  useLazyGetSalesChannelsListQuery,
  useGetSalesChannelQuery,
  useLazyGetSalesChannelQuery,
  useUpdateSalesChannelMutation,
  useDeleteSalesChannelMutation,
  useUploadSalesChannelImageMutation
} = injectedRtkApi
