import React from 'react'
import clsx from 'clsx'
import {
  useQueryResponseLoading,
  useQueryResponsePagination
} from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { KTSVG } from '../../../../../_metronic/helpers'

const mappedLabel = (label: string): string => {
  switch (label) {
    case 'next':
      return 'arr071'
    case 'last':
      return 'arr080'
    case 'previous':
      return 'arr074'
    case 'first':
      return 'arr079'
  }
  return label
}

const getPage = (link: any): number => {
  if (link.link.includes('page')) {
    return link.link.match('page=(.*)&')[1]
  }
  return -1
}

const getLink = (links: any, link: any): string => {
  return links[link]
}

const UsersListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()
  const updatePage = (currentPage: number | null) => {
    if (
      !currentPage ||
      isLoading ||
      pagination.meta?.currentPage === currentPage
    ) {
      return
    }
    updateState({
      currentPage,
      itemsPerPage: pagination.meta?.itemsPerPage || 10
    })
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            {Object.keys(pagination?.links ? pagination.links : {})
              ?.map((link, i) => {
                return {
                  name: link,
                  link: getLink(pagination.links, link),
                  label: mappedLabel(link)
                }
              })
              .map(link => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.meta?.currentPage === getPage(link),
                    disabled: isLoading,
                    previous: link.label === 'arr074',
                    next: link.label === 'arr071'
                  })}>
                  <button
                    type="button"
                    className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="top-end"
                    disabled={getPage(link) < 0}
                    onClick={() => updatePage(getPage(link))}>
                    <KTSVG
                      path={`/media/icons/duotune/arrows/${link.label}.svg`}
                      className="svg-icon-2"
                    />
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export { UsersListPagination }
