import { api } from './index'
import { ResponsePolicyDto } from './policies'
export const addTagTypes = ['Roles'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      createRole: build.mutation<CreateRoleResponse, CreateRoleArg>({
        query: queryArg => ({
          url: `/api/roles`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Roles']
      }),
      getAllRoles: build.query<GetAllRolesResponse, GetAllRolesArg>({
        query: queryArg => ({
          url: `/api/roles`,
          params: { page: queryArg.page, limit: queryArg.limit }
        }),
        providesTags: ['Roles']
      }),
      getRoleById: build.query<GetRolesByIdResponse, GetRolesByIdArg>({
        query: queryArg => ({ url: `/api/roles/${queryArg}` }),
        providesTags: ['Roles']
      }),
      removeRole: build.mutation<RemoveRoleResponse, RemoveRoleArg>({
        query: queryArg => ({
          url: `/api/roles/${queryArg}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Roles']
      }),
      updateRole: build.mutation<UpdateRoleResponse, UpdateRoleArg>({
        query: queryArg => ({
          url: `/api/roles/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.createDto
        }),
        invalidatesTags: ['Roles']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type CreateRoleResponse = /** status 200  */ ResponseRoleDto
export type CreateRoleArg = CreateRoleDto
export type GetAllRolesResponse = /** status 200  */ PaginatedResponseDto & {
  items?: ResponseRoleDto[]
}
export type GetAllRolesArg = {
  page?: number
  limit?: number
}
export type GetRolesByIdResponse = /** status 200  */ ResponseRoleDto
export type GetRolesByIdArg = number
export type RemoveRoleResponse = unknown
export type RemoveRoleArg = number
export type UpdateRoleResponse = /** status 200  */ ResponseRoleDto
export type UpdateRoleArg = {
  id: number
  createDto: CreateRoleDto
}
export type ResponseRoleDto = {
  id: number
  name: string
  description: string
  isBackOfficeRole: boolean
  policies: ResponsePolicyDto[]
  capabilities?: {
    agencies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statistics?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    ranking?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    roles?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    policies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    workflows?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    partners?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    offers?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    salesChannels?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statuses?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers|surveys'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|notes'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|attachments'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    notes?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    admins?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    fiscalPositions?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'fiscalPositions|prospectus'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    settings?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    balances?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    privacy?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
  }
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type CreateRoleDto = {
  name: string
  description: string
  isBackOfficeRole: boolean
  policies: number[]
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  items: string[]
  meta: IPaginationMetaResponseDto
}
export const {
  useCreateRoleMutation,
  useGetAllRolesQuery,
  useLazyGetAllRolesQuery,
  useGetRoleByIdQuery,
  useLazyGetRoleByIdQuery,
  useRemoveRoleMutation,
  useUpdateRoleMutation
} = injectedRtkApi
