import { KTCard } from '../../../../../_metronic/helpers'
import { Button, CardBody } from '@windmill/react-ui'
import { Controller, useForm } from 'react-hook-form'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import UserSelect from '../../../../components/selects/UserSelect'
import { ResponseUserDto } from '../../../../store/api/users'
import { ResponseOfferDto } from '../../../../store/api/offers'
import {
  GetContractsListApiArg,
  ResponseStatusDto
} from '../../../../store/api/contracts'
import StatusSelect from '../../../../components/selects/StatusSelect'
import { ResponsePartnerDto } from '../../../../store/api/partners'
import NullableProprieties from '../../../../helpers/typescript/NullableProprieties'
import { ResponseSalesChannelDto } from '../../../../store/api/salesChannels'
import SalesChannelSelect from '../../../../components/selects/SalesChannelSelect'
import PartnerSelect from '../../../../components/selects/PartnerSelect'
import CustomerSelect from '../../../../components/selects/CustomerSelect'
import { ResponseCustomerDto } from '../../../../store/api/customers'
import OfferSelect from '../../../../components/selects/OfferSelect'

type ContractsFiltersFormStatus = NullableProprieties<{
  user: ResponseUserDto
  customer: ResponseCustomerDto
  status: ResponseStatusDto
  partner: ResponsePartnerDto
  salesChannel: ResponseSalesChannelDto
  offer: ResponseOfferDto
  code: string
}>

export type ContractsFiltersProps = {
  onChange?: (
    filters: Pick<GetContractsListApiArg, keyof ContractsFiltersFormStatus> &
      Record<string, unknown>
  ) => void
}

export default ({ onChange = () => {} }: ContractsFiltersProps) => {
  const intl = useIntl()

  const { control, register, handleSubmit, reset } =
    useForm<ContractsFiltersFormStatus>({
      defaultValues: {
        user: null,
        offer: null,
        status: null,
        partner: null,
        salesChannel: null,
        customer: null,
        code: null
      }
    })

  const onSubmit = useCallback(
    ({
      user,
      customer,
      salesChannel,
      partner,
      offer,
      status,
      ...data
    }: ContractsFiltersFormStatus) => {
      onChange({
        ...(user && { user: [user.id] }),
        ...(customer && { customer: [customer.id] }),
        ...(salesChannel && { salesChannel: [salesChannel.id] }),
        ...(partner && { partner: [partner.id] }),
        ...(offer && { offer: [offer.id] }),
        ...(status && { status: [status.id] }),
        ...Object.fromEntries(Object.entries(data).filter(([_, v]) => v))
      })
    },
    [onChange]
  )

  return (
    <KTCard className="mb-8">
      <CardBody>
        <p
          className="mb-4 font-semibold text-gray-500 text-lg"
          style={{ fontSize: '1.125rem', fontWeight: '600' }}>
          {intl.formatMessage({ id: 'COMMON.FILTERS' })}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <label
              className="font-medium text-gray-500"
              style={{ minWidth: '200px' }}>
              <span className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CONTRACTS.FILTERS.USER' })}
              </span>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <UserSelect value={value} onChange={(_, v) => onChange(v)} />
                )}
                name="user"
                control={control}
              />
            </label>
            <label
              className="font-medium text-gray-500"
              style={{ minWidth: '200px' }}>
              <span className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CONTRACTS.FILTERS.CUSTOMER' })}
              </span>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <CustomerSelect
                    value={value}
                    onChange={(_, v) => onChange(v)}
                  />
                )}
                name="customer"
                control={control}
              />
            </label>
            <label
              className="font-medium text-gray-500"
              style={{ minWidth: '200px' }}>
              <span className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CONTRACTS.FILTERS.SALES_CHANNEL' })}
              </span>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <SalesChannelSelect
                    value={value}
                    onChange={(_, v) => onChange(v)}
                  />
                )}
                name="salesChannel"
                control={control}
              />
            </label>
            <label
              className="font-medium text-gray-500"
              style={{ minWidth: '200px' }}>
              <span className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CONTRACTS.FILTERS.PARTNER' })}
              </span>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <PartnerSelect
                    value={value}
                    onChange={(_, v) => onChange(v)}
                  />
                )}
                name="partner"
                control={control}
              />
            </label>
            <label
              className="font-medium text-gray-500"
              style={{ minWidth: '200px' }}>
              <span className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CONTRACTS.FILTERS.OFFER' })}
              </span>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <OfferSelect value={value} onChange={(_, v) => onChange(v)} />
                )}
                name="offer"
                control={control}
              />
            </label>
            <label
              className="font-medium text-gray-500"
              style={{ minWidth: '200px' }}>
              <span className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CONTRACTS.FILTERS.STATUS' })}
              </span>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <StatusSelect
                    value={value}
                    onChange={(_, v) => onChange(v)}
                  />
                )}
                name="status"
                control={control}
              />
            </label>
            <label
              className="font-medium text-gray-500"
              style={{ minWidth: '200px' }}>
              <span className="font-medium text-gray-500">
                {intl.formatMessage({ id: 'CONTRACTS.FILTERS.CODE' })}
              </span>
              <input
                {...register('code')}
                className="form-control form-control-lg mt-1"
              />
            </label>

            <div className="flex mt-5">
              <button type="submit" className="btn btn-primary fw-bold px-6">
                {intl.formatMessage({ id: 'COMMON.FILTER' })}
              </button>
              <Button
                layout="outline"
                style={{ marginLeft: '5px' }}
                className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                onClick={() => {
                  reset()
                  onChange({})
                }}>
                {intl.formatMessage({ id: 'COMMON.CLEAR_FILTER' })}
              </Button>
            </div>
          </div>
        </form>
      </CardBody>
    </KTCard>
  )
}
