import React, {
  ChangeEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { Button } from '@windmill/react-ui'
import ComparaTuLogo from '../assets/logo_tucloud.png'
import { useIntl } from 'react-intl'

type Props = {
  defaultImage?: string
  onImageSelected: (file: File) => void
}

export default ({ defaultImage, onImageSelected }: Props) => {
  const inputFile = useRef<HTMLInputElement>(null)
  const imageRef = useRef(null)
  const [currentImage, setCurrentImage] = useState<string>()
  const intl = useIntl()

  const onUploadClick = useCallback(() => {
    inputFile.current?.click()
  }, [inputFile])

  const onFileChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      event.stopPropagation()
      event.preventDefault()
      if (!event.target.files?.length) return
      const file = event.target.files[0]
      onImageSelected(event.target.files[0])

      const reader = new FileReader()
      reader.onload = event => setCurrentImage(event.target?.result as string)
      reader.readAsDataURL(file)
    },
    []
  )

  const src = useMemo(
    () => currentImage ?? defaultImage ?? ComparaTuLogo,
    [defaultImage, currentImage, ComparaTuLogo]
  )

  return (
    <>
      <img
        aria-hidden="true"
        className="object-cover max-h-20 light:hidden"
        ref={imageRef}
        src={src}
        alt="Partner"
      />
      <div className="grid gap-2 grid-cols-1 mt-2">
        <div>
          <Button className="h-10 lightblue-btn" onClick={onUploadClick}>
            {intl.formatMessage({ id: 'COMMON.UPLOAD' })}
          </Button>
        </div>
      </div>
      <input
        multiple={false}
        id="file"
        type="file"
        accept="image/png,image/jpeg"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
    </>
  )
}
