import React, { useState, useEffect, createContext } from 'react'

import PageTitle from '../../components/Typography/PageTitle'
import { CardBody } from '@windmill/react-ui'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import { useForm } from 'react-hook-form'
import { removeEmptyFields } from '../../lib/helpers/formatters'
import { KTSVG } from '../../../_metronic/helpers'
import { KTCard } from '../../../_metronic/helpers'
import { PartnerTable } from './Partner/table/PartnerTable'
import { useAuth } from '../../modules/auth/core/Auth'

const resultsPerPage = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT || 10

export const canWritePartnersContext = createContext<boolean>(false)

export default ({ currentPermissions }: any) => {
  const { canWrite, can = () => {} } = currentPermissions
  const canWriteOffer = can('write', 'partners')
  const alert = useAlert()
  const intl = useIntl()
  const { setCanWrite } = useAuth()

  const { handleSubmit, register, reset } = useForm()

  const [filters, setFilters] = useState({})

  function onSetFilters(filters: any) {
    setFilters(removeEmptyFields(filters))
  }

  function onClearFilters() {
    reset()
    setFilters({})
  }

  useEffect(() => {
    document.title = 'Control Room - Partner'
    setCanWrite(canWrite)
  }, [])

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="flex items-center justify-between">
          <PageTitle>{intl.formatMessage({ id: 'PARTNER.TITLE' })}</PageTitle>
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base">
            {canWriteOffer && (
              <Link to="/catalogo/partner/nuovo-partner">
                <button type="button" className="btn btn-primary">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {intl.formatMessage({ id: 'COMMON.NEW' })}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <KTCard className="mb-8">
        <CardBody>
          <p
            className="mb-4 font-semibold text-gray-500 text-lg"
            style={{ fontSize: '1.125rem', fontWeight: '600' }}>
            {intl.formatMessage({ id: 'COMMON.FILTERS' })}
          </p>
          <form onSubmit={handleSubmit(onSetFilters)}>
            <div
              className="grid gap-2 grid-cols-1 items-end"
              style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
              <label>
                <span>{intl.formatMessage({ id: 'PARTNER.PARTNER' })}</span>
                <input
                  className="form-control form-control-lg mt-1"
                  {...register('name')}
                />
              </label>
              <div className="flex mt-5">
                <button type="submit" className="btn btn-primary fw-bold px-6">
                  {intl.formatMessage({ id: 'COMMON.FILTER' })}
                </button>
              </div>
              <div className="flex mt-5">
                <button
                  style={{ marginLeft: '5px' }}
                  onClick={onClearFilters}
                  className="btn btn-light btn-active-light-primary fw-bold me-2 px-6">
                  {intl.formatMessage({ id: 'COMMON.CLEAR_FILTER' })}
                </button>
              </div>
            </div>
          </form>
        </CardBody>
      </KTCard>
      <KTCard className="mb-8">
        <canWritePartnersContext.Provider value={canWriteOffer}>
          <PartnerTable filters={filters} />
        </canWritePartnersContext.Provider>
      </KTCard>
    </>
  )
}
