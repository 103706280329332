// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import moment from 'moment'

export const getAvailableDates = (startDate, endDate = moment()) => {
  const dates = []
  const _endDate = moment(endDate).subtract(1, 'month') // Substract one month to exclude endDate itself
  const month = moment(startDate)
  while (month <= _endDate) {
    dates.push(moment(month))
    month.add(1, 'month')
  }
  return dates
}

export const getAvailableYears = dates => [
  ...new Set(dates.map(d => d.year()).sort((a, b) => b - a))
]

export const getAvailableMonths = (dates, year) => [
  ...new Set(dates.filter(d => d.year() === Number(year)).map(d => d.month()))
]
