import React, { useMemo, useState } from 'react'
import AutoSelect, { AutoSelectProps } from '../AutoSelect'
import { useGetUsersListQuery, ResponseUserDto } from '../../../store/api/users'

export type UserSelectProps = Pick<
  AutoSelectProps<ResponseUserDto>,
  'value' | 'onChange'
>

export default ({ ...props }: UserSelectProps) => {
  const [searchValue, setSearchValue] = useState<string>()

  const args = useMemo(
    () => ({
      ...(searchValue && { search: searchValue }),
      limit: 10
    }),
    [searchValue]
  )

  const { data, isFetching } = useGetUsersListQuery(args)

  return (
    <AutoSelect
      options={data?.items ?? []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onSearchChange={setSearchValue}
      loading={isFetching}
      getOptionLabel={o => `${o.firstName} ${o.lastName}`}
      renderOption={(props, o) => (
        <li {...props} key={o.id} className="p-2 select-none">
          <div>
            {o.firstName} {o.lastName}
          </div>
          <div className="font-mono font-thin text-uppercase text-sm">
            {o.fiscalCode}
          </div>
        </li>
      )}
      {...props}
    />
  )
}
