import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { FormProvider, useForm } from 'react-hook-form'
//import {useIntl} from 'react-intl'
import AgencySettings from '../../components/Impostazioni/AgencySettings'
import BillingSettings from '../../components/Impostazioni/BillingSettings'
import { useAuth } from '../../context/Auth'
//import { useLoading } from '../../context/LoadingContext';
import { list, save, create } from '../../lib/fetch/settings'
import { useIntl } from 'react-intl'

const initialSettings = {
  agencySettings: {
    agencyName: '',
    companyName: '',
    vat: '',
    fiscalCode: '',
    email: ''
  },
  billingSettings: {
    country: '',
    state: '',
    district: '',
    city: '',
    address: '',
    zip: ''
  },
  generalSettings: {
    link: '',
    backofficeEmail: '',
    website: ''
  }
}

function Impostazioni({ currentPermissions }: any) {
  const [{ token }] = useAuth()
  const alert = useAlert()
  //const { showLoading, hideLoading } = useLoading();
  const [settings, setSettings] = useState()
  const formContext = useForm()
  const intl = useIntl()

  async function initializeSettings() {
    //showLoading();
    //@ts-ignore
    const { error, data = {} } = await create({
      token,
      data: {
        name: 'agency',
        value: JSON.stringify(initialSettings)
      }
    })
    //hideLoading();
    if (error) {
      setSettings(undefined)
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    const { setting } = data
    setSettings(setting)
  }

  async function fetchSettings() {
    //showLoading();
    //@ts-ignore
    const { error, data = {} } = await list({
      token
    })

    //hideLoading();
    if (error) {
      setSettings(undefined)
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    const agencySettings = data.items.find(
      (setting: any) => setting.name === 'agency'
    )
    if (agencySettings) {
      setSettings(agencySettings)
      try {
        formContext.reset(JSON.parse(agencySettings.value))
      } catch (error) {
        alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      } finally {
        return
      }
    }
    initializeSettings()
  }

  async function onSubmit(values: any) {
    if (!settings) return
    //showLoading();
    //@ts-ignore
    const { error } = await save({
      // @ts-ignore
      id: settings.id,
      token,
      data: {
        name: 'agency',
        value: JSON.stringify(values)
      }
    })

    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    fetchSettings()
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
  }

  useEffect(() => {
    fetchSettings()
  }, [])

  useEffect(() => {
    document.title = 'Control Room - Impostazioni'
  }, [])

  return (
    <div className="row grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2">
      <FormProvider {...formContext}>
        <div className="col-6">
          <AgencySettings
            currentPermissions={currentPermissions}
            onSubmit={onSubmit}
          />
        </div>
        <div className="col-6">
          <BillingSettings
            currentPermissions={currentPermissions}
            onSubmit={onSubmit}
          />
        </div>
      </FormProvider>
    </div>
  )
}

export default Impostazioni
