import React, { useState } from 'react'
import { ListViewProvider } from './core/ListViewProvider'
import { GestisciAgentiListHeader } from './components/header/GestisciAgentiListHeader'
import { GestisciAgentiTable } from './table/GestisciAgentiTable'
import { KTCard } from '../../../../../_metronic/helpers'
import { Filters, UsersListFilter } from './components/header/UsersListFilter'

const GestisciAgentiList = ({
  currentPermissions
}: {
  currentPermissions: any
}) => {
  const initialFilters: Filters = {
    first_name: '',
    last_name: '',
    username: '',
    email: ''
  }
  const [filters, setFilters] = useState<Filters>(initialFilters)
  const [appliedFilters, setAppliedFilters] = useState<Filters>()
  const resetData = () => {
    setFilters(initialFilters)
    setAppliedFilters(undefined)
  }
  const onFilter = () => {
    setAppliedFilters(filters)
  }
  const onFiltersChange = (filters: Filters) => {
    setFilters(
      //remove empty values from filters
      Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v !== '')
      ) as Filters
    )
  }
  console.log('GestisciAgentiList currentPermissions:', currentPermissions)
  return (
    <>
      <KTCard>
        <GestisciAgentiListHeader currentPermissions={currentPermissions} />
        <UsersListFilter
          filters={filters}
          onFilter={onFilter}
          onFiltersChange={onFiltersChange}
          onResetFilters={resetData}
        />
        <GestisciAgentiTable
          filters={appliedFilters}
          currentPermissions={currentPermissions}
        />
      </KTCard>
    </>
  )
}
const GestisciAgentiListWrapper = ({
  currentPermissions
}: {
  currentPermissions: any
}) => (
  <ListViewProvider>
    <GestisciAgentiList currentPermissions={currentPermissions} />
  </ListViewProvider>
)

export { GestisciAgentiListWrapper }
