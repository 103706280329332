import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../context/Auth'
import { useAuth as useProfile } from '../../../modules/auth'
import { list } from '../../../lib/fetch/accountBalances'
import EstrattiContoPresentational from './EstrattiContoPresentational'
import { useLoading } from '../../../context/LoadingContext'
import { getUserBalances } from '../../../lib/fetch/users'
import { useIntl } from 'react-intl'

const resultsPerPage = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT || 10

function EstrattiConto({ currentPermissions }: any) {
  const [{ token }] = useAuth()
  const alert = useAlert()
  const [filters, setFilters] = useState({})

  const [balances, setBalances] = useState([])
  const [balancesCount, setBalancesCount] = useState(0)
  const [loadingBalances, setLoadingBalances] = useState(false)
  const [balancesPage, setBalancesPage] = useState(1)

  const [subnetBalances, setSubnetBalances] = useState([])
  const [subnetBalancesCount, setSubnetBalancesCount] = useState(0)
  const [loadingSubnet, setLoadingSubnet] = useState(false)
  const [subnetPage, setSubnetPage] = useState(1)
  const profile = useProfile().auth?.user

  const { showLoading, hideLoading } = useLoading()
  const intl = useIntl()

  async function fetchBackofficeBalances() {
    setBalances([])
    //@ts-ignore
    const { error, data = {} } = await list({
      token,
      page: balancesPage,
      limit: resultsPerPage
    })

    if (error) {
      setBalancesCount(0)
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    setBalancesCount((data.meta && data.meta.totalItems) || 0)
    setBalances(data.items)
  }

  async function fetchAgentBalances(id: number, page: number, limit: number) {
    //@ts-ignore
    const { error, data = {} } = await getUserBalances({
      id,
      token,
      page,
      limit:
        limit || (typeof resultsPerPage === 'number' && resultsPerPage) || 10
    })

    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      throw error
    }

    return data
  }

  // async function onDownloadBalance(userId, balanceId) {
  //   showLoading()
  //   const { error, headers, data } = await download({
  //     token,
  //     userId,
  //     balanceId
  //   })

  //   hideLoading()
  //   if (error) {
  //     alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
  //     return
  //   }

  //   try {
  //     const fileName = headers['content-disposition']
  //       .split(';')[1]
  //       .split('=')[1]
  //       .replace('"', '')
  //       .replace('"', '')
  //     const fileType = headers['content-type']
  //     downloadFile(fileName, fileType, data)
  //   } catch (error) {
  //     alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
  //     console.log(error)
  //     return
  //   }
  // }

  // async function onDeleteBalance(balanceId) {
  //   const { error } = await remove({ token, id: balanceId })

  //   if (error) {
  //     alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
  //   }
  //   setTimeout(fetchBackofficeBalances, 250)
  //   alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
  // }

  // function fetch() {
  //   setLoadingBalances(true)
  //   isAdmin
  //     ? fetchBackofficeBalances().finally(() => setLoadingBalances(false))
  //     : fetchAgentBalances(profile?.id, { page: balancesPage })
  //         .then(data => {
  //           setBalancesCount((data.meta && data.meta.totalItems) || 0)
  //           setBalances(data.items)
  //         })
  //         .catch(e => {
  //           setBalancesCount(0)
  //           setBalances([])
  //         })
  //         .finally(() => setLoadingBalances(false))
  // }
  // useEffect(() => {
  //   fetch()
  // }, [balancesPage, filters])

  // useEffect(() => {
  //   if (isAdmin) return
  //   setLoadingSubnet(true)
  //   fetchSubnet()
  //     .then(net =>
  //       Promise.all(
  //         net.map(user =>
  //           fetchAgentBalances(user.id, {
  //             page: subnetPage,
  //             limit: 1
  //           }).then(data => ({
  //             ...data,
  //             items: data.items.map(i => ({ ...i, users: user }))
  //           }))
  //         )
  //       ).catch(e => {
  //         setSubnetBalancesCount(0)
  //         setSubnetBalances([])
  //       })
  //     )
  //     .then(perUserResponse => {
  //       const subnetBalances = perUserResponse.map(r => r.items).flat()
  //       const subnetBalancesCount = perUserResponse.reduce(
  //         (acc, r) => acc + ((r.meta && r.meta.totalItems) || 0),
  //         0
  //       )
  //       setSubnetBalances(subnetBalances)
  //       setSubnetBalancesCount(subnetBalancesCount)
  //     })
  //     .finally(setLoadingSubnet(false))
  // }, [subnetPage])

  useEffect(() => {
    document.title = 'Control Room - Estratti conto'
  }, [])

  return (
    <EstrattiContoPresentational
      isAdmin={profile?.isAdmin}
      currentPermissions={currentPermissions}
    />
  )
}

export default EstrattiConto
