import React, { ComponentProps, useEffect } from 'react'
import PageTitle from '../../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import WorkflowSelect from '../../../../components/selects/WorkflowSelect'

type Props = {
  onSubmit: () => void
}

type Form = {
  name: string
  workflow: number
}

export default ({ onSubmit }: Props) => {
  const intl = useIntl()

  const history = useNavigate()
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext<Form>()

  useEffect(() => {
    register('workflow', {
      required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
    })
  }, [register])

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon as ComponentProps<typeof Button>['icon']}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({ id: 'CREATE_STATUS.TITLE' })}
        </PageTitle>
      </div>
      <div className="card mb-5 mb-xl-10">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="card-body pt-3">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.NAME' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="form-control form-control-solid form-control-lg"
                  type="text"
                  {...register('name', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                    maxLength: {
                      value: 100,
                      //t('COMMON:MAX_LENGTH', { length: 100 })
                      message: intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })
                    }
                  })}
                />
                {errors.name && errors.name.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.name.message}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'CREATE_STATUS.WORKFLOW' })}
              </label>

              <div className="col-lg-8 fv-row">
                <WorkflowSelect
                  onChange={(_, value) => {
                    if (!value) {
                      setError('workflow', {
                        type: 'required',
                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                      })
                      return
                    }
                    setValue('workflow', value.id)
                  }}
                />
                {errors.workflow && errors.workflow.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.workflow.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              {intl.formatMessage({ id: 'COMMON.SAVE' })}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
