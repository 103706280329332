import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from '../index'
import { PropsWithChildren } from 'react'
import AxiosStoreProvider from './AxiosStoreProvider'

export default ({ children }: PropsWithChildren) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AxiosStoreProvider />
      {children}
    </PersistGate>
  </Provider>
)
