import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  useCreateWorkflowMutation,
  CreateWorkflowApiArg,
  useUpdateWorkflowMutation,
  ResponseWorkflowDto
} from '../../../../store/api/workflows'
import { useAlert } from 'react-alert'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

type Props = {
  workflow?: ResponseWorkflowDto
}

export default function WorkflowForm({ workflow }: Props) {
  const history = useNavigate()
  const alert = useAlert()
  const intl = useIntl()
  const [
    createWorkflow,
    {
      data: createdWorkflow,
      isLoading: isLoadingCreatingWorkflow,
      error: errorCreatingWorkflow,
      isSuccess: isSuccessCreatingWorkflow
    }
  ] = useCreateWorkflowMutation()
  const [
    updateWorkflow,
    {
      data: updatedWorkflow,
      isLoading: isLoadingUpdatingWorkflow,
      error: errorUpdatingWorkflow,
      isSuccess: isSuccessUpdatingWorkflow
    }
  ] = useUpdateWorkflowMutation()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CreateWorkflowApiArg>({
    defaultValues: {
      name: workflow?.name || '',
      isValidForStatistics: workflow?.isValidForStatistics || false,
      isValidForTarget: workflow?.isValidForTarget || false,
      isValidForRanking: workflow?.isValidForRanking || false
    }
  })

  const onSubmit = (data: CreateWorkflowApiArg) => {
    if (workflow) {
      updateWorkflow({ id: workflow.id, createWorkflowDto: data })
      return
    }
    createWorkflow(data)
  }

  useEffect(() => {
    if (isSuccessCreatingWorkflow || isSuccessUpdatingWorkflow) {
      alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
      history(-1)
    }
  }, [isSuccessCreatingWorkflow, isSuccessUpdatingWorkflow])
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      <div className="card-body pt-3">
        <div className="row mb-6">
          <label className="col-lg-4 col-form-label required fw-bold fs-6">
            Nome
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="text"
              placeholder="Nome"
              className="form-control form-control-solid form-control-lg"
              {...register('name', { required: true })}
            />
            {errors.name && (
              <span className="text-danger">Il nome è obbligatorio</span>
            )}
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label required fw-bold fs-6">
            Incluso in statistiche
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="checkbox"
              className="form-check-input"
              {...register('isValidForStatistics')}
            />
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label required fw-bold fs-6">
            Incluso nel target
          </label>
          <div className="col-lg-8 fv-row">
            <input
              type="checkbox"
              className="form-check-input"
              {...register('isValidForTarget')}
            />
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label required fw-bold fs-6">
            Incluso nel ranking
          </label>
          <div className="col-lg-8 fv-row">
            <input
              type="checkbox"
              className="form-check-input"
              {...register('isValidForRanking')}
            />
          </div>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <button type="submit" className="btn btn-primary">
          Salva
        </button>
      </div>
    </form>
  )
}
