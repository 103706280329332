import axios from 'axios'
import { AuthenticatedQuery, PSAQuery } from '../../interfaces/PaginatedQuery'

export interface ListOffersParams {
  partner?: string
  name?: string
  description?: string
  business?: boolean
  sale_channel_id?: string
}

export const list = async ({
  token,
  ...params
}: PSAQuery<ListOffersParams>) => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/offers`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: params
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

interface GetOfferParams {
  id: number
}

export const get = async ({
  id,
  token
}: AuthenticatedQuery<GetOfferParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/offers/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreateOfferParams {
  data: any
}

export const create = async ({
  data,
  token
}: AuthenticatedQuery<CreateOfferParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/offers`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface SaveOfferParams {
  id: number
  data: any
}

export const save = async ({
  id,
  data,
  token
}: AuthenticatedQuery<SaveOfferParams>) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/offers/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface AttachOfferParams {
  data: any
}

export const attach = async ({
  data,
  token
}: AuthenticatedQuery<AttachOfferParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/offers/salesChannels/attach`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface DetachOfferParams {
  data: any
}

export const detach = async ({
  data,
  token
}: AuthenticatedQuery<DetachOfferParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/offers/salesChannels/detach`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
