import axios from 'axios'
import { PaginatedResponse } from '../../interfaces/PaginatedResponse'
import { AuthenticatedQuery, PSAQuery } from '../../interfaces/PaginatedQuery'
import Contract from '../../interfaces/Contract'
import QueryResult from '../../interfaces/QueryResult'

export interface ListContractsFilters {
  csv?: boolean
  xls?: boolean
  user?: string
  status?: string
  salesChannels?: string
  customer?: number
  customer_first_name?: string
  customer_last_name?: string
  customer_fiscal_code?: string
  customer_phone?: string
  customer_company?: string
  contract_date_from?: string
  contract_date_to?: string
  code?: string
  offer_name?: string
  offer_partner_id?: string
}

export const list = async ({
  token,
  xls,
  ...queryString
}: PSAQuery<ListContractsFilters>): Promise<PaginatedResponse<Contract>> => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/contracts`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: queryString,
    ...(xls && { responseType: 'blob' })
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

interface GetContractsParams {
  id: string
}

export const get = async ({
  id,
  token
}: AuthenticatedQuery<GetContractsParams>): Promise<QueryResult<Contract>> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/contracts/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreateContractParams {
  data: Contract
}

export const create = async ({
  data,
  token
}: AuthenticatedQuery<CreateContractParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/contracts`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface SaveContractParams {
  id: string
  data: Contract
}

export const save = async ({
  id,
  data,
  token
}: AuthenticatedQuery<SaveContractParams>) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/contracts/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface AddNoteParams {
  data: any
}

export const addNote = async ({
  data = {},
  token
}: AuthenticatedQuery<AddNoteParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/notes`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface DeleteNoteParams {
  id: string
}

export const deleteNote = async ({
  id,
  token
}: AuthenticatedQuery<DeleteNoteParams>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/notes/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface GetNotesParams {
  contract_id: string
}

export const getNotes = async (params: AuthenticatedQuery<GetNotesParams>) => {
  const { token, contract_id } = params
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/notes`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        contract_id
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface ListJobsParams {
  status?: string
  csv?: boolean
}

export const listJobs = async (params: PSAQuery<ListJobsParams>) => {
  const { token, page, limit, ordering, sort, csv, status } = params

  const queryString = {
    page,
    limit,
    ordering,
    sort,
    csv,
    status
  }

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/contracts/imported/jobs`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: queryString
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface DownloadJobParams {
  id: string
}

export const downloadJob = async ({
  token,
  id
}: AuthenticatedQuery<DownloadJobParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/contracts/imported/download/${id}`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface DownloadAttachmentParams {
  id: string
}

export const downloadAttachment = async ({
  token,
  id
}: AuthenticatedQuery<DownloadAttachmentParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/contracts/download/attachment/${id}`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface RemoveContractParams {
  id: number
}

export const remove = async ({
  id,
  token
}: AuthenticatedQuery<RemoveContractParams>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/contracts/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface UploadContractAttachmentParams {
  contractId: number
  file: any
}

export const uploadContractAttachment = async ({
  contractId,
  file,
  token
}: AuthenticatedQuery<UploadContractAttachmentParams>) => {
  try {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/contracts/${contractId}/attachments`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      },
      data: formData
    })

    if (response instanceof Error) {
      return { error: response, file }
    }

    return { data: response.data, file }
  } catch (error) {
    // Sentry TODO
    return { error, file }
  }
}

interface DeleteContractAttachementParams {
  contractId: number
  attachementid: number
}

export const deleteContractAttachement = async ({
  contractId,
  attachementid,
  token
}: AuthenticatedQuery<DeleteContractAttachementParams>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/contracts/${contractId}/attachments/${attachementid}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return { data: response.data }
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
