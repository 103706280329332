import React from 'react'
import { useListView } from '../../core/ListViewProvider'
import { UsersListToolbar } from './UserListToolbar'
import { UsersListGrouping } from './UsersListGrouping'
import { UsersFilterProps } from './UsersListFilter'
import PageTitle from '../../../../../components/Typography/PageTitle'
import { useIntl } from 'react-intl'

const UsersListHeader = ({
  onFilterApplied,
  onFilterReset
}: UsersFilterProps) => {
  const { selected } = useListView()
  const intl = useIntl()

  return (
    <div className="card-header border-0 pt-6">
      <div className="card-title">
        <PageTitle>
          {intl.formatMessage({ id: 'GESTISCI_CLIENTI.TITLE' })}
        </PageTitle>
      </div>
      <div className="card-toolbar">
        {selected.length > 0 ? (
          <UsersListGrouping />
        ) : (
          <UsersListToolbar
            onFilterApplied={onFilterApplied}
            onFilterReset={onFilterReset}
          />
        )}
      </div>
    </div>
  )
}

export { UsersListHeader }
