// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import axios from 'axios'

export const uploadPartnerImage = async ({ partnerId, file, token } = {}) => {
  try {
    const formData = new FormData()
    formData.append('id_partner', partnerId)
    formData.append('file', file)

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/partners/uploads/image`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      },
      data: formData
    })

    if (response instanceof Error) {
      return { error: response, file }
    }

    return { data: response.data, file }
  } catch (error) {
    // Sentry TODO
    return { error, file }
  }
}

export const uploadChannelImage = async ({ channelId, file, token } = {}) => {
  try {
    const formData = new FormData()
    formData.append('id_sale_channel', channelId)
    formData.append('file', file)

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/salesChannels/uploads/image`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      },
      data: formData
    })

    if (response instanceof Error) {
      return { error: response, file }
    }

    return { data: response.data, file }
  } catch (error) {
    // Sentry TODO
    return { error, file }
  }
}

export const uploadOfferImage = async ({ offerId, file, token } = {}) => {
  try {
    const formData = new FormData()
    formData.append('id_offer', offerId)
    formData.append('file', file)

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/offers/uploads/image`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      },
      data: formData
    })

    if (response instanceof Error) {
      return { error: response, file }
    }

    return { data: response.data, file }
  } catch (error) {
    // Sentry TODO
    return { error, file }
  }
}

export const uploadUserDocument = async ({
  userId,
  file,
  type,
  token
} = {}) => {
  try {
    const formData = new FormData()
    formData.append('id_user', userId)
    formData.append('file', file)
    formData.append('type', type)

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/users/uploads/document`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      },
      data: formData
    })

    if (response instanceof Error) {
      return { error: response, file }
    }

    return { data: response.data, file }
  } catch (error) {
    // Sentry TODO
    return { error, file }
  }
}

export const uploadContractJob = async ({ file, token }) => {
  try {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/contracts/uploads/xls`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      },
      data: formData
    })

    if (response instanceof Error) {
      return { error: response, file }
    }

    return { data: response.data, file }
  } catch (error) {
    // Sentry TODO
    return { error, file }
  }
}

export const uploadMeDocument = async ({ userId, file, type, token } = {}) => {
  try {
    const formData = new FormData()
    formData.append('id_user', userId)
    formData.append('file', file)
    formData.append('type', type)

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/me/uploads/document`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      },
      data: formData
    })

    if (response instanceof Error) {
      return { error: response, file }
    }

    return { data: response.data, file }
  } catch (error) {
    // Sentry TODO
    return { error, file }
  }
}
