import React from 'react'
import { OfferteArticoliActionsCell } from './OfferteArticoliActionsCell'
import ComparaTuLogo from '../../../../../assets/logo_tucloud.png'
import Offer from '../../../../../interfaces/Offer'
import { ColumnDef } from '@tanstack/react-table'
import TableThumbnail from '../../../../../components/TableThumbnail'

const offerteArticoliColumns: ColumnDef<Offer>[] = [
  {
    header: 'Logo',
    accessorKey: 'image',
    cell: ({ row }) => (
      <TableThumbnail
        src={row.original.image ?? ComparaTuLogo}
        alt={row.original.name}
      />
    )
  },
  {
    header: 'Partner',
    accessorKey: 'partner.name'
  },
  {
    header: 'Canale',
    accessorKey: 'salesChannel.code'
  },
  {
    header: 'Nome',
    accessorKey: 'name'
  },
  {
    header: 'Valore',
    accessorKey: 'value',
    cell: ({ getValue }) => {
      return !getValue() ? '0,00 €' : `${getValue() as string},00 €`
    }
  },
  {
    header: 'Business',
    accessorKey: 'business',
    cell: ({ getValue }) => {
      return (getValue() as boolean) ? 'Si' : 'No'
    }
  },
  {
    header: 'Attivo',
    accessorKey: 'status',
    cell: ({ getValue }) => {
      return (getValue() as boolean) ? 'Attivo' : 'Inattivo'
    }
  },
  {
    header: 'Azioni',
    id: 'actions',
    cell: ({ row }) => <OfferteArticoliActionsCell offer={row.original} />
  }
]

export { offerteArticoliColumns }
