import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '../../../components/Typography/PageTitle'
import { useIntl } from 'react-intl'
import { KTCard } from '../../../../_metronic/helpers'
import { SediTable } from './table/SediTable'
import { KTSVG } from '../../../../_metronic/helpers'
import { useAuth } from '../../../modules/auth/core/Auth'

function SediPresentational({ currentPermissions, filters }: any) {
  const intl = useIntl()
  const { setCanWrite } = useAuth()
  const canWrite = currentPermissions.can('write', 'nets')

  useEffect(() => {
    setCanWrite(canWrite)
  }, [])

  return (
    <>
      <div className="flex items-center justify-between">
        <PageTitle>
          {intl.formatMessage({ id: 'LIST_AGENCIES.TITLE' })}
        </PageTitle>
        <div
          className="d-flex justify-content-end"
          data-kt-user-table-toolbar="base">
          {canWrite && (
            <Link to="/rete/nuova-sede">
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginBottom: '10%' }}>
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {intl.formatMessage({ id: 'LIST_AGENCIES.NEW_AGENCY' })}
              </button>
            </Link>
          )}
        </div>
      </div>
      <KTCard className="mb-8">
        <SediTable />
      </KTCard>
    </>
  )
}

export default SediPresentational
