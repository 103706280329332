import React, { Fragment, useEffect } from 'react'

import PageTitle from '../../components/Typography/PageTitle'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { useAuth } from '../../context/Auth'
import {
  getAgencyUsers as getAgencyUsersQuery,
  list
} from '../../lib/fetch/agencies'
import { useAlert } from 'react-alert'
import { Agency } from '../../interfaces/Agecies'

function NavigaStruttura() {
  const history = useNavigate()
  const intl = useIntl()
  const [{ token }] = useAuth()
  const [openAgency, setOpenAgency] = React.useState<number | undefined>()
  const [data, setData] = React.useState(new Map())
  const [agencies, setAgencies] = React.useState<Agency[]>([])
  const alert = useAlert()

  useEffect(() => {
    document.title = 'Control Room - Struttura agenti'
  }, [])

  function onSelectUser(id: number) {
    history(`/agenzia/gestisci-agenti/modifica-utente/${id}`)
  }

  function onAdd(id: number) {
    history(`/agenzia/gestisci-agenti/nuovo-utente?sponsorId=${id}`)
  }

  const fetchAgencies = async () => {
    let result
    try {
      result = await list({ token })
    } catch (error) {
      setAgencies([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    }
    if (!result) return
    setAgencies(result.items)
  }

  const fetchAgencyUsers = async (agencyId: number) => {
    // @ts-ignore
    const { error, data } = await getAgencyUsersQuery({
      token: token,
      id: agencyId
    })
    if (error) {
      return
    }
    return data
  }

  const getAgencyUsers = async (id: number) => {
    if (data.has(id)) {
      return data.get(id)
    }
    const users = await fetchAgencyUsers(id)
    if (users) {
      const userData = users.items
      const newData = new Map(data.set(id, userData))
      setData(newData)
    }
  }

  useEffect(() => {
    fetchAgencies()
  }, [])

  console.log(data)

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'USERS_TREE.TITLE' })}</PageTitle>
      <KTCard className="mb-8">
        <KTCardBody>
          <div className="flex flex-col gap-2">
            {agencies &&
              agencies.map(agency => (
                <div key={agency.id}>
                  <div
                    className="mb-1"
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      onClick={() => {
                        openAgency === agency.id
                          ? setOpenAgency(undefined)
                          : setOpenAgency(agency.id)
                        getAgencyUsers(agency.id)
                      }}
                      className={
                        'transition duration-200 ease-in-out w-4 h-4 mr-5 flex items-center justify-center' +
                        ' ' +
                        (openAgency === agency.id
                          ? 'transform rotate-90'
                          : 'transform rotate-0')
                      }
                      style={{ transformOrigin: 'center' }}>
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr071.svg"
                        className="svg-icon-1"
                      />
                    </span>
                    <span className="inline-flex items-center cursor-pointer">
                      {agency.name}
                    </span>
                  </div>
                  {openAgency === agency.id && data.has(agency.id) && (
                    <div className="ml-5">
                      {data.get(agency.id)?.map((user: any) => (
                        <div
                          key={user.id}
                          className="mb-1 d-flex align-items-center">
                          <span className="inline-flex items-center">
                            <KTSVG
                              path="/media/icons/duotune/communication/com006.svg"
                              className="svg-icon-1"
                            />
                            <span
                              className="cursor-pointer ml-1"
                              onClick={() => {
                                onSelectUser(user.id)
                              }}>
                              {(user.first_name || user.firstName) ?? ''}{' '}
                              {(user.last_name || user.lastName) ?? ''}
                            </span>
                          </span>
                          <button
                            type="button"
                            className="text-3xl ml-5 p-1 btn-primary"
                            onClick={() => onAdd(user.id)}>
                            +
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default NavigaStruttura
