// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import PageTitle from '../../components/Typography/PageTitle'
import { CardBody } from '@windmill/react-ui'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { KTCard } from '../../../_metronic/helpers'

function AgencySettings({ currentPermissions, onSubmit }: any) {
  const canWrite = currentPermissions.can('write', 'settings')
  const intl = useIntl()
  const { handleSubmit, register } = useFormContext()

  return (
    <div>
      <PageTitle>
        {intl.formatMessage({ id: 'SETTINGS.AGENCY_SETTINGS' })}
      </PageTitle>
      <KTCard className="mb-2">
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={!canWrite}>
              <div className="grid gap-2 grid-cols-1 md:grid-cols-1 xl:grid-cols-2">
                <label>
                  <span>{intl.formatMessage({ id: 'SETTINGS.AGENCY' })}</span>
                  <input
                    className="form-control form-control-lg mt-1"
                    {...register('agencySettings.agencyName')}
                  />
                </label>
              </div>
              <div className="grid gap-2 grid-cols-1 md:grid-cols-1 xl:grid-cols-2">
                <label>
                  <span>
                    {intl.formatMessage({ id: 'SETTINGS.COMPANY_NAME' })}
                  </span>
                  <input
                    className="form-control form-control-lg mt-1"
                    {...register('agencySettings.companyName')}
                  />
                </label>
              </div>
              <div className="grid gap-2 grid-cols-1 md:grid-cols-1 xl:grid-cols-2">
                <label>
                  <span>{intl.formatMessage({ id: 'SETTINGS.VAT' })}</span>
                  <input
                    className="form-control form-control-lg mt-1"
                    {...register('agencySettings.vat')}
                  />
                </label>
              </div>
              <div className="grid gap-2 grid-cols-1 md:grid-cols-1 xl:grid-cols-2">
                <label>
                  <span>
                    {intl.formatMessage({ id: 'SETTINGS.FISCAL_CODE' })}
                  </span>
                  <input
                    className="form-control form-control-lg mt-1"
                    {...register('agencySettings.fiscalCode')}
                  />
                </label>
              </div>
              <div className="grid gap-2 grid-cols-1 md:grid-cols-1 xl:grid-cols-2">
                <label>
                  <span>{intl.formatMessage({ id: 'COMMON.EMAIL' })}</span>
                  <input
                    className="form-control form-control-lg mt-1"
                    {...register('agencySettings.email')}
                  />
                </label>
              </div>
            </fieldset>
            {canWrite && (
              <div className="grid gap-2 grid-cols-1 md:grid-cols-1 xl:grid-cols-2">
                <div className="col-span-1 xl:col-span-2 mt-5">
                  <button type="submit" className="btn btn-primary">
                    {intl.formatMessage({ id: 'COMMON.SAVE' })}
                  </button>
                </div>
              </div>
            )}
          </form>
        </CardBody>
      </KTCard>
    </div>
  )
}

export default AgencySettings
