import React from 'react'
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useAuth as useAuthoriz } from '../../../../../context/Auth'
import { remove } from '../../../../../lib/fetch/statuses'
import { useAuth } from '../../../../../modules/auth'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import Status from '../../../../../interfaces/Status'

type Props = {
  status: Status
  onDelete: () => void
}

const EsitiLavorazioneActionsCell: FC<Props> = ({ status, onDelete }) => {
  const [auth = {}] = useAuthoriz()
  const { token }: any = auth
  const { canWrite } = useAuth()
  const intl = useIntl()
  const alert = useAlert()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  async function onDeleteStatus(id: number) {
    const response = await remove({ token, id })

    if (!response) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    onDelete()
  }

  return (
    <>
      {status.name !== 'Prenotato [AGT]' && canWrite && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <button
            type="button"
            className="btn btn-light-primary me-3"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-users-table-filter="delete_row"
            data-bs-toggle="modal"
            data-bs-target={'#kt_modal_2' + status.id}>
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-2"
            />
            Elimina
          </button>
        </div>
      )}
      <div className="modal fade" tabIndex={-1} id={'kt_modal_2' + status.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Elimina esito</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>Sei sicuro di voler eliminare l'esito {status.name}?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  status.id && onDeleteStatus(status.id)
                }}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { EsitiLavorazioneActionsCell }
