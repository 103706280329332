import PageTitle from '../../../../components/Typography/PageTitle'

import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { ComponentProps } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { useIntl } from 'react-intl'
import WorkflowForm from '../WorkflowForm'

export default function NewWorkflowPage() {
  const history = useNavigate()
  const alert = useAlert()
  const intl = useIntl()

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon as ComponentProps<typeof Button>['icon']}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>Nuovo Workflow</PageTitle>
      </div>
      <div className="card mb-5 mb-xl-10">
        <WorkflowForm />
      </div>
    </>
  )
}
