import React from 'react'
import { PartnerActionsCell } from './PartnerActionsCell'
import Moment from 'moment'
import ComparaTuLogo from '../../../../../assets/logo_tucloud.png'
import Partner from '../../../../../interfaces/Partner'
import { ColumnDef } from '@tanstack/react-table'
import TableThumbnail from '../../../../../components/TableThumbnail'

const partnerColumns: ColumnDef<Partner>[] = [
  {
    header: 'Logo',
    accessorKey: 'image',
    cell: ({ row }) => (
      <TableThumbnail
        src={row.original.image ?? ComparaTuLogo}
        alt={row.original.name}
      />
    )
  },
  {
    header: 'Partner',
    accessorKey: 'name'
  },
  {
    header: 'Data di creazione',
    accessorKey: 'created_at',
    cell: ({ getValue }) => Moment(getValue() as string).format('DD-MM-YYYY')
  },
  {
    header: 'Attivo',
    accessorKey: 'status',
    cell: ({ getValue }) => {
      return (getValue() as boolean) ? 'Si' : 'No'
    }
  },
  {
    header: 'Azioni',
    id: 'actions',
    cell: ({ row }) => <PartnerActionsCell user={row.original} />
  }
]

export { partnerColumns }
