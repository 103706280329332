// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import RoleFormLabels from '../../../components/Roles/RoleFormLabels'

function NuovoRuoloPresentational({ currentPermissions, onSubmit, roles }) {
  const canWrite = currentPermissions.can('write', 'roles')
  const intl = useIntl()
  const history = useNavigate()

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({ id: 'CREATE_ROLE_AGENCY.TITLE' })}
        </PageTitle>
      </div>
      <RoleFormLabels canWrite={canWrite} onSubmit={onSubmit} roles={roles} />
    </>
  )
}

export default NuovoRuoloPresentational
