import React from 'react'

function PageTitle({ children }: any) {
  return (
    <h1 className="my-6 text-2xl font-semibold text-gray-700 light:text-gray-200">
      {children}
    </h1>
  )
}

export default PageTitle
