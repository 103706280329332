// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useRef, useState } from 'react'
import { Card } from '@windmill/react-ui'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'
import { DocumentSolidIcon } from '../../icons'

function DropFileUpload({ onUpload }) {
  const [file, setFile] = useState()
  const intl = useIntl()
  const inputRef = useRef(null)

  const onDrop = (files = []) => {
    const [file] = files
    setFile(file)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept:
      'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })

  return (
    <div
      className="mb-4 h-64 flex bg-white flex-col items-center border-gray-200 border-2 rounded border-dashed"
      {...(!file &&
        getRootProps({
          onClick: e => e.stopPropagation()
        }))}>
      <div className="my-auto flex flex-col items-center pt-2 pb-2">
        {!file ? (
          <>
            <input {...getInputProps({ ref: inputRef })} />
            {isDragActive ? (
              <p className="font-semibold text-gray-500">
                {intl.formatMessage({ id: 'ELABORAZIONE_DATI.DROP_HINT' })}
              </p>
            ) : (
              <div className="text-center">
                <p className="font-semibold text-gray-500">
                  {intl.formatMessage({ id: 'ELABORAZIONE_DATI.UPLOAD_HINT1' })}
                </p>
                <p className="font-medium text-sm text-gray-500">
                  {intl.formatMessage({ id: 'ELABORAZIONE_DATI.UPLOAD_HINT2' })}
                </p>
                <button
                  onClick={() => inputRef.current.click()}
                  className="btn btn-primary">
                  {intl.formatMessage({ id: 'ELABORAZIONE_DATI.UPLOAD' })}
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center cursor-pointer p-2">
            <Card className="bg-gray-50 flex content-center justify-center items-center w-16 h-16">
              <DocumentSolidIcon className="w-6 h-6" />
            </Card>
            <p className="mt-2 font-semibold text-gray-500">
              {file.name || file}
            </p>
            <p className="font-medium text-sm text-gray-500">
              {intl.formatMessage({ id: 'ELABORAZIONE_DATI.FILE_UPLOADED' })}
            </p>
            <button
              onClick={() => {
                onUpload(file)
                setFile()
              }}
              className="btn btn-primary">
              {intl.formatMessage({ id: 'ELABORAZIONE_DATI.NEW_JOB' })}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default DropFileUpload
