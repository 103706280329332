import { KTCardBody } from '../../../_metronic/helpers'
import {
  TableOptions,
  flexRender,
  useReactTable,
  getCoreRowModel as _getCoreRowModel
} from '@tanstack/react-table'
import Pagination from './Pagination'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import {
  PaginatedResponse,
  PaginationMeta,
  defaultPaginationMeta
} from '../../interfaces/PaginatedResponse'
import { useAlert } from 'react-alert'
import { useIntl } from 'react-intl'
import { LinearProgress } from '@mui/material'

export type GenericTableHandle = {
  refetch: () => void
}

export type GenericTableProps<TData extends object> = {
  onFetch: (
    paginationMeta: PaginationMeta
  ) => Promise<PaginatedResponse<TData> | string | TData[]>
} & Omit<TableOptions<TData>, 'getCoreRowModel' | 'data'> &
  Partial<Pick<TableOptions<TData>, 'getCoreRowModel'>>

export default forwardRef(
  <TData extends object>(
    {
      getCoreRowModel = _getCoreRowModel<TData>(),
      onFetch,
      ...props
    }: GenericTableProps<TData>,
    ref: React.ForwardedRef<GenericTableHandle>
  ) => {
    const [isLoading, setIsLoading] = useState(false)
    const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>()
    const [data, setData] = useState<TData[]>([])
    const alert = useAlert()
    const intl = useIntl()
    const { getRowModel, getHeaderGroups } = useReactTable({
      getCoreRowModel,
      data,
      ...props
    })

    const fetch = useCallback(
      async (paginationMeta: PaginationMeta) => {
        setIsLoading(true)
        const response = await onFetch(paginationMeta)
        if (typeof response === 'string') {
          alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
          console.error(response)
          setIsLoading(false)
          return
        }
        if (!response) {
          alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
          setIsLoading(false)
          return
        }
        if (Array.isArray(response)) {
          setData(response)
          setIsLoading(false)
          return
        }
        setData(response.items)
        setPaginationMeta(response.meta as PaginationMeta)
        setIsLoading(false)
      },
      [onFetch, alert, intl, setIsLoading, setPaginationMeta, setData]
    )

    const refetch = useCallback(() => {
      fetch(paginationMeta ?? defaultPaginationMeta)
    }, [fetch, paginationMeta])

    useImperativeHandle(
      ref,
      () => {
        return { refetch }
      },
      [fetch, paginationMeta]
    )

    const onPaginationChange = (paginationMeta: PaginationMeta) => {
      fetch(paginationMeta)
    }

    useEffect(() => {
      fetch(paginationMeta ?? defaultPaginationMeta)
    }, [onFetch])

    return (
      <KTCardBody className="py-4">
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              {getHeaderGroups().map(({ id, headers }) => (
                <tr
                  key={id}
                  className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  {headers.map(header => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}

              {isLoading && (
                <tr className="position-relative overflow-visible border-0">
                  <th
                    scope="col"
                    colSpan={1000}
                    style={{ width: '100%' }}
                    className="p-0 position-absolute">
                    <LinearProgress />
                  </th>
                </tr>
              )}
            </thead>
            <tbody className="text-gray-600 fw-bold">
              {getRowModel().rows.length === 0 ? (
                !isLoading ? (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        No matching records found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <div style={{ height: 20 }} />
                )
              ) : (
                getRowModel().rows.map(row => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>
        </div>
        {paginationMeta && (
          <Pagination
            paginationMeta={paginationMeta}
            onPaginationChange={onPaginationChange}
          />
        )}
      </KTCardBody>
    )
  }
) as <TData extends object>(
  props: GenericTableProps<TData> & { ref?: React.Ref<GenericTableHandle> }
) => JSX.Element
