import React from 'react'
import { FC, useEffect, useContext } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { canWriteContext } from '../../../CanaliCategorie'
import {
  ResponseSalesChannelDto,
  useDeleteSalesChannelMutation
} from '../../../../../store/api/salesChannels'
import { useAlert } from 'react-alert'

type Props = {
  channel: ResponseSalesChannelDto
  onDelete: () => void
}

const CanaliActionsCell: FC<Props> = ({ channel, onDelete }) => {
  const canWrite = useContext(canWriteContext)
  const alert = useAlert()
  const [deleteChannel, { isSuccess }] = useDeleteSalesChannelMutation()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleDeleteChannel = (id: number) => {
    deleteChannel(id)
  }

  useEffect(() => {
    if (isSuccess) {
      alert.success('Canale eliminato con successo')
      onDelete()
    }
  }, [isSuccess])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {canWrite && (
          <>
            <Link to={`/agenzia/canali-vendita/modifica-canale/${channel.id}`}>
              <button
                type="button"
                className="btn btn-light-primary me-3"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end">
                <KTSVG
                  path="/media/icons/duotune/general/gen055.svg"
                  className="svg-icon-2"
                />
                Modifica
              </button>
            </Link>
            <button
              type="button"
              className="btn btn-light-danger"
              data-bs-toggle="modal"
              data-bs-target={'#kt_modal_2' + channel.id}>
              <KTSVG
                path="/media/icons/duotune/general/gen027.svg"
                className="svg-icon-2"
              />
              Elimina
            </button>
          </>
        )}
      </div>
      <div className="modal fade" tabIndex={-1} id={'kt_modal_2' + channel.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Elimina agente</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>Sei sicuro di voler eliminare il canale {channel.code}?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  channel.id && handleDeleteChannel(channel.id)
                }}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { CanaliActionsCell }
