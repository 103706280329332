import React from 'react'
import { QualificheActionsCell } from './QualificheActionsCell'
import { Role } from '../../core/_models'
import { ColumnDef } from '@tanstack/react-table'

const qualificheColumns = (
  refetch: () => void = () => {}
): ColumnDef<Role>[] => [
  {
    header: 'Qualifica',
    accessorKey: 'name'
  },
  {
    header: 'Descrizione',
    accessorKey: 'description'
  },
  {
    header: 'Azioni',
    id: 'actions',
    cell: ({ row }) => (
      <QualificheActionsCell role={row.original} onDelete={refetch} />
    )
  }
]

export { qualificheColumns }
