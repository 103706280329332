import React from 'react'
import PageTitle from '../../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { NewUserForm } from '../../../../components/GestioneUtenze/NewUserForm'

function ModificaAdminPresentational({
  policies,
  currentPermissions,
  onSubmit,
  user,
  onSavePolicies
}: any) {
  const { can = () => {} } = currentPermissions
  const canReadPolicies = can('read', 'policies')
  const canWritePolicies = can('write', 'policies')
  const canWrite = can('write', 'admins')
  const history = useNavigate()
  const intl = useIntl()

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({
            id: `EDIT_ADMIN.${canWrite ? 'TITLE' : 'TITLE_READONLY'}`
          })}
        </PageTitle>
      </div>
      {user && (
        <NewUserForm
          user={user}
          onUpdate={onSubmit}
          type="update"
          loading={false}
          isBackOffice={true}
        />
      )}
      {/* {canReadPolicies && user && (
        <PoliciesForm
          canWrite={canWritePolicies}
          role={user.role}
          onSave={onSavePolicies}
        />
      )} */}
    </>
  )
}

export default ModificaAdminPresentational
