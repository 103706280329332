import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard } from '../../../../../_metronic/helpers'
import { ResponseSalesChannelDto } from '../../../../store/api/salesChannels'
import { ResponsePartnerDto } from '../../../../store/api/partners'
import SalesChannelSelect from '../../../../components/selects/SalesChannelSelect'
import PartnerSelect from '../../../../components/selects/PartnerSelect'
import OfferSelect from '../../../../components/selects/OfferSelect'
import { Controller, UseFormReturn } from 'react-hook-form'
import { NewContractFormStaus } from '../../NewContract'

export type OfferSectionProps = {
  form: UseFormReturn<NewContractFormStaus>
  readonly?: boolean
}

export default ({
  form: { control, resetField, watch },
  readonly
}: OfferSectionProps) => {
  const intl = useIntl()

  const offer = watch('offer')

  const [salesChannel, setSalesChannel] =
    useState<ResponseSalesChannelDto | null>(null)
  const [partner, setPartner] = useState<ResponsePartnerDto | null>(null)

  useEffect(() => {
    if (!partner) return
    if (!offer) return
    if (offer.partner.id === partner.id) return
    resetField('offer')
  }, [partner])

  useEffect(() => {
    if (!salesChannel) return
    if (!offer) return
    if (offer.salesChannel.id === salesChannel.id) return
    resetField('offer')
    setPartner(null)
  }, [salesChannel])

  useEffect(() => {
    if (!offer) return
    if (offer.salesChannel.id === salesChannel?.id) return
    setSalesChannel(offer.salesChannel)
  }, [offer])
  useEffect(() => {
    if (!offer) return
    if (offer.partner.id === partner?.id) return
    setPartner(offer.partner)
  }, [offer])

  return (
    <KTCard className="p-4">
      <div className="flex items-center justify-between mb-5">
        <p className="font-semibold text-gray-500 text-lg">
          {intl.formatMessage({ id: 'CONTRACT.OFFER.TITLE' })}
        </p>
      </div>

      {readonly ? (
        <>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <p className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.OFFER.SALES_CHANNEL' })}
            </p>
            <p>{offer?.salesChannel.code}</p>
          </div>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <p className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.OFFER.PARTNER' })}
            </p>
            <p>{offer?.partner.name}</p>
          </div>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <p className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.OFFER.OFFER' })}
            </p>
            <p>{offer?.name}</p>
          </div>
        </>
      ) : (
        <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
          <label
            className="font-medium text-gray-500"
            style={{ minWidth: '200px' }}>
            <span className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.OFFER.SALES_CHANNEL' })}
            </span>
            <SalesChannelSelect
              value={salesChannel}
              onChange={(_, v) => setSalesChannel(v)}
            />
          </label>

          <label
            className="font-medium text-gray-500"
            style={{ minWidth: '200px' }}>
            <span className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.OFFER.PARTNER' })}
            </span>
            <PartnerSelect
              value={partner}
              onChange={(_, v) => setPartner(v)}
              salesChannelId={salesChannel?.id}
            />
          </label>

          <label
            className="font-medium text-gray-500"
            style={{ minWidth: '200px' }}>
            <span className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.OFFER.OFFER' })}
            </span>
            <Controller
              render={({ field: { value, onChange } }) => (
                <OfferSelect
                  value={value}
                  onChange={(_, v) => onChange(v)}
                  salesChannelId={salesChannel?.id}
                  partnerId={partner?.id}
                />
              )}
              name="offer"
              rules={{ required: true }}
              control={control}
            />
          </label>
        </div>
      )}
    </KTCard>
  )
}
