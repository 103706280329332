import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../context/Auth'

import { useParams } from 'react-router-dom'
import ModificaQualificaPresentational from './ModificaQualificaPresentational'
import {
  UpdateRoleArg,
  useGetRoleByIdQuery,
  useUpdateRoleMutation
} from '../../../store/api/roles'

function NuovaQualifica({ currentPermissions }: any) {
  const [auth = {}] = useAuth()
  const { token } = auth
  const formContext = useForm()
  const { id: idParam } = useParams()
  const id = parseInt(idParam ?? '0')
  const alert = useAlert()
  const intl = useIntl()
  const { data: role, refetch: fetchRole } = useGetRoleByIdQuery(id)
  const [updateRole, { isError: isErrorUpdatingRole, isSuccess, isLoading }] =
    useUpdateRoleMutation()

  async function onSave({ createDto, id }: UpdateRoleArg) {
    await updateRole({
      id,
      createDto: createDto
    })
  }

  useEffect(() => {
    if (isSuccess) {
      alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    }
  }, [isSuccess])

  useEffect(() => {
    if (isErrorUpdatingRole) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    }
  }, [isErrorUpdatingRole])

  useEffect(() => {
    fetchRole()
  }, [id])

  useEffect(() => {
    document.title = 'Control Room - Nuova qualifica'
  }, [])

  return (
    <>
      {role && (
        <ModificaQualificaPresentational
          currentPermissions={currentPermissions}
          role={role}
          loading={isLoading}
          onSave={onSave}
        />
      )}
    </>
  )
}

export default NuovaQualifica
