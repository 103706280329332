// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import { useIntl } from 'react-intl'
import { css } from '@emotion/react'
import { KTCard } from '../../../_metronic/helpers'
import { ElaborazioneDatiTable } from '../../pages/Contratti/ElaborazioneDati/table/ElaborazioneDatiTable'

function ElaborazioniDatiTable({
  currentPermissions,
  loading = false,
  jobs = [],
  totalResults,
  resultsPerPage,
  onPageChange,
  onDownloadJob
}) {
  const intl = useIntl()

  const clipLoaderCss = css`
    height: 20px;
    width: 20px;
  `

  return (
    <div>
      <KTCard
        className="mb-8"
        style={{
          padding: '1rem',
          boxShadow: '0 0 0 1px rgb(0 0 0 / 5%)',
          borderRadius: '0.5rem'
        }}>
        <ElaborazioneDatiTable
          currentPermissions={currentPermissions}
          jobs={jobs}
          loading={loading}
          totalResults={totalResults}
          resultsPerPage={resultsPerPage}
          onPageChange={onPageChange}
          onDownloadJob={onDownloadJob}
        />
      </KTCard>
    </div>
  )
}

export default ElaborazioniDatiTable
