import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Card2 } from '../../../../../../../_metronic/partials/content/cards/Card2'
import {
  ResponseContractDto,
  useLazyGetContractsListQuery
} from '../../../../../../store/api/contracts'
import moment from 'moment'

interface Props {
  id: number
}

export function ContractsTab({ id }: Props) {
  const [getContractsList, { data: contractsResponse }] =
    useLazyGetContractsListQuery()
  const totalItems = contractsResponse ? contractsResponse.meta.totalItems : 0
  const totalPages = contractsResponse
    ? contractsResponse.meta.totalPages || 1
    : 1
  const currentPage = contractsResponse ? contractsResponse.meta.currentPage : 1
  const contracts = contractsResponse
    ? (contractsResponse.items as ResponseContractDto[])
    : []
  const [statuses, setStatuses] = useState([])
  const [statusId, setStatusId] = useState(20)
  const [loading, setLoading] = useState(false)

  const intl = useIntl()

  useEffect(() => {
    getContractsList({ page: 1, customer: [id] })
  }, [id])

  const getPages = () => {
    let pages = []
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i)
    }
    return pages
  }

  const showContracts = (e: any) => {
    setLoading(true)
    setStatusId(e.target.value)
  }

  if (!loading) {
    return (
      <>
        <div className="d-flex flex-wrap flex-stack mb-6">
          <h3 className="fw-bolder my-2">
            Contratti
            <span className="fs-6 text-gray-400 fw-bold ms-1">Attivi</span>
          </h3>

          <div className="d-flex flex-wrap my-2">
            <div className="me-4">
              <select
                name="status"
                data-control="select2"
                data-hide-search="true"
                className="form-select form-select-sm form-select-white w-125px"
                defaultValue={statusId}
                onChangeCapture={showContracts}>
                <option value={0}>Tutti</option>
                {statuses.map((x: any) => {
                  return <option value={x?.id}>{x?.name}</option>
                })}
              </select>
            </div>
            <Link to={`/contracts/new/${id}`}>
              <button type="button" className="btn btn-primary">
                {intl.formatMessage({ id: 'COMMON.NEW_CONTRACT' })}
              </button>
            </Link>
          </div>
        </div>

        <div className="row g-6 g-xl-9">
          {contracts &&
            contracts.length > 0 &&
            contracts.map(contract => {
              return (
                <div className="col-md-6 col-xl-4" key={contract.id}>
                  <Card2
                    icon="/media/svg/brand-logos/plurk.svg"
                    badgeColor="primary"
                    status={contract?.status?.name}
                    statusColor="primary"
                    title={contract?.offer?.name}
                    description={`${contract?.offer?.salesChannel?.code} (${contract?.podPdr})`}
                    date={moment(contract?.contractDate).format('DD-MM-YYYY')}
                    budget={`${contract?.offer?.value ?? 0} €`}
                    progress={50}
                  />
                </div>
              )
            })}

          {contracts?.length === 0 && <div>Nessun contratto presente</div>}
        </div>

        {contracts && contracts.length > 0 && (
          <div className="d-flex flex-stack flex-wrap pt-10">
            <div className="fs-6 fw-bold text-gray-700">
              {currentPage} di {totalPages} pagine ({totalItems} contratti)
            </div>

            <ul className="pagination mr-5">
              <li
                className={
                  'page-item ' +
                  (currentPage === 1 ? 'disabled' : 'cursor-pointer')
                }>
                <a
                  className="page-link"
                  onClick={() =>
                    getContractsList({ page: currentPage - 1, customer: [id] })
                  }>
                  <i className="bi bi-chevron-left" />
                </a>
              </li>
              {getPages().map((page, index) => {
                return (
                  <li
                    key={index}
                    className={`page-item cursor-pointer
                    ${currentPage === page ? 'active' : ''}`}>
                    <a
                      className="page-link"
                      onClick={() =>
                        currentPage !== page &&
                        getContractsList({ page, customer: [id] })
                      }>
                      {page}
                    </a>
                  </li>
                )
              })}
              <li
                className={`page-item ${currentPage === totalPages ? 'disabled' : 'cursor-pointer'}`}>
                <a
                  className="page-link"
                  onClick={() =>
                    getContractsList({ page: currentPage + 1, customer: [id] })
                  }>
                  <i className="bi bi-chevron-right" />
                </a>
              </li>
            </ul>
          </div>
        )}
      </>
    )
  } else {
    return <></>
  }
}
