import { useIntl } from 'react-intl'
import PageTitle from '../../../components/Typography/PageTitle'
import { Link } from 'react-router-dom'
import { KTCard, KTSVG } from '../../../../_metronic/helpers'
import WorkflowsTable from './WorkflowsTable'

export default function WorkflowsPage() {
  const canWrite = true
  const intl = useIntl()

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="flex items-center justify-between">
          <PageTitle>{intl.formatMessage({ id: 'WORKFLOWS.TITLE' })}</PageTitle>
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base">
            {canWrite && (
              <Link to="/workflows/new">
                <button type="button" className="btn btn-primary">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {intl.formatMessage({ id: 'WORKFLOWS.NEW_WORKFLOW' })}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <KTCard className="mb-8">
        <WorkflowsTable />
      </KTCard>
    </>
  )
}
