import React from 'react'
import { useIntl } from 'react-intl'
import { KTCard } from '../../../../../_metronic/helpers'
import StatusSelect from '../../../../components/selects/StatusSelect'
import { Controller, UseFormReturn } from 'react-hook-form'
import { NewContractFormStaus } from '../../NewContract'
import { DateTime } from 'luxon'

export type OfferSectionProps = {
  form: UseFormReturn<NewContractFormStaus>
  readonly?: boolean
}

export default ({
  form: { control, register, watch },
  readonly
}: OfferSectionProps) => {
  const intl = useIntl()

  const [status, code, podPdr, contractDate] = watch([
    'status',
    'code',
    'podPdr',
    'contractDate'
  ])

  return (
    <KTCard className="p-4">
      <div className="flex items-center justify-between mb-5">
        <p className="font-semibold text-gray-500 text-lg">
          {intl.formatMessage({ id: 'CONTRACT.DATA.TITLE' })}
        </p>
      </div>

      {readonly ? (
        <>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <p className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.DATA.STATUS' })}
            </p>
            <p>{status?.name}</p>
          </div>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <p className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.DATA.DATE' })}
            </p>
            <p>
              {contractDate &&
                DateTime.fromISO(contractDate).toLocaleString(
                  DateTime.DATE_HUGE
                )}
            </p>
          </div>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <p className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.DATA.CODE' })}
            </p>
            <p>{code}</p>
          </div>
          <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
            <p className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.DATA.POD_PDR' })}
            </p>
            <p>{podPdr}</p>
          </div>
        </>
      ) : (
        <div className="grid gap-1 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-2">
          <label
            className="font-medium text-gray-500"
            style={{ minWidth: '200px' }}>
            <span className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.DATA.STATUS' })}
            </span>
            <Controller
              render={({ field: { value, onChange } }) => (
                <StatusSelect value={value} onChange={(_, v) => onChange(v)} />
              )}
              name="status"
              rules={{ required: true }}
              control={control}
            />
          </label>

          <label
            className="font-medium text-gray-500"
            style={{ minWidth: '200px' }}>
            <span className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.DATA.DATE' })}
            </span>
            <input
              type="date"
              {...register('contractDate', { required: true })}
              className="form-control form-control-lg mt-1"
            />
          </label>

          <label
            className="font-medium text-gray-500"
            style={{ minWidth: '200px' }}>
            <span className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.DATA.CODE' })}
            </span>
            <input
              {...register('code', { required: true })}
              className="form-control form-control-lg mt-1"
            />
          </label>

          <label
            className="font-medium text-gray-500"
            style={{ minWidth: '200px' }}>
            <span className="font-medium text-gray-500">
              {intl.formatMessage({ id: 'CONTRACT.DATA.POD_PDR' })}
            </span>
            <input
              {...register('podPdr', { required: true })}
              className="form-control form-control-lg mt-1"
            />
          </label>
        </div>
      )}
    </KTCard>
  )
}
