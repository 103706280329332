import React, { useEffect, useState } from 'react'

import PageTitle from '../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../context/Auth'
import { get, save } from '../../lib/fetch/salesChannels'
import { useLoading } from '../../context/LoadingContext'
import UploadImage from '../../components/UploadImage'
import { uploadChannelImage } from '../../lib/fetch/uploads'
import { useIntl } from 'react-intl'

function ModificaCanale({ currentPermissions }: any) {
  const canWrite = currentPermissions.can('write', 'salesChannels')
  const alert = useAlert()
  const history = useNavigate()
  const [auth = {}] = useAuth()
  const { showLoading, hideLoading } = useLoading()
  const { token } = auth
  const intl = useIntl()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm()
  const { id: idParam } = useParams()
  const id = parseInt(idParam ?? '0')
  const [image, setImage] = useState()
  const [imageFile, setImageFile] = useState()

  async function onUploadImage(file: any, id: number) {
    const { error } = await uploadChannelImage({
      token,
      channelId: id,
      file
    })
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.UPLOAD_IMAGE_ERROR' }))
      return
    }
    setImageFile(undefined)
  }

  async function fetchChannel() {
    showLoading()
    // @ts-ignore
    const { error, data } = await get({
      token,
      id
    })

    hideLoading()
    if (error) {
      reset({})
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    reset({
      code: data.code,
      description: data.description,
      rankingMetrics: data.rankingMetrics,
      isRankingVisible: data.isRankingVisible,
      isRankingValueVisible: data.isRankingValueVisible
    })
    setImage(data.image)
  }

  async function onSubmit(values: any) {
    const {
      code,
      description,
      rankingMetrics,
      isRankingVisible,
      isRankingValueVisible
    } = values

    showLoading()
    // @ts-ignore
    const { error } = await save({
      id,
      data: {
        code,
        description,
        rankingMetrics,
        isRankingVisible,
        isRankingValueVisible
      },
      token
    })

    hideLoading()
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    imageFile && onUploadImage(imageFile, id)
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    fetchChannel()
  }

  useEffect(() => {
    fetchChannel()
  }, [id])

  useEffect(() => {
    document.title = 'Control Room - Modifica Canale'
  }, [])

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon as any}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({
            id: `EDIT_CHANNEL.${canWrite ? 'TITLE' : 'TITLE_READONLY'}`
          })}
        </PageTitle>
      </div>
      <div className="card mb-5 mb-xl-10">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <fieldset disabled={!canWrite}>
            <div className="card-body pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {intl.formatMessage({ id: 'COMMON.CODE' })}
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    className="mt-1 form-control form-control-lg form-control-solid"
                    type="text"
                    {...register('code', {
                      required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                      maxLength: {
                        value: 20,
                        //t('COMMON:MAX_LENGTH', { length: 20 })
                        message: intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })
                      }
                    })}
                  />
                  {errors.code && errors.code.message && (
                    <span className="text-xs text-red-400 mt-4">
                      {errors.code.message.toString()}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {intl.formatMessage({ id: 'COMMON.IMAGE' })}
                </label>

                <div className="col-lg-8 fv-row">
                  <UploadImage
                    defaultImage={image}
                    onImageSelected={file => setImageFile(file as any)}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {intl.formatMessage({ id: 'COMMON.DESCRIPTION' })}
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    className="mt-1 form-control form-control-lg form-control-solid"
                    type="text"
                    {...register('description', {
                      required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                      maxLength: {
                        value: 200,
                        //t('COMMON:MAX_LENGTH', { length: 200 })
                        message: intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })
                      }
                    })}
                  />
                  {errors.description && errors.description.message && (
                    <span className="text-xs text-red-400 mt-4">
                      {errors.description.message.toString()}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Metrica del ranking
                </label>

                <div className="col-lg-8 fv-row">
                  <select
                    data-placeholder={intl.formatMessage({
                      id: 'COMMON.SELECT'
                    })}
                    className="mt-1 form-select form-select-solid form-select-lg"
                    {...register('rankingMetrics', {
                      required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                      maxLength: {
                        value: 200,
                        //t('COMMON:MAX_LENGTH', { length: 200 })
                        message: intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })
                      }
                    })}>
                    <option value="value">Valore</option>
                    <option value="count">Numero</option>
                  </select>
                  {errors.rankingMetrics && errors.rankingMetrics.message && (
                    <span className="text-xs text-red-400 mt-4">
                      {errors.rankingMetrics.message.toString()}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Visibile nel ranking
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    className="mt-1 form-check-input"
                    type="checkbox"
                    {...register('isRankingVisible')}
                  />
                  {errors.isRankingVisible &&
                    errors.isRankingVisible.message && (
                      <span className="text-xs text-red-400 mt-4">
                        {errors.isRankingVisible.message.toString()}
                      </span>
                    )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Valore visibile nel ranking
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    className="mt-1 form-check-input"
                    type="checkbox"
                    {...register('isRankingValueVisible')}
                  />
                  {errors.isRankingValueVisible &&
                    errors.isRankingValueVisible.message && (
                      <span className="text-xs text-red-400 mt-4">
                        {errors.isRankingValueVisible.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            </div>
          </fieldset>
          {canWrite && (
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                {intl.formatMessage({ id: 'COMMON.SAVE' })}
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  )
}

export default ModificaCanale
