import React from 'react'
import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../../_metronic/helpers'

export interface UsersFilterProps {
  onFilterApplied: (filter: object) => void
  onFilterReset: () => void
}

const UsersListFilter = ({
  onFilterApplied,
  onFilterReset
}: UsersFilterProps) => {
  const [first_name, setFirstName] = useState<string | undefined>()
  const [last_name, setLastName] = useState<string | undefined>()
  const [fiscal_code, setFiscalCode] = useState<string | undefined>()
  const [company, setCompany] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setFirstName('')
    setLastName('')
    setFiscalCode('')
    setCompany('')
    onFilterReset()
  }

  const filterData = () => {
    onFilterApplied({
      first_name,
      last_name,
      fiscal_code,
      company
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        type="button"
        className="btn btn-light-primary me-3"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end">
        <KTSVG
          path="/media/icons/duotune/general/gen031.svg"
          className="svg-icon-2"
        />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
        data-kt-menu="true">
        {/* begin::Header */}
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className="separator border-gray-200" />
        {/* end::Separator */}

        {/* begin::Content */}
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          {/* begin::Input group */}
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">Nome:</label>
            <input
              type="text"
              data-kt-user-table-filter="first_name"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Digita nome"
              value={first_name}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">Cognome:</label>
            <input
              type="text"
              data-kt-user-table-filter="last_name"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Digita cognome"
              value={last_name}
              onChange={e => setLastName(e.target.value)}
            />
          </div>
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">Codice fiscale:</label>
            <input
              type="text"
              data-kt-user-table-filter="fiscal_code"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Digita codice fiscale"
              value={fiscal_code}
              onChange={e => setFiscalCode(e.target.value)}
            />
          </div>
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">Azienda:</label>
            <input
              type="text"
              data-kt-user-table-filter="company"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Digita azienda"
              value={company}
              onChange={e => setCompany(e.target.value)}
            />
          </div>
          {/* begin::Actions */}
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={resetData}
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="reset">
              Reset
            </button>
            <button
              type="button"
              onClick={filterData}
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter">
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { UsersListFilter }
