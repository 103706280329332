import React from 'react'
import { types } from 'react-alert'
import { KTSVG } from '../../_metronic/helpers'

const alertStyle: any = {
  position: 'relative',
  padding: '15px 10px 15px 25px',
  textTransform: 'uppercase',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  width: '330px',
  fontWeight: '600',
  boxSizing: 'border-box',
  textAlign: 'center'
}

const alertColors: any = {
  info: '#5cc0f8',
  success: '#76e376',
  error: '#ff7676'
}

const buttonStyle: any = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#142935'
}

const borderStyle: any = {
  position: 'absolute',
  left: '0',
  top: '0',
  width: '12px',
  height: '100%',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px'
}

const iconStyle: any = {
  marginRight: '20px'
}

const AlertTemplate = ({
  style,
  options,
  message,
  close
}: {
  style: any
  options: any
  message: any
  close: any
}) => (
  <div
    style={{
      ...alertStyle,
      ...style,
      color: 'white',
      backgroundColor: alertColors[options.type]
    }}>
    {options.type === types.INFO && (
      <KTSVG
        path="/media/icons/duotune/general/gen045.svg"
        className=" svg-icon-1 svg-icon-white"
      />
    )}
    {options.type === types.SUCCESS && (
      <KTSVG
        path="/media/icons/duotune/general/gen043.svg"
        className=" svg-icon-1 svg-icon-white"
      />
    )}
    {options.type === types.ERROR && (
      <KTSVG
        path="/media/icons/duotune/general/gen040.svg"
        className=" svg-icon-1 svg-icon-white"
      />
    )}
    {message}
    <button onClick={close} style={buttonStyle}>
      <i className="fas fa-times" />
    </button>
  </div>
)

export default AlertTemplate
