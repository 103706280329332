import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import useLogin from '../../../../../hooks/useLogin'
import { ResponseContractDto } from '../../../../../store/api/contracts'
import { KTSVG } from '../../../../../../_metronic/helpers'

export type ContractActionsCellProps = {
  contract: ResponseContractDto
  onDelete: () => void
}

export default ({
  contract: { id, code, customer },
  onDelete
}: ContractActionsCellProps) => {
  const intl = useIntl()
  const alert = useAlert()

  const { can } = useLogin()

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Link to={`/contracts/${id}`}>
          <button
            type="button"
            className="btn btn-light-primary me-3"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end">
            <i className="text-2xl bi bi-eye-fill fs-2 p-0" />
          </button>
        </Link>
        <button
          type="button"
          className="btn btn-light-primary me-3 hidden"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-users-table-filter="delete_row"
          data-bs-toggle="modal"
          data-bs-target={`#kt_modal_2${id}`}>
          <i className="text-2xl bi bi-trash-fill fs-2 p-0" />
        </button>
      </div>

      <div className="modal fade" tabIndex={-1} id={`kt_modal_2${id}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Elimina contratto</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>
                Sei sicuro di voler eliminare il contratto{' '}
                <strong>{code}</strong> di{' '}
                <strong>
                  {customer?.firstName ?? ' ? '} {customer?.lastName ?? ' ? '}
                </strong>
                ?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {}}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
