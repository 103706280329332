import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers'
import {
  CreateRoleArg,
  ResponseRoleDto,
  UpdateRoleArg
} from '../../store/api/roles'
import {
  ResponsePolicyDto,
  useGetAllPoliciesQuery
} from '../../store/api/policies'

// refactored to make it better for typescript
type Props = {
  canWrite: boolean
  loading?: boolean
} & (
  | {
      type: 'update'
      role: ResponseRoleDto
      onUpdate: ({ createDto, id }: UpdateRoleArg) => void
      onCreate?: never
    }
  | {
      type: 'create'
      role?: never
      onUpdate?: never
      onCreate: (role: CreateRoleArg) => void
    }
)

const RoleForm = ({
  canWrite,
  role,
  type,
  loading,
  onUpdate,
  onCreate
}: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: role ? role.name : '',
      description: role ? role.description : '',
      isBackOfficeRole: role ? role.isBackOfficeRole : false
    }
  })
  const intl = useIntl()

  const handleSave = ({
    name,
    description,
    isBackOfficeRole
  }: {
    name: string
    description: string
    isBackOfficeRole: boolean
  }) => {
    if (type === 'update') {
      onUpdate({
        createDto: {
          name,
          description,
          isBackOfficeRole: isBackOfficeRole,
          policies: newPolicies.map(p => p.id)
        },
        id: role.id
      })
    } else {
      onCreate({
        name,
        description,
        isBackOfficeRole,
        policies: newPolicies.map(p => p.id)
      })
    }
  }
  const [newPolicies, setNewPolicies] = useState<ResponsePolicyDto[]>(
    role?.policies ?? []
  )

  const [selectedPolicy, setSelectedPolicy] = useState<ResponsePolicyDto | ''>()
  const { data: policies, isFetching: isFetchingPolicies } =
    useGetAllPoliciesQuery({})

  const handlePolicyChange = (policyId: number) => {
    if (!policies?.items) return
    const policy = policies.items.find(p => p.id === policyId)
    if (!policy) return
    setSelectedPolicy(policy)
  }

  const handleAddPolicy = () => {
    if (selectedPolicy) {
      setNewPolicies([...newPolicies, selectedPolicy])
      setSelectedPolicy('')
    }
  }

  const handleRemovePolicy = (policy: ResponsePolicyDto) => {
    setNewPolicies(newPolicies.filter(p => p.id !== policy.id))
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <form onSubmit={handleSubmit(handleSave)} className="form">
        <fieldset disabled={!canWrite}>
          <div className="card-body">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.CODE' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'COMMON.CODE' })}
                  {...register('name', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                    maxLength: {
                      value: 30,
                      message: 'Massimo 30 caratteri'
                    }
                  })}
                />
                {errors.name && errors.name.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.name.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.DESCRIPTION' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'COMMON.DESCRIPTION' })}
                  {...register('description', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                    maxLength: {
                      value: 40,
                      message: 'Massimo 40 caratteri'
                    }
                  })}
                />
                {errors.description && errors.description.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.description.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.ISBACKOFFICE' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="form-check-input"
                  type="checkbox"
                  {...register('isBackOfficeRole')}
                />
                {errors.description && errors.description.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.description.message.toString()}
                  </span>
                )}
              </div>
            </div>
          </div>
        </fieldset>
        <div className="card-body border-top pt-3">
          <div className="fs-4 font-semibold mb-3 mt-2">
            {intl.formatMessage({ id: 'POLICIES_FORM.PERMISSIONS' })}
          </div>
          <div className="row mb-6">
            <div className="col-lg-8 fv-row">
              <div className="d-flex">
                <select
                  className="form-select form-select-lg form-select-solid"
                  disabled={isFetchingPolicies}
                  value={selectedPolicy ? selectedPolicy.id : ''}
                  onChange={e => handlePolicyChange(parseInt(e.target.value))}>
                  <option value="" disabled selected>
                    Seleziona un permesso
                  </option>
                  {policies?.items
                    ?.filter(p => !newPolicies.includes(p))
                    .map(policy => (
                      <option key={policy.id} value={policy.id}>
                        {policy.name}
                      </option>
                    ))}
                </select>
                <button
                  className="btn btn-primary ml-2"
                  onClick={handleAddPolicy}
                  disabled={!canWrite || !selectedPolicy}>
                  {intl.formatMessage({ id: 'COMMON.ADD' })}
                </button>
              </div>
            </div>
          </div>
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-bold fs-6">
              {intl.formatMessage({ id: 'POLICIES_FORM.ASSOCIATED_POLICIES' })}
            </label>

            <div className="col-lg-8 fv-row">
              {newPolicies.length > 0 ? (
                newPolicies.map(policy => (
                  <div
                    key={`pol-${policy.id}`}
                    className="badge badge-primary mr-4 mb-2">
                    <label className="fw-bold fs-6">{policy.name}</label>
                    <button
                      className="btn btn-icon btn-light-danger btn-sm ml-3"
                      onClick={() => handleRemovePolicy(policy)}
                      disabled={!canWrite}>
                      <KTSVG
                        path="/media/icons/duotune/general/gen027.svg"
                        className="svg-icon-2"
                      />
                    </button>
                  </div>
                ))
              ) : (
                <span>Nessun permesso associato</span>
              )}
            </div>
          </div>
        </div>
        {canWrite && (
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}>
              {!loading && intl.formatMessage({ id: 'COMMON.SAVE' })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              )}
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default RoleForm
