// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import axios from 'axios'

export const list = async token => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/policies`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const create = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/policies`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const remove = async ({ id, token } = {}) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/policies/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const attachToRole = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/policies/roles/attach`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const detachFromRole = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/policies/roles/detach`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const attachToAdmin = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/policies/admins/attach`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

export const detachFromAdmin = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/policies/admins/detach`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
