// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { useIntl } from 'react-intl'
import DataTile from '../../../components/DataTile'
import { formatCurrency } from '../../../lib/helpers/formatters'
import DettaglioEstrattoTable from '../../../components/EstrattiConto/DettaglioEstrattoTable'
import { MONTHS } from '../../../lib/constants'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from '@mui/x-date-pickers'

function DettglioEstrattoPresentational({
  currentPermissions,
  items,
  balance = {},
  onPublishBalance,
  loading,
  totalResults,
  resultsPerPage,
  onPageChange
}) {
  const intl = useIntl()
  const { bonus, malus, month, year, contractsCount } = balance
  const history = useNavigate()

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />

        <PageTitle>
          {intl.formatMessage(
            { id: 'DETTAGLIO_ESTRATTO.TITLE' },
            {
              balanceDate:
                MONTHS.find(({ en }) => en === month)?.it + ' ' + year
            }
          )}
        </PageTitle>
        <Button
          className="ml-auto lightblue-btn"
          disabled={balance.public}
          onClick={onPublishBalance}
          style={{ marginBottom: '1rem' }}>
          {intl.formatMessage({ id: 'DETTAGLIO_ESTRATTO.PUBLISH' })}
        </Button>
      </div>

      <div className="flex flex-row gap-4 mb-4">
        <DataTile
          title={intl.formatMessage({
            id: 'DETTAGLIO_ESTRATTO.CONTRACTS_COUNT'
          })}
          value={contractsCount || 0}
        />
        <DataTile
          title={intl.formatMessage({ id: 'DETTAGLIO_ESTRATTO.BONUS' })}
          value={formatCurrency(bonus || 0)}
        />
        <DataTile
          title={intl.formatMessage({ id: 'DETTAGLIO_ESTRATTO.MALUS' })}
          value={formatCurrency(malus || 0)}
        />
      </div>

      <DettaglioEstrattoTable
        currentPermissions={currentPermissions}
        items={items}
        loading={loading}
        totalResults={totalResults}
        resultsPerPage={resultsPerPage}
        onPageChange={onPageChange}
      />
    </>
  )
}

export default DettglioEstrattoPresentational
