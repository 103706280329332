/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { KTSVG } from '../../../../helpers'
import { LINK_MODULISTICA } from '../../../../../app/lib/constants'
import { Divider, Stack, Typography } from '@mui/material'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/general/gen001.svg"
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItemWithSub
        to="/agenzia/agenzia"
        title="Agenzia"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/finance/fin006.svg">
        <SidebarMenuItem
          to="/agenzia/gestisci-agenti"
          title="Gestisci agenti"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/agenzia/back-office"
          title="Back Office"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/agenzia/canali-vendita"
          title="Canali di vendita"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/agenzia/impostazioni"
          title="Impostazioni"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/rete/rete"
        title="Rete"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/general/gen008.svg">
        <SidebarMenuItem to="/rete/agenti" title="Agenti" hasBullet={true} />
        <SidebarMenuItem
          to="/rete/posizioni-fiscali"
          title="Posizioni fiscali"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/rete/lista-qualifiche"
          title="Lista qualifiche"
          hasBullet={true}
        />
        <SidebarMenuItem to="/rete/lista-sedi" title="Sedi" hasBullet={true} />

        <SidebarMenuItem
          to="/rete/naviga-struttura"
          title="Naviga struttura"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/catalogo/catalogo"
        title="Catalogo"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/files/fil012.svg">
        <SidebarMenuItem
          to="/catalogo/partner"
          title="Partner"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/catalogo/offerte-articoli"
          title="Offerte articoli"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to="/customers"
        icon="/media/icons/duotune/communication/com014.svg"
        title="Anagrafiche"
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItemWithSub
        to="/contratti/contratti"
        title="Contratti"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/files/fil003.svg">
        <SidebarMenuItem
          to="/contracts"
          title="Contratti e vendite"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/contratti/esiti-lavorazione"
          title="Esiti lavorazione"
          hasBullet={true}
        />
        <SidebarMenuItem to="/workflows" title="Workflows" hasBullet={true} />
        {/* <SidebarMenuItem
          to="/contratti/elaborazione-dati"
          title="Elaborazione dati"
          hasBullet={true}
        /> */}
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub
        to="/estratto-conto"
        title="Estratto conto"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/finance/fin008.svg">
        <SidebarMenuItem
          to="/estratto-conto/estratti-conto"
          title="Estratto conto"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/estratto-conto/piani-compenso"
          title="Piani di compenso"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}>
        <SidebarMenuItem
          to="/contracts/new"
          style={{ marginTop: '1rem' }}
          children={
            <button type="button" className="btn btn-primary w-100">
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              {intl.formatMessage({ id: 'COMMON.NEW_CONTRACT' })}
            </button>
          }
        />
        <div className="menu-item">
          <div className="menu-link without-sub">
            <button
              type="button"
              className="btn btn-primary w-100 "
              onClick={() => {
                window.open(LINK_MODULISTICA, '_blank', 'noopener,noreferrer')
              }}>
              {intl.formatMessage({ id: 'SIDEBAR.DOCUMENTS' })}
            </button>
          </div>
        </div>
        {process.env.REACT_APP_VERSION && (
          <div className="menu-item justify-center flex">
            <span className="font-light text-slate-500 tabular-nums uppercase">
              v{process.env.REACT_APP_VERSION}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export { SidebarMenuMain }
