import React from 'react'
import { useEffect } from 'react'
import { Navigate, Routes } from 'react-router-dom'
import useLogin from '../../hooks/useLogin'

export function Logout() {
  const { logout } = useLogin()
  useEffect(() => {
    if (logout) logout()
  }, [logout])

  return (
    <Routes>
      <Navigate to="/auth/login" />
    </Routes>
  )
}
