import axios from 'axios'
import { AuthenticatedQuery } from '../../interfaces/PaginatedQuery'

export const list = async ({ token }: AuthenticatedQuery<{}>) => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/workflows`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

interface GetWorkflowParams {
  id: string
}

export const get = async ({
  id,
  token
}: AuthenticatedQuery<GetWorkflowParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/workflows/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreateWorkflowParams {
  data: any
}

export const create = async ({
  data,
  token
}: AuthenticatedQuery<CreateWorkflowParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/workflows`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface RemoveWorkflowParams {
  id: string
}

export const remove = async ({
  id,
  token
}: AuthenticatedQuery<RemoveWorkflowParams>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/workflows/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
