// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import RoleFormLabels from '../../../components/Roles/RoleFormLabels'
import { useIntl } from 'react-intl'

function ModificaRuoloPresentational({
  currentPermissions,
  onSubmit,
  roles,
  role
}) {
  const canWrite = currentPermissions.can('write', 'roles')
  const history = useNavigate()
  const intl = useIntl()

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({
            id: `EDIT_ROLE_AGENCY.${canWrite ? 'TITLE' : 'TITLE_READONLY'}`
          })}
        </PageTitle>
      </div>
      <RoleFormLabels
        canWrite={canWrite}
        role={role}
        onSubmit={onSubmit}
        roles={roles}
      />
    </>
  )
}

export default ModificaRuoloPresentational
