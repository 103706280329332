import React, { useEffect } from 'react'
import { useAlert } from 'react-alert'

import { Navigate, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { NewUserForm } from '../../../components/GestioneUtenze/NewUserForm'
import { CreateUserDto, useCreateUserMutation } from '../../../store/api/users'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import PageTitle from '../../../components/Typography/PageTitle'

function NuovoUtente({ currentPermissions, location }: any) {
  const canWrite = currentPermissions.can('write', 'users')
  const [createUser, { isSuccess }] = useCreateUserMutation()
  const history = useNavigate()
  const alert = useAlert()
  const intl = useIntl()
  useEffect(() => {
    document.title = 'Control Room - Nuovo Utente'
  }, [])

  if (!canWrite) {
    let pathname = '/'
    if (location && location.pathname) {
      pathname = location.pathname.split('/')
      if (Array.isArray(pathname)) {
        pathname.pop()
        pathname = pathname.join('/')
      }
    }
    return <Navigate to={{ pathname }} />
  }

  const onSubmit = async (data: CreateUserDto) => {
    createUser(data)
  }

  useEffect(() => {
    if (isSuccess) {
      alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
      history(-1)
    }
  }, [isSuccess])

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>Nuova anagrafica</PageTitle>
      </div>
      <NewUserForm
        type="create"
        onCreate={onSubmit}
        loading={false}
        isBackOffice={false}
      />
    </>
  )
}

export default NuovoUtente
