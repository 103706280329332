import React from 'react'
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteUser } from '../../core/_requests'
import { Link } from 'react-router-dom'

type Props = {
  id: ID
  onDelete?: () => void
}

const UserActionsCell: FC<Props> = ({ id, onDelete }) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteUser(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    }
  })

  const handleDelete = async () => {
    await deleteItem.mutateAsync()
    onDelete && onDelete()
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Link to={`/contracts/new/${id}`}>
          <button
            type="button"
            className="btn btn-light-primary me-3"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end">
            <i className="text-2xl bi bi-file-earmark-plus-fill fs-2 p-0" />
          </button>
        </Link>
        <Link to={'/customers/edit/' + id}>
          <button
            type="button"
            className="btn btn-light-primary me-3"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end">
            <i className="text-2xl bi bi-pencil-square fs-2 p-0" />
          </button>
        </Link>
        {false && (
          <button
            type="button"
            className="btn btn-light-primary me-3"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-users-table-filter="delete_row"
            onClick={handleDelete}>
            <i className="text-2xl bi bi-trash-fill fs-2 p-0" />
          </button>
        )}
      </div>
    </>
  )
}

export { UserActionsCell }
