import { api } from './index'
export const addTagTypes = ['Users'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      changePassword: build.mutation<
        ChangePasswordApiResponse,
        ChangePasswordApiArg
      >({
        query: queryArg => ({
          url: `/api/me/change-password`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Users']
      }),
      getMe: build.query<GetMeApiResponse, GetMeApiArg>({
        query: () => ({ url: `/api/me` }),
        providesTags: ['Users']
      }),
      updateProfileUser: build.mutation<
        UpdateProfileUserApiResponse,
        UpdateProfileUserApiArg
      >({
        query: queryArg => ({ url: `/api/me`, method: 'PUT', body: queryArg }),
        invalidatesTags: ['Users']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type ChangePasswordApiResponse = unknown
export type ChangePasswordApiArg = ChangePasswordDto
export type GetMeApiResponse = /** status 200  */ ResponseUserDto
export type GetMeApiArg = void
export type UpdateProfileUserApiResponse = /** status 200  */ ResponseUserDto
export type UpdateProfileUserApiArg = UpdateUserDto
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type ChangePasswordDto = {
  password: string
}
export type ResponseAgencyDto = {
  id: number
  name: string
  city?: string
  address?: string
  zip_code?: string
  phone?: string
}
export type ResponseRoleDto = {
  id: number
  name: string
  description: string
  isBackOfficeRole: boolean
  policies: string[]
  capabilities?: {
    agencies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statistics?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    ranking?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    roles?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    policies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    workflows?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    partners?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    offers?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    salesChannels?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statuses?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers|surveys'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|notes'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|attachments'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    notes?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    admins?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    fiscalPositions?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'fiscalPositions|prospectus'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    settings?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    balances?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    privacy?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
  }
}
export type FiscalPositions = {}
export type ResponseUserSummaryDto = {
  id: number
  username: string
  firstName: string
  lastName: string
  fiscalCode?: string
  agency: ResponseAgencyDto
}
export type ResponseUserDto = {
  id: number
  username: string
  firstName: string
  lastName: string
  fiscalCode?: string
  agency: ResponseAgencyDto
  createdAt: string
  email: string
  status: boolean
  role: ResponseRoleDto
  budget: number
  iban: string
  province: string
  city: string
  address: string
  zip_code: string
  fiscalPosition: FiscalPositions
  salesChannels: string[]
  capabilities?: {
    agencies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statistics?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    ranking?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    roles?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    policies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    workflows?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    partners?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    offers?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    salesChannels?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statuses?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers|surveys'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|notes'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|attachments'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    notes?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    admins?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    fiscalPositions?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'fiscalPositions|prospectus'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    settings?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    balances?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    privacy?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
  }
  sponsor?: ResponseUserSummaryDto
}
export type UpdateUserDto = {
  firstName: string
  lastName: string
  email: string
  status: boolean
  salesChannels: string[]
  mobile: string
  businessName: string
  fiscalCode: string
  budget: number
  province: string
  city: string
  address: string
  zipCode: string
  iban: string
  id_parent: number
  id_fiscal_position: number
  id_agency: number
}
export const {
  useChangePasswordMutation,
  useGetMeQuery,
  useLazyGetMeQuery,
  useUpdateProfileUserMutation
} = injectedRtkApi
