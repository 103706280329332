// @ts-nocheck
import React from 'react'
import { FC, useEffect, useContext } from 'react'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { ID, KTSVG } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { User } from '../../core/_models'
import { useAuth as useAuthoriz } from '../../../../context/Auth'
import { remove } from '../../../../lib/fetch/accountBalances'
import { useAlert } from 'react-alert'
import { useIntl } from 'react-intl'

type Props = {
  user: User
}

const EstrattiContoBackofficeActionsCell: FC<Props> = ({ user }) => {
  const [auth = {}] = useAuthoriz()
  const { token }: any = auth
  const alert = useAlert()
  const intl = useIntl()
  const fetchBalances = useContext(fetchBalancesContext)
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  async function onDeleteBalance(id: ID) {
    const response = await remove({ token, id })

    if (respose.status !== 200) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    fetchBalances()
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Link
          to={`/estratto-conto/estratti-conto/dettaglio-estratto/${user.id}`}>
          <button
            type="button"
            className="btn btn-light-primary me-3"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end">
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-2"
            />
            Visualizza
          </button>
        </Link>
        <button
          type="button"
          className="btn btn-light-primary me-3"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-users-table-filter="delete_row"
          data-bs-toggle="modal"
          data-bs-target={`#kt_modal_2` + user.id}>
          <KTSVG
            path="/media/icons/duotune/general/gen027.svg"
            className="svg-icon-2"
          />
          Elimina
        </button>
      </div>
      <div className="modal fade" tabIndex={-1} id={`kt_modal_2` + user.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Elimina estratto conto</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>
                Sei sicuro di voler eliminare l'estratto conto{' '}
                <strong>
                  {user.month} {user.year}
                </strong>
                ?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  onDeleteBalance(user.id)
                }}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { EstrattiContoBackofficeActionsCell }
