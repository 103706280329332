// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useForm, FormProvider } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../context/Auth'
import { saveLabel, getLabel, list } from '../../../lib/fetch/roles'
//import { useLoading } from '../../../context/LoadingContext';
import { useParams } from 'react-router-dom'
import ModificaRuoloPresentational from './ModificaRuoloPresentational'

function ModificaRuolo({ currentPermissions }) {
  const [auth = {}] = useAuth()
  //const { showLoading, hideLoading } = useLoading();
  const { token } = auth
  const formContext = useForm()
  const { id } = useParams()
  const [role, setRole] = useState(null)
  const [roles, setRoles] = useState([])
  const intl = useIntl()
  const alert = useAlert()

  async function fetchRole() {
    //showLoading();
    const { error, data } = await getLabel({
      id,
      token
    })

    //hideLoading();
    if (error) {
      setRole(null)
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    const { roleLabel = {} } = data
    setRole(roleLabel)
  }

  const fetchRoles = async () => {
    const { error, data } = await list(token)

    if (error) {
      setRoles([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    setRoles(data)
  }

  async function onSubmit(values) {
    const { name, roleId } = values

    //showLoading();
    const { error } = await saveLabel({
      id,
      data: {
        name,
        roleId
      },
      token
    })

    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    fetchRole()
  }

  useEffect(() => {
    if (!role) return
    formContext.reset({
      name: role.name,
      roleId: role.roles.id
    })
  }, [role])

  useEffect(() => {
    fetchRole()
  }, [id])

  useEffect(() => {
    fetchRoles()
  }, [id])

  useEffect(() => {
    document.title = 'Control Room - Modifica Ruolo'
  }, [])

  return (
    <FormProvider {...formContext}>
      <ModificaRuoloPresentational
        currentPermissions={currentPermissions}
        role={role}
        roles={roles}
        onSubmit={onSubmit}
      />
    </FormProvider>
  )
}

export default ModificaRuolo
