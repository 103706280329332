import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import Moment from 'moment'
import { Country } from '../core/_models'
import {
  CreateCustomerDto,
  ResponseCustomerDto,
  UpdateCustomerApiArg
} from '../../../../store/api/customers'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required('Nome è richiesto'),
  lastName: Yup.string().required('Cognome è richiesto'),
  fiscalCode: Yup.string().required('Codice fiscale è richiesto'),
  email: Yup.string().email().required('Email è richiesto'),
  birthDate: Yup.string(),
  birthPlace: Yup.string(),
  company: Yup.string(),
  phone: Yup.string(),
  mobile: Yup.string(),
  fax: Yup.string(),
  iban: Yup.string(),
  country: Yup.string(),
  region: Yup.string(),
  province: Yup.string(),
  city: Yup.string(),
  address: Yup.string(),
  zipCode: Yup.string(),
  customerType: Yup.string(),
  job: Yup.string(),
  maritalStatus: Yup.string(),
  educationalQualification: Yup.string(),
  linkedin: Yup.string(),
  gender: Yup.string(),
  notes: Yup.string()
})

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  fiscalCode: '',
  email: '',
  birthDate: '',
  birthPlace: '',
  company: '',
  phone: '',
  mobile: '',
  fax: '',
  iban: '',
  country: '',
  region: '',
  province: '',
  city: '',
  address: '',
  zipCode: '',
  customerType: '',
  job: '',
  maritalStatus: '',
  educationalQualification: '',
  linkedin: '',
  gender: '',
  notes: ''
}

interface Props {
  customer?: ResponseCustomerDto
  loading: boolean
  onSubmit: ({ createCustomerDto, id }: Partial<UpdateCustomerApiArg>) => void
}

export function AnagraficaForm({ customer, loading, onSubmit }: Props) {
  const [countries, setCountries] = useState<Country[]>([])
  const [regions, setRegions] = useState<Country[]>([])
  const [provinces, setProvinces] = useState<Country[]>([])
  const [saved, setSaved] = useState(false)
  const formik = useFormik({
    initialValues: customer
      ? {
          ...customer,
          id: undefined
        }
      : defaultInitialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: values => {
      let newValues = Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v)
      )
      newValues.birthDate = Moment(newValues.birthDate).format(
        'YYYY-MM-DDTHH:mm:SS.000Z'
      )
      onSubmit({
        createCustomerDto: newValues as CreateCustomerDto,
        id: customer?.id
      })
    }
  })

  const getCountries = () => {
    var config = {
      headers: {
        'X-CSCAPI-KEY':
          'OVhvZHYxeGVTVVBoRHdpQ0pBNjJuMzJaa2xzMU9pdUVlWnhnY0l4Nw=='
      }
    }
    axios
      .get('https://api.countrystatecity.in/v1/countries', config)
      .then(res => {
        setCountries(res.data)
      })
  }

  const searchRegion = (e: any) => {
    formik.setFieldValue('country', e.target.value)
    var country = countries.find(x => x.name === e.target.value)?.iso2
    if (country) getRegions(country)
  }

  // const setDate = (e: string) => {
  //   formik.setFieldValue(
  //     'birthDate',
  //     Moment(new Date(e).setHours(0, 0, 0, 0)).format(
  //       'YYYY-MM-DDTHH:mm:SS.000Z'
  //     )
  //   )
  // }

  const getRegions = (country: string) => {
    var config = {
      headers: {
        'X-CSCAPI-KEY':
          'OVhvZHYxeGVTVVBoRHdpQ0pBNjJuMzJaa2xzMU9pdUVlWnhnY0l4Nw=='
      }
    }
    axios
      .get(
        `https://api.countrystatecity.in/v1/countries/${country}/states`,
        config
      )
      .then(res => {
        if (res.data && res.data.length > 0) {
          var reg = res.data.filter((x: Country) => {
            if (!/[a-zA-Z]/.test(x.iso2)) {
              return x
            }
          })
          if (reg.length > 0) setRegions(reg)
          else formik.setFieldValue('region', '')

          var prov = res.data.filter((x: Country) => {
            if (/[a-zA-Z]/.test(x.iso2)) {
              return x
            }
          })
          if (prov.length > 0) setProvinces(prov)
          else formik.setFieldValue('province', '')
        } else {
          formik.setFieldValue('region', '')
          formik.setFieldValue('province', '')
        }
      })
  }

  useEffect(() => {
    getCountries()
  }, [])

  useEffect(() => {
    if (!customer) return
    if (!countries || countries.length === 0) return
    const region = countries.find(e => e.name === customer.country)
    if (!region) return
    getRegions(region.iso2)
  }, [customer, countries])

  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">
              {customer ? 'Modifica cliente' : 'Nuovo cliente'}
            </h3>
          </div>
        </div>

        <div id="kt_account_profile_details">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <div className="card-body border-top p-9 pt-3">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Nome completo
                </label>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Nome"
                        {...formik.getFieldProps('firstName')}
                      />
                      {(formik.touched.firstName || saved) &&
                        formik.errors.firstName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.firstName}
                            </div>
                          </div>
                        )}
                    </div>

                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Cognome"
                        {...formik.getFieldProps('lastName')}
                      />
                      {(formik.touched.lastName || saved) &&
                        formik.errors.lastName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.lastName}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Codice fiscale
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Codice fiscale"
                    {...formik.getFieldProps('fiscalCode')}
                  />
                  {(formik.touched.fiscalCode || saved) &&
                    formik.errors.fiscalCode && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.fiscalCode}
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Email
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Email"
                    {...formik.getFieldProps('email')}
                  />
                  {(formik.touched.email || saved) && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Nascita
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input
                        type="date"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Data di nascita"
                        {...formik.getFieldProps('birthDate')}
                      />

                      {(formik.touched.birthDate || saved) &&
                        formik.errors.birthDate && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.birthDate}
                            </div>
                          </div>
                        )}
                    </div>

                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Luogo di nascita"
                        {...formik.getFieldProps('birthPlace')}
                      />
                      {(formik.touched.birthPlace || saved) &&
                        formik.errors.birthPlace && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.birthPlace}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Azienda
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Company name"
                    {...formik.getFieldProps('company')}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span>Telefono</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="tel"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="N. Telefono"
                    {...formik.getFieldProps('phone')}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span>Cellulare</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="tel"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="N. Cellulare"
                    {...formik.getFieldProps('mobile')}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span>Fax</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Fax"
                    {...formik.getFieldProps('fax')}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span>Iban</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Iban"
                    {...formik.getFieldProps('iban')}
                  />
                </div>
              </div>
              {countries && countries.length > 0 && (
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    Nazione
                  </label>

                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select form-select-solid form-select-lg fw-bold"
                      value={formik.values.country}
                      onChange={searchRegion}>
                      <option value="">Seleziona Nazione...</option>
                      {countries.map(x => {
                        return (
                          <option key={x.id} value={x.name}>
                            {x?.name}
                          </option>
                        )
                      })}
                    </select>
                    {(formik.touched.country || saved) &&
                      formik.errors.country && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.country}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
              {regions && regions.length > 0 && (
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <span>Regione</span>
                  </label>

                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select form-select-solid form-select-lg fw-bold"
                      {...formik.getFieldProps('region')}>
                      <option value="">Seleziona regione...</option>
                      {regions.map(region => {
                        return (
                          <option key={region.id} value={region?.name}>
                            {region?.name}
                          </option>
                        )
                      })}
                    </select>
                    {(formik.touched.region || saved) &&
                      formik.errors.region && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.region}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
              {provinces && provinces.length > 0 && (
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <span>Provincia</span>
                  </label>
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select form-select-solid form-select-lg fw-bold"
                      {...formik.getFieldProps('province')}>
                      <option value="">Seleziona Provincia...</option>
                      {provinces.map(x => {
                        return (
                          <option key={x.id} value={x.name}>
                            {x?.name}
                          </option>
                        )
                      })}
                    </select>
                    {(formik.touched.province || saved) &&
                      formik.errors.province && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.province}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Città
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Città"
                    {...formik.getFieldProps('city')}
                  />
                  {(formik.touched.city || saved) && formik.errors.city && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.city}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Indirizzo
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Indirizzo"
                    {...formik.getFieldProps('address')}
                  />
                  {(formik.touched.address || saved) &&
                    formik.errors.address && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.address}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  CAP
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="CAP"
                    {...formik.getFieldProps('zipCode')}
                  />
                  {(formik.touched.zipCode || saved) &&
                    formik.errors.zipCode && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.zipCode}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Tipologia cliente
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    className="form-select form-select-solid form-select-lg fw-bold"
                    {...formik.getFieldProps('customerType')}>
                    <option value="">Seleziona tipologia...</option>
                    <option value="Persona fisica"> Persona fisica</option>
                    <option value="Privato"> Privato </option>
                    <option value="Azienda"> Azienda </option>
                  </select>
                  {(formik.touched.customerType || saved) &&
                    formik.errors.customerType && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.customerType}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Titolo di studio
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Titolo di studio"
                    {...formik.getFieldProps('educationalQualification')}
                  />
                  {(formik.touched.educationalQualification || saved) &&
                    formik.errors.educationalQualification && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.educationalQualification}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Genere
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    className="form-select form-select-solid form-select-lg fw-bold"
                    {...formik.getFieldProps('gender')}>
                    <option value="">Seleziona genere...</option>
                    <option value="F"> F</option>
                    <option value="M"> M </option>
                    <option value="Other"> Altro </option>
                  </select>
                  {(formik.touched.gender || saved) && formik.errors.gender && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.gender}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Impiego
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Impiego"
                    {...formik.getFieldProps('job')}
                  />
                  {(formik.touched.job || saved) && formik.errors.job && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.job}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  Stato civile
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    className="form-select form-select-solid form-select-lg fw-bold"
                    {...formik.getFieldProps('maritalStatus')}>
                    <option value="">Seleziona stato civile...</option>
                    <option value="celibe/nubile"> Celibe / Nubile</option>
                    <option value="divorziato/divorziata">
                      {' '}
                      Divorziato / Divorziata{' '}
                    </option>
                    <option value="coniugato/coniugata">
                      {' '}
                      Coniugato / Coniugata{' '}
                    </option>
                    <option value="vedovo/vedova"> Vedovo / Vedova </option>
                  </select>
                  {(formik.touched.maritalStatus || saved) &&
                    formik.errors.maritalStatus && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.maritalStatus}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span>Profilo LinkedIn</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="LinkedIn"
                    {...formik.getFieldProps('linkedin')}
                  />
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  setSaved(true)
                }}
                disabled={loading}>
                {!loading && (customer ? 'Salva' : 'Crea')}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
