import React, { useEffect, useState } from 'react'
import { CardBody } from '@windmill/react-ui'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { BackOfficeTable } from './table/BackOfficeTable'
import { KTCard } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth/core/Auth'
import { removeEmptyFields } from '../../lib/helpers/formatters'

function BackofficeUsersTable({ currentPermissions }: any) {
  const intl = useIntl()
  const { handleSubmit, register, reset } = useForm()
  const canWrite = currentPermissions.can('write', 'admins')
  const { setCanWrite } = useAuth()
  const [filters, setFilters] = useState({})

  useEffect(() => {
    setCanWrite(canWrite)
  }, [])

  const handleApplyFilters = (filters: any) => {
    setFilters(removeEmptyFields(filters))
  }

  const handleClearFilters = () => {
    setFilters({})
    reset()
  }

  return (
    <>
      <KTCard className="mb-8">
        <CardBody>
          <p
            className="mb-4 font-semibold text-gray-500 text-lg"
            style={{ fontSize: '1.125rem', fontWeight: '600' }}>
            {intl.formatMessage({ id: 'COMMON.FILTERS' })}
          </p>
          <form onSubmit={handleSubmit(handleApplyFilters)}>
            <div
              className="grid gap-2 grid-cols-1 items-end"
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end'
              }}>
              <label>
                <span>{intl.formatMessage({ id: 'COMMON.FIRST_NAME' })}</span>
                <input
                  className="form-control form-control-lg mt-1"
                  {...register('first_name')}
                />
              </label>
              <label>
                <span>{intl.formatMessage({ id: 'COMMON.LAST_NAME' })}</span>
                <input
                  className="form-control form-control-lg mt-1"
                  {...register('last_name')}
                />
              </label>
              <label>
                <span>{intl.formatMessage({ id: 'COMMON.USERNAME' })}</span>
                <input
                  className="form-control form-control-lg mt-1"
                  {...register('username')}
                />
              </label>
              <label>
                <span>{intl.formatMessage({ id: 'COMMON.EMAIL' })}</span>
                <input
                  className="form-control form-control-lg mt-1"
                  {...register('email')}
                />
              </label>
              <div className="flex col-span-1 sm:col-span-2 md:col-span-4 xl:col-span-1 mt-5">
                <button type="submit" className="btn btn-primary fw-bold px-6">
                  {intl.formatMessage({ id: 'COMMON.FILTER' })}
                </button>
              </div>
              <div className="flex col-span-1 sm:col-span-2 md:col-span-4 xl:col-span-1 mt-5">
                <button
                  style={{ marginLeft: '5px' }}
                  className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                  onClick={() => {
                    reset()
                    handleClearFilters()
                  }}>
                  {intl.formatMessage({ id: 'COMMON.CLEAR_FILTER' })}
                </button>
              </div>
            </div>
          </form>
        </CardBody>
      </KTCard>
      <KTCard className="mb-8">
        <BackOfficeTable filters={filters} />
      </KTCard>
    </>
  )
}

export default BackofficeUsersTable
