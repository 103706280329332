import React from 'react'
import { useMemo } from 'react'
import { useQueryResponseLoading } from '../core/QueryResponseProvider'
import { estrattiContoColumns } from './columns/_columns'

const EstrattoContoSubnetTable = () => {
  const isLoading = useQueryResponseLoading()
  const columns = useMemo(() => estrattiContoColumns, [])

  return <></>
}

export { EstrattoContoSubnetTable }
