import { api } from './index'
export const addTagTypes = ['Policies'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      findAll: build.query<FindAllApiResponse, FindAllApiArg>({
        query: queryArg => ({
          url: `/api/policies`,
          params: { page: queryArg.page, limit: queryArg.limit }
        }),
        providesTags: ['Policies']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type FindAllApiResponse = /** status 200  */ PaginatedResponseDto & {
  items?: ResponsePolicyDto[]
}
export type FindAllApiArg = {
  page?: number
  limit?: number
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  meta: IPaginationMetaResponseDto
}
export type ResponseCapabilityDto = {
  id: number
  capability:
    | 'agencies'
    | 'statistics'
    | 'ranking'
    | 'roles'
    | 'policies'
    | 'workflows'
    | 'partners'
    | 'offers'
    | 'salesChannels'
    | 'statuses'
    | 'users@all'
    | 'users@agency'
    | 'customers@all'
    | 'customers@agency'
    | 'customers@mine'
    | 'customers|surveys'
    | 'contracts@all'
    | 'contracts@agency'
    | 'contracts@mine'
    | 'contracts|notes'
    | 'contracts|attachments'
    | 'notes'
    | 'admins'
    | 'fiscalPositions'
    | 'fiscalPositions|prospectus'
    | 'settings'
    | 'balances'
    | 'privacy'
  permissions: number
}
export type ResponsePolicyDto = {
  id: number
  name: string
  description: string
  capabilities: ResponseCapabilityDto[]
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export const {
  useFindAllQuery: useGetAllPoliciesQuery,
  useLazyFindAllQuery: useLazyGetAllPoliciesQuery
} = injectedRtkApi
