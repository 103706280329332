import axios from 'axios'
import { Metric } from './statistics'
import { ResponseUserSummaryDto } from '../../store/api/users'

export const list = async ({
  startDate,
  endDate,
  salesChannels,
  metrics
}: {
  startDate: string
  endDate: string
  salesChannels?: number[]
  metrics?: Metric
}): Promise<RankingResponse | { error: Error }> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/statistics/contracts/ranking/users`,
      params: {
        startDate,
        endDate,
        salesChannels,
        metrics
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response && (response.data as RankingResponse)
  } catch (error) {
    // Sentry TODO
    return { error: error as Error }
  }
}

export interface RankingResponse {
  ranking: RankingItem[]
}

export interface RankingItem {
  user: ResponseUserSummaryDto
  value: number
  rank: number
}
