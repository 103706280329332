// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useState } from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { useIntl } from 'react-intl'
import ElaborazioniDatiTable from '../../../components/Contratti/ElaborazioniDatiTable'
import DropFileUpload from '../../../components/Contratti/DropFileUpload'
import { ListCheckIcon } from '../../../icons'

function ElaborazioneDatiPresentational({
  currentPermissions,
  jobs,
  loading,
  onPageChange,
  totalResults,
  resultsPerPage,
  onDownloadJob,
  onUploadXls
}) {
  const canWrite = currentPermissions.can('write', 'contracts')
  const intl = useIntl()
  const [showUpload, setShowUpload] = useState(false)

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="flex items-center justify-between">
          <PageTitle>
            {intl.formatMessage({ id: 'ELABORAZIONE_DATI.TITLE' })}
          </PageTitle>
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base">
            {canWrite && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowUpload(true)}>
                <ListCheckIcon
                  className="w-4 h-4 "
                  style={{ marginRight: '0.5rem', marginTop: '-0.2rem' }}
                />
                {intl.formatMessage({ id: 'ELABORAZIONE_DATI.NEW_JOB' })}
              </button>
            )}
          </div>
        </div>
      </div>

      {showUpload && (
        <DropFileUpload
          onUpload={file => {
            onUploadXls(file)
            setShowUpload(false)
          }}
        />
      )}

      <ElaborazioniDatiTable
        currentPermissions={currentPermissions}
        jobs={jobs}
        loading={loading}
        totalResults={totalResults}
        resultsPerPage={resultsPerPage}
        onPageChange={onPageChange}
        onDownloadJob={onDownloadJob}
      />
    </>
  )
}

export default ElaborazioneDatiPresentational
