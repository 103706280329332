import React, { useCallback } from 'react'
import { useMemo } from 'react'
import { offerteArticoliColumns } from './columns/_columns'
import Offer from '../../../../interfaces/Offer'
import GenericTable from '../../../../components/GenericTable'
import { useAuth } from '../../../../context/Auth'
import {
  PaginatedResponse,
  PaginationMeta
} from '../../../../interfaces/PaginatedResponse'
import { list } from '../../../../lib/fetch/offers'

interface OfferteArticoliTableProps {
  filters: any
}

const OfferteArticoliTable = ({ filters }: OfferteArticoliTableProps) => {
  const columns = useMemo(() => offerteArticoliColumns, [])
  const [{ token }] = useAuth()
  const fetch = useCallback(
    async (
      paginationMeta: PaginationMeta
    ): Promise<PaginatedResponse<Offer> | string> => {
      let result
      try {
        result = await list({
          token,
          page: paginationMeta.currentPage,
          limit: paginationMeta.itemsPerPage,
          ...filters
        })
      } catch (error) {
        let message = 'Error'
        if (error instanceof Error) {
          message = error.message
        }
        result = message
      }
      return result
    },
    [filters, token]
  )

  return <GenericTable columns={columns} onFetch={fetch} />
}

export { OfferteArticoliTable }
