import { CardBody } from '@windmill/react-ui'
import React from 'react'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { useQueryResponse } from '../../core/QueryResponseProvider'

export interface Filters {
  first_name: string
  last_name: string
  username: string
  email: string
}

type UsersListFilterProps = {
  onFilter: () => void
  onResetFilters: () => void
  onFiltersChange: (filters: Filters) => void
  filters?: Filters
}

const UsersListFilter = ({
  onFilter,
  onResetFilters,
  onFiltersChange,
  filters
}: UsersListFilterProps) => {
  const { isLoading } = useQueryResponse()

  const intl = useIntl()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <div className="separator border-gray-200" />
      <CardBody>
        <p
          className="mb-4 font-semibold text-gray-500 text-lg"
          style={{ fontSize: '1.125rem', fontWeight: '600' }}>
          {intl.formatMessage({ id: 'COMMON.FILTERS' })}
        </p>
        <div
          className="grid gap-2 grid-cols-1 items-end"
          style={{ display: 'flex', width: '100%', alignItems: 'flex-end' }}
          data-kt-user-table-filter="form">
          <label>
            <span>{intl.formatMessage({ id: 'COMMON.FIRST_NAME' })}</span>
            <input
              type="text"
              data-kt-user-table-filter="first_name"
              className="form-control form-control-lg mt-1"
              placeholder=" "
              value={filters?.first_name}
              onChange={e =>
                filters &&
                onFiltersChange({ ...filters, first_name: e.target.value })
              }
            />
          </label>
          <label>
            <span>{intl.formatMessage({ id: 'COMMON.LAST_NAME' })}</span>
            <input
              type="text"
              data-kt-user-table-filter="last_name"
              className="form-control form-control-lg mt-1"
              placeholder=" "
              value={filters?.last_name}
              onChange={e =>
                filters &&
                onFiltersChange({ ...filters, last_name: e.target.value })
              }
            />
          </label>
          <label>
            <span>{intl.formatMessage({ id: 'COMMON.USERNAME' })}</span>
            <input
              type="text"
              data-kt-user-table-filter="username"
              className="form-control form-control-lg mt-1"
              placeholder=" "
              value={filters?.username}
              onChange={e =>
                filters &&
                onFiltersChange({ ...filters, username: e.target.value })
              }
            />
          </label>
          <label>
            <span>{intl.formatMessage({ id: 'COMMON.EMAIL' })}</span>
            <input
              type="text"
              data-kt-user-table-filter="email"
              className="form-control form-control-lg mt-1"
              placeholder=" "
              value={filters?.email}
              onChange={e =>
                filters &&
                onFiltersChange({ ...filters, email: e.target.value })
              }
            />
          </label>
          {/* begin::Actions */}
          <div className="flex mt-5">
            <button
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
              type="button"
              disabled={isLoading}
              onClick={onResetFilters}
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="reset">
              Cancella
            </button>
            <button
              disabled={isLoading}
              type="button"
              onClick={onFilter}
              style={{ marginLeft: '5px' }}
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter">
              Applica
            </button>
          </div>
        </div>
      </CardBody>
      <div className="separator border-gray-200" />
    </>
  )
}

export { UsersListFilter }
