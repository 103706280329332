import { Fragment, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAuth } from '../../../../context/Auth'
import { User } from '../../../../interfaces/User'
import { Agency } from '../../../../interfaces/Agecies'
import { getAgencyUsers as getAgencyUsersQuery } from '../../../../lib/fetch/agencies'
import { selectedAgencyOrUser } from '../../../../interfaces/SelectedAgencyOrUser'

interface Props {
  agencies?: Agency[]
  onSelectedObject: (
    selectedAgencyOrUser: selectedAgencyOrUser | undefined
  ) => void
  selectedObject?: selectedAgencyOrUser
}

export const AgencyFilters = ({
  agencies,
  onSelectedObject,
  selectedObject
}: Props) => {
  // @ts-ignore
  const [{ token } = {}] = useAuth()
  const [openAgency, setOpenAgency] = useState<number>()
  const [data, setData] = useState<Map<number, User[]>>(new Map())

  const fetchAgencyUsers = async (agencyId: number) => {
    // @ts-ignore
    const { error, data } = await getAgencyUsersQuery({
      token: token,
      id: agencyId
    })
    if (error) {
      return
    }
    return data
  }

  const getAgencyUsers = async (agencyId: number) => {
    if (data.has(agencyId)) {
      return data.get(agencyId)
    }
    const users = await fetchAgencyUsers(agencyId)
    if (users) {
      const userData = users.items
      const newData = new Map(data.set(agencyId, userData))
      setData(newData)
    }
  }

  const handleObjectClick = ({ object, type }: selectedAgencyOrUser) => {
    if (
      selectedObject?.type === type &&
      selectedObject?.object?.id === object?.id
    ) {
      onSelectedObject(undefined)
      return
    }
    onSelectedObject({ object, type })
  }

  return (
    <>
      {agencies &&
        agencies.map(agency => (
          <Fragment key={agency.id}>
            <div
              className="col-12 mb-1"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <span
                onClick={() => {
                  openAgency === agency.id
                    ? setOpenAgency(undefined)
                    : setOpenAgency(agency.id)
                  getAgencyUsers(agency.id)
                }}
                className={
                  'transition duration-200 ease-in-out w-4 h-4 mr-5 flex items-center justify-center' +
                  ' ' +
                  (openAgency === agency.id
                    ? 'transform rotate-90'
                    : 'transform rotate-0')
                }
                style={{ transformOrigin: 'center' }}>
                <KTSVG
                  path="/media/icons/duotune/arrows/arr071.svg"
                  className="svg-icon-1"
                />
              </span>
              <span
                className={
                  'inline-flex items-center cursor-pointer' +
                  ' ' +
                  (selectedObject?.type === 'agency' &&
                  selectedObject?.object?.id === agency.id
                    ? 'text-primary'
                    : 'text-gray-700')
                }
                onClick={() =>
                  handleObjectClick({
                    object: agency,
                    type: 'agency'
                  })
                }>
                {agency.name}
              </span>
            </div>
            {openAgency === agency.id && data.has(agency.id) && (
              <div className="col-12 ml-5">
                {data.get(agency.id)?.map((user: any) => (
                  <div
                    key={user.id}
                    className="col-12 mb-1"
                    style={{ display: 'flex' }}>
                    <span className="inline-flex items-center">
                      <KTSVG
                        path="/media/icons/duotune/communication/com006.svg"
                        className="svg-icon-1"
                      />
                      <span
                        className={
                          'cursor-pointer ml-1' +
                          ' ' +
                          (selectedObject?.type === 'user' &&
                          selectedObject?.object?.id === user.id
                            ? 'text-primary'
                            : 'text-gray-700')
                        }
                        onClick={() =>
                          handleObjectClick({
                            object: user,
                            type: 'user'
                          })
                        }>
                        {user.firstName} {user.lastName}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            )}
          </Fragment>
        ))}
    </>
  )
}
