import React from 'react'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'
import useLogin from '../../../../app/hooks/useLogin'

const HeaderUserMenu: FC = () => {
  const { logout, user } = useLogin()
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-225px"
      data-kt-menu="true">
      <div className="menu-item px-3 ml-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user?.firstName} {user?.firstName}
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                {user?.status ? 'Attivo' : 'Disattivo'}
              </span>
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2" />

      {false && (
        <>
          <div className="menu-item px-5">
            <Link
              to={'/crafted/pages/profile/overview/' + user?.id}
              className="menu-link px-5">
              Profilo
            </Link>
          </div>

          <div className="menu-item px-5">
            <Link
              to={'/crafted/pages/profile/contracts/' + user?.id}
              className="menu-link px-5">
              Contratti
            </Link>
          </div>

          <div className="separator my-2" />

          {/* <Languages /> Implements multilanguage*/}

          <div className="menu-item px-5 my-1">
            <Link
              to={'/crafted/pages/profile/edit/' + user?.id}
              className="menu-link px-5">
              Modifica account
            </Link>
          </div>
        </>
      )}

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
