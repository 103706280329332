// @ts-nocheck
import React from 'react'
import { Column } from 'react-table'
import { UserCustomHeader } from './UserCustomHeader'
import Moment from 'moment'

const elaborazioneDatiColumns: ReadonlyArray<Column<any>> = [
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Data elaborazione"
        className="min-w-125px"
      />
    ),
    accessor: 'created_at',
    Cell: ({ ...props }) =>
      Moment(props.data[props.row.index]?.created_at).format('DD-MM-YYYY HH:mm')
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Stato"
        className="min-w-125px"
      />
    ),
    accessor: 'status'
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Documento"
        className="min-w-125px"
      />
    ),
    accessor: 'original_filename',
    Cell: ({ ...props }) => {
      return (
        <span className="text-sm">
          {(props.data[props.row.index]?.status === 'done' && (
            <span
              onClick={() => onDownloadJob(id)}
              className="text-blue-600 hover:text-blue-800 light:hover:text-blue-200 cursor-pointer">
              {props.data[props.row.index]?.original_filename}
            </span>
          )) ||
            '-'}
        </span>
      )
    }
  }
]

export { elaborazioneDatiColumns }
