import React from 'react'
import PageTitle from '../../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { NewUserForm } from '../../../../components/GestioneUtenze/NewUserForm'
import {
  CreateUserDto,
  useCreateUserMutation
} from '../../../../store/api/users'

function NuovoAdminPresentational() {
  const intl = useIntl()
  const history = useNavigate()

  const [createAdmin, { isLoading }] = useCreateUserMutation()

  const handleCreateAdmin = (values: CreateUserDto) => {
    createAdmin(values)
  }

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({ id: 'CREATE_ADMIN.TITLE' })}
        </PageTitle>
      </div>
      <NewUserForm
        onCreate={handleCreateAdmin}
        type="create"
        loading={isLoading}
        isBackOffice={true}
      />
    </>
  )
}

export default NuovoAdminPresentational
