// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../context/Auth'
import { useLoading } from '../../../context/LoadingContext'
import { create } from '../../../lib/fetch/accountBalances'
import NuovoEstrattoPresentational from './NuovoEstrattoPresentational'
import { useIntl } from 'react-intl'

function NuovoEstratto({ currentPermissions, location }) {
  const { can, isAdmin } = currentPermissions
  const canWrite = can('write', 'balances')
  const { showLoading, hideLoading } = useLoading()
  const alert = useAlert()
  const [auth = {}] = useAuth()
  const { token } = auth
  const intl = useIntl()

  async function onSubmit(values) {
    alert.show(intl.formatMessage({ id: 'COMMON.WAIT' }))

    const { month, year } = values

    const response = await create({
      data: {
        month,
        year: Number(year)
      },
      token
    })
    if (response.status !== 200) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
  }

  useEffect(() => {
    document.title = 'Control Room - Nuovo estratto'
  }, [])

  if (!canWrite) {
    let pathname = '/'
    if (location && location.pathname) {
      pathname = location.pathname.split('/')
      pathname.pop()
      pathname = pathname.join('/')
    }
    return <Navigate push={false} to={{ pathname }} />
  }

  return <NuovoEstrattoPresentational onSubmit={onSubmit} />
}

export default NuovoEstratto
