import { ID, Response } from '../../../../../_metronic/helpers'
import { SalesChannels } from '../../../../components/GestioneUtenze/core/_models'

export type CustomSurvey = {
  annual_income_worker?: number
  car_deadline?: string
  car_insurance?: boolean
  car_license_plate?: string
  car_price?: number
  family_members?: number
  family_members_dependent?: number
  gas_electricity_management_company?: string
  gas_electricity_management_house?: string
  id?: ID
  is_dependent_worker?: boolean
  is_self_employed_worker?: boolean
  is_worker?: boolean
  job_start_date?: string
  legal_consultation_number?: Array<string>
  loan_company_amount?: number
  loan_company_date?: string
  loan_company_institutes?: string
  loan_personal_amount?: number
  loan_personal_date?: string
  loan_personal_institutes?: string
  loan_requested?: boolean
  loan_requested_company?: boolean
  loan_requested_personal?: boolean
  major_social_networks?: boolean
  major_social_networks_kinds?: Array<string>
  mean_deadline?: string
  mean_insurance?: boolean
  mean_license_plate?: string
  mean_price?: number
  motorcycle_deadline?: string
  motorcycle_insurance?: boolean
  motorcycle_license_plate?: string
  motorcycle_price?: number
  next_vacation?: Array<string>
  online_business?: boolean
  online_business_kinds?: Array<string>
  other_insurance?: boolean
  other_insurance_kinds?: Array<string>
  owns_property?: boolean
  pay_tv_kinds?: Array<string>
  pay_tv_subscription?: boolean
  phone_wifi_management_company?: string
  phone_wifi_management_house?: string
  properties?: Array<string>
  properties_insured?: boolean
  social_bonuses_required?: Array<string>
  travel_booking_method?: Array<string>
  trip_per_years_vacation?: boolean
  trip_per_years_vacation_kinds?: Array<string>
  trip_per_years_work?: boolean
  trip_per_years_work_kinds?: Array<string>
  trusted_lawyer?: boolean
  vehicle_insurance?: boolean
}

export type Privacy = {
  id?: ID
  marketing_personal_data?: boolean
  profiling_personal_data?: boolean
  external_personal_data?: boolean
}
export type Customer = {
  id?: ID
  firstName?: string
  fiscalCode?: string
  lastName?: string
  company?: string
  birthDate?: string
  birthPlace?: string
  phone?: string
  mobile?: string
  fax?: string
  iban?: string
  email?: string
  country?: string
  region?: string
  province?: string
  city?: string
  address?: string
  zipCode?: string
  notes?: string
  job?: string
  customerType?: string
  maritalStatus?: string
  educationalQualification?: string
  linkedin?: string
  gender?: string

  customersSurvey?: Array<CustomSurvey>
  customersPrivacy?: Array<Privacy>
}

export type User = {
  id?: ID
  first_name?: string
  fiscal_code?: string
  last_name?: string
  company?: string
  birth_date?: string
  birth_place?: string
  phone?: string
  mobile?: string
  fax?: string
  iban?: string
  email?: string
  country?: string
  region?: string
  province?: string
  city?: string
  address?: string
  zip_code?: string
  notes?: string
  customersSurvey?: Array<CustomSurvey>
  customersPrivacy?: Array<Privacy>
}

export type Admin = {
  id?: ID
  adminsToPolicies: Array<any>
  created_at: string
  email: string
  first_name: string
  isAdmin: boolean
  last_name: string
  plain_text_password: string
  status: boolean
  username: string
}
export type ContractAttachment = {
  created_at: string
  deleted: boolean
  description: string
  id: ID
  key: string
  original_filename: string
  url: string
}

export type Note = {
  created_at: string
  description: string
  id: ID
}

export type Partner = {
  id: ID
  name: string
  status: boolean
  image: string
  created_at: string
}

export type Offer = {
  business: boolean
  created_at: string
  id: ID
  name: string
  partners: Partner
  salesChannel: SalesChannels
  status: boolean
  value: string
}

export type WorkFlow = {
  id: ID
  name: string
}

export type Status = {
  description: string
  id: ID
  name: string
  workflows: WorkFlow
}

export type Contract = {
  activationDate: string
  alreadyBalanced: boolean
  alreadyStarling: boolean
  code: string
  contractAttachments: Array<ContractAttachment>
  contractDate: string
  createdAt: string
  customers: Customer
  deactivationDate: string
  id: ID
  notes: Array<Note>
  offer: Offer
  podPdr: string
  statuses: Status
  updatedAt: string
  users: User
}

export type Country = {
  id: ID
  iso2: string
  name: string
}

export type UsersQueryResponse = Response<Array<Customer>>

export const initialUser: Customer = {
  firstName: '',
  lastName: '',
  fiscalCode: '',
  customersSurvey: [],
  customersPrivacy: [],
  email: '',
  birthDate: undefined
}

export const initialQuestionary: CustomSurvey = {
  annual_income_worker: undefined,
  car_deadline: '',
  car_insurance: false,
  car_license_plate: '',
  car_price: undefined,
  family_members: undefined,
  family_members_dependent: undefined,
  gas_electricity_management_company: '',
  gas_electricity_management_house: '',
  is_dependent_worker: false,
  is_self_employed_worker: false,
  is_worker: false,
  job_start_date: '',
  legal_consultation_number: [],
  loan_company_amount: undefined,
  loan_company_date: '',
  loan_company_institutes: '',
  loan_personal_amount: undefined,
  loan_personal_date: '',
  loan_personal_institutes: '',
  loan_requested: false,
  loan_requested_company: false,
  loan_requested_personal: false,
  major_social_networks: false,
  major_social_networks_kinds: [],
  mean_deadline: '',
  mean_insurance: false,
  mean_license_plate: '',
  mean_price: undefined,
  motorcycle_deadline: '',
  motorcycle_insurance: false,
  motorcycle_license_plate: '',
  motorcycle_price: undefined,
  next_vacation: [],
  online_business: false,
  online_business_kinds: [],
  other_insurance: false,
  other_insurance_kinds: [],
  owns_property: false,
  pay_tv_kinds: [],
  pay_tv_subscription: false,
  phone_wifi_management_company: '',
  phone_wifi_management_house: '',
  properties: [],
  properties_insured: false,
  social_bonuses_required: [],
  travel_booking_method: [],
  trip_per_years_vacation: false,
  trip_per_years_vacation_kinds: [],
  trip_per_years_work: false,
  trip_per_years_work_kinds: [],
  trusted_lawyer: false,
  vehicle_insurance: false
}

export const initialPrivacy: Privacy = {
  marketing_personal_data: false,
  profiling_personal_data: false,
  external_personal_data: false
}
