import { api } from './index'
export const addTagTypes = ['Contracts', 'Notes'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      createContract: build.mutation<
        CreateContractApiResponse,
        CreateContractApiArg
      >({
        query: queryArg => ({
          url: `/api/contracts`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Contracts']
      }),
      getContractsList: build.query<
        GetContractsListApiResponse,
        GetContractsListApiArg
      >({
        query: queryArg => ({
          url: `/api/contracts`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            user: queryArg.user,
            customer: queryArg.customer,
            offer: queryArg.offer,
            salesChannel: queryArg.salesChannel,
            partner: queryArg.partner,
            status: queryArg.status,
            created_at_from: queryArg.createdAtFrom,
            created_at_to: queryArg.createdAtTo,
            contract_date_from: queryArg.contractDateFrom,
            contract_date_to: queryArg.contractDateTo,
            user_business_name: queryArg.userBusinessName,
            code: queryArg.code,
            pod_pdr: queryArg.podPdr,
            ordering: queryArg.ordering,
            sort: queryArg.sort,
            csv: queryArg.csv,
            xls: queryArg.xls
          }
        }),
        providesTags: ['Contracts']
      }),
      getContract: build.query<GetContractApiResponse, GetContractApiArg>({
        query: queryArg => ({ url: `/api/contracts/${queryArg}` }),
        providesTags: ['Contracts']
      }),
      deleteContract: build.mutation<
        DeleteContractApiResponse,
        DeleteContractApiArg
      >({
        query: queryArg => ({
          url: `/api/contracts/${queryArg}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Contracts']
      }),
      updateContract: build.mutation<
        UpdateContractApiResponse,
        UpdateContractApiArg
      >({
        query: queryArg => ({
          url: `/api/contracts/${queryArg.contractId}`,
          method: 'PUT',
          body: queryArg.updateContractDto
        }),
        invalidatesTags: ['Contracts']
      }),
      uploadContractAttachment: build.mutation<
        UploadContractAttachmentApiResponse,
        UploadContractAttachmentApiArg
      >({
        query: ({ contractId, file }) => {
          const data = new FormData()
          data.append('file', file)

          return {
            url: `/api/contracts/${contractId}/attachments`,
            method: 'POST',
            data,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        },
        invalidatesTags: ['Contracts']
      }),
      getContractSignedAttachment: build.query<
        GetContractSignedAttachmentApiResponse,
        GetContractSignedAttachmentApiArg
      >({
        query: queryArg => ({
          url: `/api/contracts/${queryArg.contractId}/attachments/${queryArg.attachmentId}`
        }),
        providesTags: ['Contracts']
      }),
      deleteContractAttachment: build.mutation<
        DeleteContractAttachmentApiResponse,
        DeleteContractAttachmentApiArg
      >({
        query: queryArg => ({
          url: `/api/contracts/${queryArg.contractId}/attachments/${queryArg.attachmentId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Contracts']
      }),
      createContractNote: build.mutation<
        CreateContractNoteApiResponse,
        CreateContractNoteApiArg
      >({
        query: queryArg => ({
          url: `/api/contracts/${queryArg.contractId}/notes`,
          method: 'POST',
          body: queryArg.createNoteDto
        }),
        invalidatesTags: ['Contracts', 'Notes']
      }),
      deleteContractNote: build.mutation<
        DeleteContractNoteApiResponse,
        DeleteContractNoteApiArg
      >({
        query: queryArg => ({
          url: `/api/contracts/${queryArg.contractId}/notes/${queryArg.noteId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Contracts', 'Notes']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type CreateContractApiResponse = /** status 200  */ ResponseContractDto
export type CreateContractApiArg = CreateContractDto
export type GetContractsListApiResponse =
  /** status 200  */
  PaginatedResponseDto & {
    items: ResponseContractDto[]
  }
export type GetContractsListApiArg = {
  page?: number
  limit?: number
  user?: number[]
  customer?: number[]
  offer?: number[]
  salesChannel?: number[]
  partner?: number[]
  status?: number[]
  createdAtFrom?: string
  createdAtTo?: string
  contractDateFrom?: string
  contractDateTo?: string
  userBusinessName?: string
  code?: string
  podPdr?: string
  ordering?: string
  sort?: string
  csv?: boolean
  xls?: boolean
}
export type GetContractApiResponse = /** status 200  */ ResponseContractDto
export type GetContractApiArg = number
export type DeleteContractApiResponse = unknown
export type DeleteContractApiArg = number
export type UpdateContractApiResponse = /** status 200  */ ResponseContractDto
export type UpdateContractApiArg = {
  contractId: number
  updateContractDto: UpdateContractDto
}
export type UploadContractAttachmentApiResponse =
  /** status 200  */ ResponseContractAttachmentDto
export type UploadContractAttachmentApiArg = {
  contractId: number
  file: File
}
export type GetContractSignedAttachmentApiResponse =
  /** status 200  */ ResponseContractAttachmentDto
export type GetContractSignedAttachmentApiArg = {
  contractId: number
  attachmentId: number
}
export type DeleteContractAttachmentApiResponse = unknown
export type DeleteContractAttachmentApiArg = {
  contractId: number
  attachmentId: number
}
export type CreateContractNoteApiResponse =
  /** status 200  */ ResponseContractNoteDto
export type CreateContractNoteApiArg = {
  contractId: number
  createNoteDto: CreateNoteDto
}
export type DeleteContractNoteApiResponse = unknown
export type DeleteContractNoteApiArg = {
  contractId: number
  noteId: number
}
export type ResponseAgencyDto = {
  id: number
  name: string
  city?: string
  address?: string
  zip_code?: string
  phone?: string
}
export type ResponseUserSummaryDto = {
  id: number
  username: string
  firstName: string
  lastName: string
  fiscalCode?: string
  agency: ResponseAgencyDto
}
export type ResponseWorkflowDto = {
  id: number
  name: string
  isValidForStatistics: boolean
  isValidForTarget: boolean
  isValidForRanking: boolean
}
export type ResponseStatusDto = {
  id: number
  name: string
  description: string
  workflow: ResponseWorkflowDto
}
export type ResponsePartnerDto = {
  id: number
  name: string
  status: boolean
  image: string
}
export type ResponseSalesChannelDto = {
  id: number
  code: string
  description: string
  image: string
  rankingMetrics: string
  isRankingValueVisible: boolean
  isRankingVisible: boolean
}
export type ResponseOfferDto = {
  id: number
  name: string
  status: boolean
  business: boolean
  image: string
  value: number
  partner: ResponsePartnerDto
  salesChannel: ResponseSalesChannelDto
}
export type ResponseCustomerSummaryDto = {
  id: number
  fiscalCode: string
  firstName: string
  lastName: string
  company: string
}
export type ResponseContractAttachmentDto = {
  id: number
  original_filename: string
  url: string
  key: string
  deleted: boolean
  created_at: string
  description: string
}
export type ResponseContractNoteDto = {
  id: number
  description: string
  created_at: string
  user: ResponseUserSummaryDto
}
export type ResponseContractDto = {
  id: number
  contractDate: string
  activationDate: string
  deactivationDate: string
  code: string
  podPdr: string
  user: ResponseUserSummaryDto
  status: ResponseStatusDto
  offer: ResponseOfferDto
  customer: ResponseCustomerSummaryDto
  attachments: ResponseContractAttachmentDto[]
  notes: ResponseContractNoteDto[]
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type CreateContractDto = {
  code: string
  podPdr: string
  contractDate: string
  userId?: number
  statusId: number
  offerId: number
  customerId: number
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  items: string[]
  meta: IPaginationMetaResponseDto
}
export type UpdateContractDto = {
  code: string
  podPdr?: string
  contractDate?: string
  deactivationDate?: string
  activationDSate?: string
  userId?: number
  statusId?: number
  offerId?: number
  customerId?: number
}
export type CreateNoteDto = {
  description: string
}
export const {
  useCreateContractMutation,
  useGetContractsListQuery,
  useLazyGetContractsListQuery,
  useGetContractQuery,
  useLazyGetContractQuery,
  useDeleteContractMutation,
  useUpdateContractMutation,
  useUploadContractAttachmentMutation,
  useGetContractSignedAttachmentQuery,
  useLazyGetContractSignedAttachmentQuery,
  useDeleteContractAttachmentMutation,
  useCreateContractNoteMutation,
  useDeleteContractNoteMutation
} = injectedRtkApi
