import React, { useState } from 'react'
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Prospetto, User } from '../../core/_models'
import { getProspectus } from '../../../../../lib/fetch/fiscalPositions'
import { useAuth as useAuthoriz } from '../../../../../context/Auth'
import Divider from '@mui/material/Divider'
import { useAuth } from '../../../../../modules/auth'

type Props = {
  user: User
}

const PosizioniFiscaliActionsCell: FC<Props> = ({ user }) => {
  const [prospectusData, setProspectusData] = useState<Prospetto>()
  const [auth = {}] = useAuthoriz()
  const { token }: any = auth
  const { canWrite } = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  async function getProsp(id: any) {
    const data: any = await getProspectus({ id, token })
    if (data?.data?.status === 200) {
      setProspectusData(data.data.prospectus)
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <button
          type="button"
          className="btn btn-light-primary me-3"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-users-table-filter="edit_row"
          data-bs-toggle="modal"
          data-bs-target={'#kt_modal_1' + user.id}
          onClick={() => getProsp(user?.id)}>
          <KTSVG
            path="/media/icons/duotune/general/gen055.svg"
            className="svg-icon-2"
          />
          Preview pagamento
        </button>
      </div>
      <div className="modal fade" tabIndex={-1} id={'kt_modal_1' + user.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Auto Fattura (Preview)</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="row mt-2 mb-2">
                <div className="col">
                  <p>IMPONIBILE</p>
                  <p>IVA</p>
                </div>
                <div className="col">
                  <p>{prospectusData?.taxable} €</p>
                  <p>{prospectusData?.vat} €</p>
                </div>
              </div>
              <Divider style={{ borderColor: 'black' }} />
              <div className="row mt-2 mb-2">
                <div className="col">
                  <p>TOTALE LORDO</p>
                </div>
                <div className="col">
                  <p>{prospectusData?.gross} €</p>
                </div>
              </div>
              <Divider style={{ borderColor: 'black' }} />
              <div className="row mt-2 mb-2">
                <div className="col">
                  <p>ENASARCO</p>
                  <p>INPS</p>
                  <p>IRPEF</p>
                  <p>RIVALSA</p>
                </div>
                <div className="col">
                  <p>{prospectusData?.enasarco} €</p>
                  <p>{prospectusData?.inps} €</p>
                  <p>{prospectusData?.irpef} €</p>
                  <p>{prospectusData?.rivalsa} €</p>
                </div>
              </div>
              <Divider style={{ borderColor: 'black' }} />
              <div className="row mt-2 mb-2">
                <div className="col">
                  <p>NETTO A PAGARE</p>
                </div>
                <div className="col">
                  <p>{prospectusData?.net} €</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal">
                Chiudi
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PosizioniFiscaliActionsCell }
