// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect } from 'react'

import PageTitle from '../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { Navigate, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../context/Auth'
import { create } from '../../lib/fetch/salesChannels'
import { useLoading } from '../../context/LoadingContext'

function NuovoCanale({ currentPermissions, location }) {
  const canWrite = currentPermissions.can('write', 'salesChannels')
  const intl = useIntl()

  const alert = useAlert()
  const history = useNavigate()
  const [auth = {}] = useAuth()
  const { showLoading, hideLoading } = useLoading()
  const { token } = auth
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm()

  async function onSubmit(values) {
    const { code, description } = values

    showLoading()
    const { error } = await create({
      data: {
        code,
        description
      },
      token
    })

    hideLoading()
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    history(-1)
  }

  useEffect(() => {
    document.title = 'Control Room - Nuovo canale'
  }, [])

  if (!canWrite) {
    let pathname = '/'
    if (location && location.pathname) {
      pathname = location.pathname.split('/')
      pathname.pop()
      pathname = pathname.join('/')
    }
    return <Navigate push={false} to={{ pathname }} />
  }
  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({ id: 'CREATE_CHANNEL.TITLE' })}
        </PageTitle>
      </div>
      <div className="card mb-5 mb-xl-10">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="card-body pt-3">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.CODE' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="form-control form-control-lg form-control-solid mt-1"
                  type="text"
                  {...register('code', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                    maxLength: {
                      value: 20,
                      message: intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })
                    }
                  })}
                />
                {errors.code && errors.code.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.code.message}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.DESCRIPTION' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="form-control form-control-lg form-control-solid mt-1"
                  type="text"
                  {...register('description', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                    maxLength: {
                      value: 200,
                      //t('COMMON:MAX_LENGTH', { length: 200 })
                      message: intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })
                    }
                  })}
                />
                {errors.description && errors.description.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.description.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              {intl.formatMessage({ id: 'COMMON.SAVE' })}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default NuovoCanale
