import { DateTime } from 'luxon'

export const jsonReviver = (key: string, value: unknown): unknown => {
  if (value === null) return undefined
  switch (key) {
    case 'createdAt':
    case 'at':
    case 'date':
    case 'effectiveDate':
    case 'expirationDate':
    case 'birthDate':
    case 'notBefore':
    case 'notAfter':
    case 'claimedAt':
    case 'time':
    case 'timestamp':
    case 'lastUpdate':
    case 'lastRead':
      return DateTime.fromISO(value as string)
    case 'insertDateTimes':
      return (value as string[]).map(v => DateTime.fromISO(v))
  }
  return value
}

export default (data: string) => JSON.parse(data, jsonReviver) as unknown
