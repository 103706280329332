import axios from 'axios'
import { AdminPolicies, AuthModel, UserResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/me`
export const GET_POLICIES_BY_USER_URL = `${API_URL}/me/policies`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken() {
  return axios.get<UserResponse>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function getUserPolicies() {
  return axios.get<AdminPolicies>(GET_POLICIES_BY_USER_URL)
}
