// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../../context/Auth'
import { create } from '../../../../lib/fetch/admins'
import { useLoading } from '../../../../context/LoadingContext'
import NuovoAdminPresentational from './NuovoAdminPresentational'
import { useForm, FormProvider } from 'react-hook-form'
import { Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

function NuovoAdmin({ currentPermissions, location }) {
  const canWrite = currentPermissions.can('write', 'users')
  const alert = useAlert()
  const [auth = {}] = useAuth()
  const { showLoading, hideLoading } = useLoading()
  const { token } = auth
  const formContext = useForm()
  const intl = useIntl()

  async function onSubmit(values) {
    const { email, password, firstName, lastName } = values

    showLoading()
    const { error } = await create({
      data: {
        password,
        email,
        first_name: firstName,
        last_name: lastName
      },
      token
    })

    hideLoading()
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    formContext.reset()
  }

  useEffect(() => {
    document.title = 'Control Room - Nuovo Admin'
  }, [])

  if (!canWrite) {
    let pathname = '/'
    if (location && location.pathname) {
      pathname = location.pathname.split('/')
      pathname.pop()
      pathname = pathname.join('/')
    }
    return <Navigate push={false} to={{ pathname }} />
  }

  return (
    <FormProvider {...formContext}>
      <NuovoAdminPresentational currentPermissions={currentPermissions} />
    </FormProvider>
  )
}

export default NuovoAdmin
