import React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import ContractActionsCell from './ContractActionsCell'
import { ResponseContractDto } from '../../../../../store/api/contracts'

export default (
  refetch: () => void = () => {}
): ColumnDef<ResponseContractDto>[] => [
  {
    header: 'Data contratto',
    accessorKey: 'contractDate',
    cell: ({ cell: { getValue } }) =>
      new Date(getValue() as string).toLocaleDateString()
  },
  {
    header: 'Nome',
    accessorKey: 'customer.firstName'
  },
  {
    header: 'Cognome',
    accessorKey: 'customer.lastName'
  },
  {
    header: 'Codice fiscale / P.IVA',
    accessorKey: 'customer.fiscalCode'
  },
  {
    header: 'Ragione sociale',
    accessorKey: 'customer.company'
  },
  {
    header: 'Agente',
    accessorKey: 'user.username'
  },
  {
    header: 'Nome agente',
    accessorFn: row => `${row.user?.firstName} ${row.user?.lastName}`
  },
  {
    header: 'Sede',
    accessorKey: 'user.agency.name'
  },
  {
    header: 'Cod. contratto',
    accessorKey: 'podPdr'
  },
  {
    header: 'Cod. univoco',
    accessorKey: 'code'
  },
  {
    header: 'Offerta',
    accessorKey: 'offer.name'
  },
  {
    header: 'Esito',
    accessorKey: 'status.name'
  },
  {
    header: 'Azioni',
    cell: ({ row }) => (
      <ContractActionsCell contract={row.original} onDelete={refetch} />
    )
  }
]
