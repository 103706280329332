import { KTSVG } from '../../../../_metronic/helpers'
import { PaginationMeta } from '../../../interfaces/PaginatedResponse'

interface Props {
  paginationMeta: PaginationMeta
  onPaginationChange: (paginationMeta: PaginationMeta) => void
}

export default ({ paginationMeta, onPaginationChange }: Props) => {
  const handlePaginationChange = (paginationMeta: PaginationMeta) => {
    onPaginationChange(paginationMeta)
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            <li className="page-item">
              <button
                type="button"
                className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
                disabled={paginationMeta?.currentPage === 1 ?? true}
                onClick={() =>
                  handlePaginationChange({ ...paginationMeta, currentPage: 1 })
                }>
                <KTSVG
                  path="/media/icons/duotune/arrows/arr079.svg"
                  className="svg-icon-2"
                />
              </button>
            </li>
            <li className="page-item">
              <button
                type="button"
                className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
                disabled={paginationMeta?.currentPage === 1 ?? true}
                onClick={() =>
                  paginationMeta?.currentPage &&
                  handlePaginationChange({
                    ...paginationMeta,
                    currentPage: paginationMeta.currentPage - 1
                  })
                }>
                <KTSVG
                  path="/media/icons/duotune/arrows/arr074.svg"
                  className="svg-icon-2"
                />
              </button>
            </li>
            <li className="page-item">
              <button
                type="button"
                className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
                disabled={
                  paginationMeta?.currentPage === paginationMeta?.totalPages
                }
                onClick={() =>
                  paginationMeta?.currentPage &&
                  handlePaginationChange({
                    ...paginationMeta,
                    currentPage: paginationMeta.currentPage + 1
                  })
                }>
                <KTSVG
                  path="/media/icons/duotune/arrows/arr071.svg"
                  className="svg-icon-2"
                />
              </button>
            </li>
            <li className="page-item">
              <button
                type="button"
                className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
                disabled={
                  paginationMeta?.currentPage === paginationMeta?.totalPages
                }
                onClick={() =>
                  paginationMeta?.totalPages &&
                  handlePaginationChange({
                    ...paginationMeta,
                    currentPage: paginationMeta.totalPages
                  })
                }>
                <KTSVG
                  path="/media/icons/duotune/arrows/arr080.svg"
                  className="svg-icon-2"
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
