import {
  ResponseCustomerDto,
  UpdateCustomerApiArg,
  useUpdateCustomerMutation
} from '../../../../../../store/api/customers'
import { AnagraficaForm } from '../../../AnagraficaForm'

interface Props {
  customer: ResponseCustomerDto
}
export default function EditTab({ customer }: Props) {
  const [updateCustomer, { isLoading: loading }] = useUpdateCustomerMutation()

  const handleSubmit = ({
    id,
    createCustomerDto
  }: Partial<UpdateCustomerApiArg>) => {
    if (!id) return
    if (!createCustomerDto) return
    updateCustomer({ id, createCustomerDto })
  }
  return (
    <AnagraficaForm
      onSubmit={handleSubmit}
      customer={customer}
      loading={loading}
    />
  )
}
