import React, { useEffect, useState } from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { CardBody } from '@windmill/react-ui'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { KTCard } from '../../../../_metronic/helpers'
import { PosizioniFiscaliTable } from './table/PosizioniFiscaliTable'
import { useAuth } from '../../../modules/auth/core/Auth'
import { removeEmptyFields } from '../../../lib/helpers/formatters'

function PosizioniFiscaliPresentational({
  currentPermissions
}: {
  currentPermissions: any
}) {
  const intl = useIntl()
  const { setCanWrite } = useAuth()
  const { handleSubmit, register, reset } = useForm({})

  const [filters, setFilters] = useState({})

  function onSetFilters(filters: any) {
    setFilters(removeEmptyFields(filters))
  }
  useEffect(() => {
    setCanWrite(currentPermissions?.isAdmin)
  }, [])

  return (
    <>
      <div className="flex items-center justify-between">
        <PageTitle>
          {intl.formatMessage({ id: 'FISCAL_POSITIONS.TITLE' })}
        </PageTitle>
        {/* <div>
          {canWrite && (
            <Link to="/app/rete/posizioni-fiscali/nuova-posizione-fiscale">
              <Button>
                <PlusIcon className="w-4 h-4 mr-1" />
                {intl.formatMessage({id: 'COMMON.NEW'})}
              </Button>
            </Link>
          )}
        </div> */}
      </div>

      <KTCard className="mb-8 hidden">
        <CardBody>
          <p
            className="mb-4 font-semibold text-gray-500 text-lg"
            style={{ fontSize: '1.125rem', fontWeight: '600' }}>
            {intl.formatMessage({ id: 'COMMON.FILTERS' })}
          </p>

          <form onSubmit={handleSubmit(onSetFilters)}>
            <div
              className="grid gap-2 grid-cols-1 items-end"
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end'
              }}>
              <label>
                <span>{intl.formatMessage({ id: 'COMMON.NAME' })}</span>
                <input
                  className="form-control form-control-lg mt-1"
                  {...register('name')}
                />
              </label>
              <div className="flex mt-6">
                <button type="submit" className="btn btn-primary fw-bold px-6">
                  {intl.formatMessage({ id: 'COMMON.FILTER' })}
                </button>
              </div>
              <div className="flex mt-6">
                <button
                  style={{ marginLeft: '5px' }}
                  className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                  onClick={() => {
                    reset()
                  }}>
                  {intl.formatMessage({ id: 'COMMON.CLEAR_FILTER' })}
                </button>
              </div>
            </div>
          </form>
        </CardBody>
      </KTCard>
      <KTCard className="mb-8">
        <PosizioniFiscaliTable filters={filters} />
      </KTCard>
    </>
  )
}

export default PosizioniFiscaliPresentational
