import React from 'react'
import { SediActionsCell } from './QualificheActionsCell'
import { Sedi } from '../../core/_models'
import { ColumnDef } from '@tanstack/react-table'

const sediColumns = (refetch: () => void = () => {}): ColumnDef<Sedi>[] => [
  {
    header: 'Nome',
    accessorKey: 'name'
  },
  {
    header: 'Azioni',
    id: 'actions',
    cell: ({ row }) => (
      <SediActionsCell sede={row.original} onDelete={refetch} />
    )
  }
]

export { sediColumns }
