import { Dispatch, SetStateAction } from 'react'

export type ID = undefined | null | number

export type PaginationState = {
  page: number
  limit: 10 | 30 | 50 | 100
  links?: {
    label: string
    active: boolean
    url: string | null
    page: number | null
  }
}

export type Pagination = {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
}

export type ListPaginationState = {
  meta?: Pagination
  links?: { first: string; last: string; next: string; previous: string }
}

export type SortState = {
  sort?: string
  ordering?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Link = {
  first: string
  previous: string
  next: string
  last: string
}

export type Response<T> = {
  items?: T
  meta?: {
    totalItems: number
    itemCount: number
    itemsPerPage: number
    totalPages: number
    currentPage: number
  }
  links?: Link
}

export type QueryState = /*PaginationState & */ Pagination &
  SortState &
  FilterState &
  SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

/*export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
}
*/
export const initialQueryState: QueryState = {
  totalItems: 10,
  itemCount: 10,
  itemsPerPage: 10,
  totalPages: 1,
  currentPage: 1
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {}
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {
  refetch: () => {},
  isLoading: false,
  query: ''
}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false
}
