import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../_metronic/helpers'
import { User, UsersQueryResponse } from './_models'

//const API_URL = process.env.REACT_APP_THEME_API_URL
const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
const DOCUMENT_DOWNLOAD_URL = `${API_URL}/users/download/document`
const GET_USERS_URL = `${API_URL}/users`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.items)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.items)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.items)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${GET_USERS_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map(id => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const downloadDocument = (id: ID): Promise<any> => {
  return axios.get(`${DOCUMENT_DOWNLOAD_URL}/${id}`)
  /*.then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.items)*/
}

const saveUser = ({
  id,
  data
}: {
  id: ID
  data: any
}): Promise<User | undefined> => {
  return axios
    .put(`${GET_USERS_URL}/${id}`, data)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.items)
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  downloadDocument,
  saveUser
}
