import React from 'react'
import clsx from 'clsx'
import { FC, PropsWithChildren, useMemo } from 'react'
import { HeaderProps } from 'react-table'
import { initialQueryState } from '../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { User } from '../../core/_models'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<User>>
}
const UserCustomHeader: FC<Props> = ({ className, title, tableProps }) => {
  const id = tableProps.column.id
  const { state, updateState } = useQueryRequest()

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id
  }, [state, id])
  const ordering: 'asc' | 'desc' | undefined = useMemo(
    () => state.ordering,
    [state]
  )

  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === 'actions' || id === 'selection') {
      return
    }

    if (!isSelectedForSorting) {
      // enable sort asc
      updateState({ sort: id, ordering: 'asc', ...initialQueryState })
      return
    }

    if (isSelectedForSorting && ordering !== undefined) {
      if (ordering === 'asc') {
        // enable sort desc
        updateState({ sort: id, ordering: 'desc', ...initialQueryState })
        return
      }

      // disable sort
      updateState({
        sort: undefined,
        ordering: undefined,
        ...initialQueryState
      })
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting &&
          ordering !== undefined &&
          `table-sort-${ordering}`
      )}
      style={{ cursor: 'pointer' }}
      onClick={sortColumn}>
      {title}
    </th>
  )
}

export { UserCustomHeader }
