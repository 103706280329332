import { StatusCodes } from 'http-status-codes'
import { useEffect } from 'react'
import useSelector from '../../hooks/useSelector'
import { apiClient } from '../api/base/baseQuery'
import useDispatch from '../../hooks/useDispatch'
import { resetAuth } from '../auth'

export default () => {
  const token = useSelector(state => state.auth.token)
  const dispatch = useDispatch()

  useEffect(() => {
    const requestInterceptor = apiClient.interceptors.request.use(config => {
      if (token) config.headers.setAuthorization(`Bearer ${token}`)

      return config
    })

    return () => apiClient.interceptors.request.eject(requestInterceptor)
  }, [token])

  useEffect(() => {
    const requestInterceptor = apiClient.interceptors.response.use(response => {
      if (
        response.status === StatusCodes.UNAUTHORIZED &&
        response.config.headers.getAuthorization()
      )
        dispatch(resetAuth())
      return response
    })

    return () => apiClient.interceptors.request.eject(requestInterceptor)
  }, [token])

  return <></>
}
