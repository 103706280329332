// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import moment from 'moment'

export const formatDate = date =>
  date ? moment(date).format('DD-MM-YYYY') : ''

const { Intl } = window || {}
let locale

if (Intl && typeof Intl === 'object') {
  const { language } = navigator || {}
  locale = language
}

export const formatCurrency = (amount = 0) => {
  if (!locale) return amount
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR'
  }).format(amount)
}

export const removeEmptyFields = data => {
  const d = { ...data }
  Object.keys(d).forEach(key => {
    if (d[key] === '' || d[key] == null) {
      delete d[key]
    }
  })
  return d
}

export const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = function (error) {
      reject(error)
    }
  })
