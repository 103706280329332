import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import CustomerSelect from '../selects/CustomerSelect'
import { useNavigate } from 'react-router-dom'
import { AnagraficaForm } from '../../pages/Anagrafica/users-list/AnagraficaForm'
import {
  CreateCustomerApiArg,
  useCreateCustomerMutation
} from '../../store/api/customers'

function Step1Form({ onSubmit }: any) {
  const history = useNavigate()
  const intl = useIntl()
  const [createCustomer, { isLoading: loading, isSuccess, data }] =
    useCreateCustomerMutation()
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()

  const handleCreateCustomer = (createCustomerDto: CreateCustomerApiArg) => {
    if (!createCustomerDto) return
    createCustomer(createCustomerDto)
  }

  useEffect(() => {
    if (isSuccess && data) history(`/contracts/new/${data.id}`)
  }, [isSuccess, data])

  return (
    <>
      <KTCard className="mb-8">
        <KTCardBody>
          <p className="mb-4 font-semibold text-gray-500 text-lg">
            {intl.formatMessage({ id: 'NEW_CONTRACT.STEP1_TITLE' })}
          </p>
          <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-4 items-end">
            <label
              className="font-medium text-gray-500"
              style={{ minWidth: '200px' }}>
              <span className="font-medium text-gray-500">
                Ricerca anagrafica cliente
              </span>
              <CustomerSelect
                onChange={(_, o) =>
                  history(`/contracts/new/${o?.id}`, { replace: true })
                }
              />
            </label>
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className="mt-3">
        <KTCardBody>
          <AnagraficaForm
            onSubmit={({ createCustomerDto }) => {
              if (!createCustomerDto) return
              handleCreateCustomer(createCustomerDto)
            }}
            loading={loading}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default Step1Form
