// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { list as listPartners } from '../../../lib/fetch/partners'
import { list as listOffers } from '../../../lib/fetch/offers'
import { removeEmptyFields } from '../../../lib/helpers/formatters'
import { useAuth } from '../../../context/Auth'
import PianiCompensoPresentational from './PianiCompensoPresentational'
import { useIntl } from 'react-intl'

const resultsPerPage = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT || 10

function PianiCompenso({ currentPermissions }) {
  const [{ token } = {}] = useAuth()
  const alert = useAlert()
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({})
  const [data, setData] = useState([])
  const [partners, setPartners] = useState([])
  const [totalResults, setTotalResults] = useState(0)
  const [loading, setLoading] = useState(false)
  const [partnersLoading, setPartnersLoading] = useState(false)
  const intl = useIntl()

  async function fetchOffers() {
    setLoading(true)
    setData([])
    const { error, data = {} } = await listOffers({
      token,
      page: page,
      limit: resultsPerPage,
      sort: 'name',
      ordering: 'asc',
      ...filters
    })

    setLoading(false)
    if (error) {
      setTotalResults(0)
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    setTotalResults((data.meta && data.meta.totalItems) || 0)
    setData(data.items)
  }

  async function fetchPartners() {
    setPartnersLoading(true)
    const { error, data = {} } = await listPartners({
      token
    })

    if (error) {
      setPartnersLoading(false)
      setPartners([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    setPartnersLoading(false)
    setPartners(data.items)
  }

  function onPageChange(p) {
    setPage(p)
  }

  function onSetFilters(filters) {
    const { partner, ...others } = filters
    if (!partner) return setFilters(removeEmptyFields(others))
    setFilters(removeEmptyFields(filters))
  }

  function onClearFilters() {
    setFilters({})
  }

  useEffect(() => {
    fetchPartners()
  }, [])

  useEffect(() => {
    fetchOffers()
  }, [page, filters])

  useEffect(() => {
    document.title = 'Control Room - Piani compenso'
  }, [])

  return (
    <PianiCompensoPresentational
      currentPermissions={currentPermissions}
      offers={data}
      loading={loading}
      partners={partners}
      partnersLoading={partnersLoading}
      onSetFilters={onSetFilters}
      onClearFilters={onClearFilters}
      onPageChange={onPageChange}
      totalResults={totalResults}
      resultsPerPage={resultsPerPage}
    />
  )
}

export default PianiCompenso
