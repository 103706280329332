// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useForm, FormProvider } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../context/Auth'
import { createLabel, list } from '../../../lib/fetch/roles'
//import { useLoading } from '../../../context/LoadingContext';
import NuovoRuoloPresentational from './NuovoRuoloPresentational'
import { Navigate } from 'react-router-dom'

function NuovoRuolo({ currentPermissions, location }) {
  const canWrite = currentPermissions.can('write', 'roles')
  // const { showLoading, hideLoading } = useLoading();
  const [auth = {}] = useAuth()
  const { token } = auth
  const formContext = useForm()
  const [roles, setRoles] = useState([])
  const intl = useIntl()
  const alert = useAlert()

  const fetchRoles = async () => {
    const { error, data } = await list(token)

    if (error) {
      setRoles([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    setRoles(data)
  }

  async function onSubmit(values) {
    const { name, roleId } = values

    // showLoading();
    const response = await createLabel({
      data: {
        name,
        roleId
      },
      token
    })

    // hideLoading();
    if (!response) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    formContext.reset()
  }

  useEffect(() => {
    document.title = 'Control Room - Nuovo Ruolo'
  }, [])

  useEffect(() => {
    // showLoading()
    Promise.all([fetchRoles()]).finally()
  }, [])

  if (!canWrite) {
    let pathname = '/'
    if (location && location.pathname) {
      pathname = location.pathname.split('/')
      pathname.pop()
      pathname = pathname.join('/')
    }
    return <Navigate push={false} to={{ pathname }} />
  }

  return (
    <FormProvider {...formContext}>
      <NuovoRuoloPresentational
        currentPermissions={currentPermissions}
        onSubmit={onSubmit}
        roles={roles}
      />
    </FormProvider>
  )
}

export default NuovoRuolo
