import axios from 'axios'
import { AuthenticatedQuery } from '../../interfaces/PaginatedQuery'

export interface ListSalesChannelsParams {
  code?: string
}

export const list = async ({
  token,
  ...params
}: AuthenticatedQuery<ListSalesChannelsParams> = {}) => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/salesChannels`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: params
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

interface GetSalesChannelParams {
  id: number
}

export const get = async ({
  id,
  token
}: AuthenticatedQuery<GetSalesChannelParams>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/salesChannels/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreateSalesChannelParams {
  data: any
}

export const create = async ({
  data,
  token
}: AuthenticatedQuery<CreateSalesChannelParams>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/salesChannels`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface SaveSalesChannelParams {
  id: number
  data: any
}

export const save = async ({
  id,
  data,
  token
}: AuthenticatedQuery<SaveSalesChannelParams>) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/salesChannels/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface RemoveSalesChannelParams {
  id: number
}

export const remove = async ({
  id,
  token
}: AuthenticatedQuery<RemoveSalesChannelParams>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/salesChannels/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
