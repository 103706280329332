// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'

import { useIntl } from 'react-intl'
import { css } from '@emotion/react'
import { useAuth } from '../../context/Auth'
import { EstrattoContoTable } from './table/EstrattoContoTable'
import { KTCard } from '../../../_metronic/helpers'

function EstrattiContoTable({
  loadingBalances = false,
  balances = [],
  balancesCount,
  resultsPerPage,
  onBalancesPageChange,
  currentPermissions,
  onDownloadBalance
}: any) {
  const intl = useIntl()

  const [{ profile }]: any = useAuth()

  const clipLoaderCss = css`
    height: 20px;
    width: 20px;
  `

  return (
    <>
      <KTCard
        className="mb-8"
        style={{
          padding: '1rem',
          boxShadow: '0 0 0 1px rgb(0 0 0 / 5%)',
          borderRadius: '0.5rem'
        }}>
        <EstrattoContoTable data={balances} />
      </KTCard>
    </>
  )
}

export default EstrattiContoTable
