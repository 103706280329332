import React, { useState } from 'react'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { UsersListHeader } from './components/header/UsersListHeader'
import { UsersTable } from './table/UsersTable'
import { UserEditModal } from './user-edit-modal/UserEditModal'
import { KTCard } from '../../../../_metronic/helpers'

const UsersList = () => {
  const { itemIdForUpdate } = useListView()
  const [filters, setFilters] = useState({})
  const handleFilterApplied = (filter: object) => {
    setFilters(filter)
  }
  const handleFilterReset = () => {
    setFilters({})
  }
  return (
    <>
      <KTCard>
        <UsersListHeader
          onFilterApplied={handleFilterApplied}
          onFilterReset={handleFilterReset}
        />
        <UsersTable filters={filters} />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const UsersListWrapper = () => (
  <ListViewProvider>
    <UsersList />
  </ListViewProvider>
)

export { UsersListWrapper }
