// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect } from 'react'
//import {useIntl} from 'react-intl'
import { FormProvider, useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../../context/Auth'
import { create } from '../../../../lib/fetch/statuses'
//import { useLoading } from '../../../../context/LoadingContext';
import NuovoEsitoPresentational from './NuovoEsitoPresentational'
import { useNavigate, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

function NuovoEsito({ currentPermissions, location }) {
  const canWrite = currentPermissions.can('write', 'statuses')
  const [auth = {}] = useAuth()
  //const { showLoading, hideLoading } = useLoading();
  const { token } = auth
  const formContext = useForm()
  const alert = useAlert()
  const intl = useIntl()
  const history = useNavigate()

  async function onSubmit(values) {
    const { name, workflow } = values
    //showLoading();
    const { error } = await create({
      data: {
        name,
        workflow
      },
      token
    })

    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    formContext.reset()
    history(-1)
  }

  useEffect(() => {
    document.title = 'Control Room - Nuovo Esito Lavorazione'
  }, [])

  if (!canWrite) {
    let pathname = '/'
    if (location && location.pathname) {
      pathname = location.pathname.split('/')
      pathname.pop()
      pathname = pathname.join('/')
    }
    return <Navigate push={false} to={{ pathname }} />
  }

  return (
    <FormProvider {...formContext}>
      <NuovoEsitoPresentational
        currentPermissions={currentPermissions}
        onSubmit={onSubmit}
      />
    </FormProvider>
  )
}

export default NuovoEsito
