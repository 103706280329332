import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../../context/Auth'
import { attachToAdmin, detachFromAdmin } from '../../../../lib/fetch/policies'
import { useLoading } from '../../../../context/LoadingContext'
import ModificaAdminPresentational from './ModificaAdminPresentational'
import { useIntl } from 'react-intl'
import {
  useLazyGetUserQuery,
  useUpdateUserMutation
} from '../../../../store/api/users'
import { useGetAllPoliciesQuery } from '../../../../store/api/policies'

function ModificaUtente({ currentPermissions }: any) {
  const alert = useAlert()
  const [auth = {}] = useAuth()
  const { showLoading, hideLoading } = useLoading()
  const { token } = auth
  const history = useNavigate()
  const { id: idParam } = useParams()
  if (!idParam) {
    history(-1)
  }
  const id = parseInt(idParam ?? '0')
  const intl = useIntl()
  const [getUser, { data: user }] = useLazyGetUserQuery()
  const { data: policies } = useGetAllPoliciesQuery({})
  const [updateUser, { isSuccess }] = useUpdateUserMutation()

  const handleUpdateUser = async (values: any) => {
    updateUser(values)
  }

  const handleSavePolicies = async (values: any) => {
    //TODO: implement
    console.log('handleSavePolicies', values)
  }

  async function onAddPolicy(policyId: number) {
    showLoading()
    //@ts-ignore
    const { error } = await attachToAdmin({
      token,
      data: {
        id_policy: policyId,
        //@ts-ignore
        id_admin: user.id
      }
    })
    hideLoading()

    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    history(-1)
  }

  async function onRemovePolicy(policyId: number) {
    showLoading()

    //@ts-ignore
    const { error } = await detachFromAdmin({
      token,
      data: {
        id_policy: policyId,
        //@ts-ignore
        id_admin: user.id
      }
    })
    hideLoading()

    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    history(-1)
  }

  useEffect(() => {
    getUser(id)
    document.title = 'Control Room - Modifica Admin'
  }, [])

  useEffect(() => {
    if (isSuccess) {
      history(-1)
    }
  }, [isSuccess, history])

  return (
    <ModificaAdminPresentational
      policies={policies}
      onSubmit={handleUpdateUser}
      onAddPolicy={onAddPolicy}
      onRemovePolicy={onRemovePolicy}
      currentPermissions={currentPermissions}
      user={user}
      onSavePolicies={handleSavePolicies}
    />
  )
}

export default ModificaUtente
