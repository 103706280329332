import React, { useCallback, useEffect, useRef } from 'react'
import { useMemo } from 'react'
import { posizioniFiscaliColumns } from './columns/_columns'
import { FiscalPosition } from '../core/_models'
import GenericTable, {
  GenericTableHandle
} from '../../../../components/GenericTable'
import { useAuth } from '../../../../context/Auth'
import {
  PaginatedResponse,
  PaginationMeta
} from '../../../../interfaces/PaginatedResponse'
import { list } from '../../../../lib/fetch/fiscalPositions'

interface PosizioniFiscaliTableProps {
  filters: any
}

const PosizioniFiscaliTable = ({ filters }: PosizioniFiscaliTableProps) => {
  useEffect(() => {
    console.log(filters)
  }, [filters])
  const ref = useRef<GenericTableHandle>(null)
  const refetch = useCallback(() => {
    ref.current?.refetch()
  }, [ref])
  const columns = useMemo(() => posizioniFiscaliColumns(refetch), [refetch])
  const [{ token }] = useAuth()
  const fetch = useCallback(
    async (
      paginationMeta: PaginationMeta
    ): Promise<PaginatedResponse<FiscalPosition> | string> => {
      let result
      try {
        result = await list({
          token,
          page: paginationMeta.currentPage,
          limit: paginationMeta.itemsPerPage,
          ...filters
        })
      } catch (error) {
        let message = 'Error'
        if (error instanceof Error) {
          message = error.message
        }
        result = message
      }
      return result
    },
    [filters, token]
  )

  return <GenericTable columns={columns} onFetch={fetch} ref={ref} />
}

export { PosizioniFiscaliTable }
