import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useAuth as useAuthoriz } from '../../../../../../context/Auth'
import { useIntl } from 'react-intl'
import { useQueryResponseData } from '../../core/QueryResponseProvider'
import moment from 'moment'
import generateXlsx from '../../../../../../utils/generateXLSX'
import { openFile } from '../../../../../../utils/download'
import { downloadDocument } from '../../../../../../lib/fetch/users'
import isAgent from '../../../../../../utils/isAgent'
import PageTitle from '../../../../../../components/Typography/PageTitle'

const UsersListToolbar = ({
  currentPermissions
}: {
  currentPermissions: any
}) => {
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const canWrite = currentPermissions.can('write', 'users')
  const canRead = currentPermissions.can('read', 'users')
  const [auth = {}] = useAuthoriz()
  const { token, currentUser }: any = auth
  const intl = useIntl()

  async function onDownloadExcel() {
    try {
      const users = data.map(user => ({
        id: user.id,
        created_at: moment(user.created_at).format('DD/MM/YYYY HH:mm'),
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        role: user.roles && user.roles.description
      }))
      const columns = {
        id: 'ID',
        created_at: 'REGISTRAZIONE',
        username: 'USERNAME',
        first_name: 'NOME',
        last_name: 'COGNOME',
        email: 'EMAIL',
        role: 'QUALIFICA'
      }
      await generateXlsx(`Agenti_${moment().toISOString()}`, columns, users)
    } catch (error) {
      console.log(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    } finally {
    }
  }

  async function onOpenDocument(document: any) {
    const { id } = document
    const { error, headers, data }: any = await downloadDocument({
      token,
      id
    })

    if (error) {
      console.log(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    try {
      /*const fileName = headers['content-disposition']
         .split(';')[1]
         .split('=')[1]
         .replace('"', '')
         .replace('"', '');*/
      const fileType = headers['content-type']
      openFile(fileType, data)
    } catch (error) {
      console.log(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
  }

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10" style={{ width: '100%' }}>
        <div className="flex items-center justify-between">
          <PageTitle>
            {intl.formatMessage({ id: 'GESTISCI_UTENZE.TITLE' })}
          </PageTitle>
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base">
            {false && canRead && (
              <button
                type="button"
                className="btn btn-light-primary me-3"
                onClick={onDownloadExcel}>
                <KTSVG
                  path="/media/icons/duotune/arrows/arr078.svg"
                  className="svg-icon-2"
                />
                {intl.formatMessage({ id: 'COMMON.DOWNLOAD_EXCEL' })}
              </button>
            )}
            {canWrite && !isAgent(currentUser) && (
              <Link to="/agenzia/gestisci-agenti/nuovo-utente">
                <button type="button" className="btn btn-primary">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {intl.formatMessage({ id: 'GESTISCI_UTENZE.NEW_AGENT' })}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export { UsersListToolbar }
