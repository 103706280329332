import { skipToken } from '@reduxjs/toolkit/query'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import PageTitle from '../../../components/Typography/PageTitle'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { CircularProgress, Stack } from '@mui/material'
import {
  ResponseStatusDto,
  useGetContractQuery,
  useUpdateContractMutation
} from '../../../store/api/contracts'
import useLogin from '../../../hooks/useLogin'
import { useAlert } from 'react-alert'
import { ResponseUserDto } from '../../../store/api/users'
import { ResponseOfferDto } from '../../../store/api/offers'
import NullableProprieties from '../../../helpers/typescript/NullableProprieties'
import { useForm } from 'react-hook-form'
import NonNullableProprieties from '../../../helpers/typescript/NonNullableProprieties'
import UserSection from '../components/UserSection'
import OfferSection from '../components/OfferSection'
import CustomerSection from '../components/CustomerSection'
import AttachmentsSection from '../components/AttachmentsSection'
import DataSection from '../components/DataSection'
import { DateTime } from 'luxon'

export type NewContractFormStaus = NullableProprieties<{
  user: ResponseUserDto
  offer: ResponseOfferDto
  status: ResponseStatusDto
  contractDate: string
  podPdr: string
  code: string
}>

export type ContractParams = {
  contractId: string
}

export default () => {
  const intl = useIntl()
  const history = useNavigate()
  const alert = useAlert()
  const { isLogged, can } = useLogin()

  const { contractId } = useParams<ContractParams>()

  const canSetUser = useMemo(
    () =>
      isLogged &&
      can('Get', 'users@*') &&
      (can('Create', 'contracts@all') || can('Create', 'contracts@agency')),
    [isLogged, can]
  )
  const canSeeAttachments = useMemo(
    () => isLogged && can('Get', 'contracts|attachments'),
    [isLogged, can]
  )
  const canSeeNotes = useMemo(
    () => isLogged && can('Get', 'contracts|notes'),
    [isLogged, can]
  )

  const {
    error,
    isLoading,
    data: contract
  } = useGetContractQuery(contractId ? parseInt(contractId) : skipToken)
  useEffect(() => {
    if (!error) return
    if (!('status' in error)) return
    if (error.status !== 404) return
    alert.error('Contratto non trovato')
    history(-1)
  }, [error])

  const form = useForm<NewContractFormStaus>({
    defaultValues: {
      status: null,
      user: null,
      offer: null,
      contractDate: null,
      podPdr: null,
      code: null
    }
  })

  const a = form.watch()
  useEffect(() => {
    console.log(a)
  }, [a])

  useEffect(() => {
    if (!contract) return
    form.reset({
      ...contract,
      contractDate: DateTime.fromISO(contract.contractDate).toISODate()
    })
  }, [contract])

  const [updateContract, updateContractStatus] = useUpdateContractMutation()

  useEffect(() => {
    if (!updateContractStatus.isSuccess) return
    alert.success('Contratto modificato con successo')
    setReadonly(true)
  }, [updateContractStatus])

  const onSubmit = useCallback(
    ({
      offer,
      status,
      user,
      code,
      podPdr,
      contractDate
    }: NonNullableProprieties<NewContractFormStaus>) => {
      if (!contractId) return
      updateContract({
        contractId: parseInt(contractId),
        updateContractDto: {
          userId: user.id,
          offerId: offer.id,
          statusId: status.id,
          code,
          podPdr,
          contractDate
        }
      })
    },
    [contractId, updateContract]
  )

  const [readonly, setReadonly] = useState(true)

  return (
    <>
      <form
        onSubmit={form.handleSubmit(d =>
          onSubmit(d as NonNullableProprieties<NewContractFormStaus>)
        )}>
        <div className="d-flex items-center justify-content-between g-5 g-xl-10 mb-5 mb-xl-10 w-100">
          <div className="d-flex items-center g-5">
            <Button
              className="lightblue-btn rounded-full mr-3"
              onClick={() => history(-1)}>
              <ArrowLeftIcon style={{ width: '1.5rem', height: '1.5rem' }} />
            </Button>

            <PageTitle>
              {intl.formatMessage({ id: 'CONTRACT.TITLE' })}
            </PageTitle>
          </div>

          <div className="d-flex items-center g-5">
            {readonly && (
              <Button
                className="rounded-full mr-3 fs-6 text-white"
                onClick={() => setReadonly(false)}>
                <i className="bi bi-pencil-square fs-2 pr-2 text-white" />
                Modifica
              </Button>
            )}
            {!readonly && (
              <>
                <Button
                  disabled={updateContractStatus.isLoading}
                  className="rounded-full mr-3 fs-6 text-white"
                  style={{ padding: '0' }}
                  onClick={() => {
                    form.reset()
                    setReadonly(true)
                  }}>
                  <i className="text-2xl bi bi-arrow-counterclockwise fs-2 pr-2 text-white" />
                  Annulla
                </Button>
                <Button
                  disabled={updateContractStatus.isLoading}
                  className="rounded-full mr-3 fs-6 text-white"
                  style={{ padding: '0' }}
                  type="submit">
                  <i className="text-2xl bi bi-box-arrow-down fs-2 pr-2 text-white" />
                  Salva
                </Button>
              </>
            )}
          </div>
        </div>
        {contract ? (
          <Stack spacing={2}>
            <CustomerSection customerId={contract.customer.id} />

            <UserSection form={form} readonly={readonly || !canSetUser} />

            <OfferSection form={form} readonly={readonly} />

            <DataSection form={form} readonly={readonly} />

            {canSeeAttachments && contract && (
              <AttachmentsSection
                contractAttachments={contract.attachments}
                contractId={contract.id}
              />
            )}
          </Stack>
        ) : isLoading ? (
          <div className="h-96 w-full flex justify-center items-center">
            <CircularProgress size={40} />
          </div>
        ) : (
          <></>
        )}
      </form>
    </>
  )
}
