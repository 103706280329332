// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import { removeEmptyFields } from '../../../lib/helpers/formatters'
import { useAuth } from '../../../context/Auth'
import { useLoading } from '../../../context/LoadingContext'
import { downloadJob, listJobs } from '../../../lib/fetch/contracts'
import { uploadContractJob } from '../../../lib/fetch/uploads'
import ElaborazioneDatiPresentational from './ElaborazioneDatiPresentational'
import { downloadFile } from '../../../utils/download'

const resultsPerPage = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT || 10

function ElaborazioneDati({ currentPermissions }) {
  const [{ token } = {}] = useAuth()
  const alert = useAlert()
  const intl = useIntl()
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({})
  const [data, setData] = useState([])
  const [totalResults, setTotalResults] = useState(0)
  const [loading, setLoading] = useState(false)
  const { showLoading, hideLoading } = useLoading()

  async function fetchJobs() {
    setLoading(true)
    setData([])
    const { error, data = {} } = await listJobs({
      token,
      page: page,
      limit: resultsPerPage,
      ...filters
    })

    if (error) {
      setLoading(false)
      setTotalResults(0)
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    setLoading(false)
    setTotalResults((data.meta && data.meta.totalItems) || 0)
    setData(data.items)
  }

  async function onDownloadJob(id) {
    showLoading()
    const { error, headers, data } = await downloadJob({
      token,
      id
    })

    hideLoading()
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    try {
      const fileName = headers['content-disposition']
        .split(';')[1]
        .split('=')[1]
        .replace('"', '')
        .replace('"', '')
      const fileType = headers['content-type']
      downloadFile(fileName, fileType, data)
    } catch (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      console.log(error)
      return
    }
  }

  async function onUploadXls(file) {
    setLoading(true)
    const { error, file: fileResponse } = await uploadContractJob({
      file,
      token
    })

    setLoading(false)
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.UPLOAD_SUCCESS' }))
    fetchJobs()
  }

  function onPageChange(p) {
    setPage(p)
  }

  function onSetFilters(filters) {
    const { partner, ...others } = filters
    if (!partner) return setFilters(removeEmptyFields(others))
    setFilters(removeEmptyFields(filters))
  }

  function onClearFilters() {
    setFilters({})
  }

  useEffect(() => {
    fetchJobs()
  }, [page, filters])

  useEffect(() => {
    document.title = 'Control Room - Elaborazione dati'
  }, [])

  return (
    <ElaborazioneDatiPresentational
      currentPermissions={currentPermissions}
      jobs={data}
      loading={loading}
      onSetFilters={onSetFilters}
      onClearFilters={onClearFilters}
      onPageChange={onPageChange}
      totalResults={totalResults}
      resultsPerPage={resultsPerPage}
      onDownloadJob={onDownloadJob}
      onUploadXls={onUploadXls}
    />
  )
}

export default ElaborazioneDati
