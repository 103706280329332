import React, { useState, useEffect } from 'react'
import PageTitle from '../../components/Typography/PageTitle'
import { CardBody } from '@windmill/react-ui'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useAlert } from 'react-alert'
import { useForm } from 'react-hook-form'
import { list as listPartners } from '../../lib/fetch/partners'
import { list as listChannels } from '../../lib/fetch/salesChannels'
import { useAuth } from '../../context/Auth'
import ClipLoader from 'react-spinners/ClipLoader'
import { removeEmptyFields } from '../../lib/helpers/formatters'
import { KTSVG } from '../../../_metronic/helpers'
import { KTCard } from '../../../_metronic/helpers'
import { OfferteArticoliTable } from './OfferteArticoli/table/OfferteArticoliTable'
import { SalesChannel } from '../../interfaces/SalesChannel'

export const canWriteOffersContext = React.createContext<boolean>(false)

function OfferteArticoli({ currentPermissions }: any) {
  const intl = useIntl()
  const canWrite = currentPermissions.can('write', 'offers')
  const [{ token }] = useAuth()
  const alert = useAlert()
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      partner: [],
      name: '',
      audience: 'all',
      salesChannel: ''
    }
  })
  const [filters, setFilters] = useState({})
  const [partners, setPartners] = useState([])
  const [salesChannels, setSalesChannels] = useState([])

  const [loading, setLoading] = useState(false)
  const [partnersLoading, setPartnersLoading] = useState(false)

  async function fetchChannels() {
    setLoading(true)
    setSalesChannels([])
    try {
      const data = await listChannels({ token })
      setSalesChannels(data.items)
    } catch (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    } finally {
      setLoading(false)
    }
  }

  async function fetchPartners() {
    setPartnersLoading(true)
    let result
    try {
      result = await listPartners({ token })
    } catch (error) {
      setPartnersLoading(false)
      setPartners([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    if (!result) return
    setPartners(result.items)
    setPartnersLoading(false)
  }

  useEffect(() => {
    console.log(salesChannels)
  }, [salesChannels])

  function onSetFilters(filters: any) {
    const { partner, ...others } = filters
    if (!partner) return setFilters(removeEmptyFields(others))
    setFilters(removeEmptyFields(filters))
  }

  function onClearFilters() {
    reset()
    setFilters({})
  }

  useEffect(() => {
    document.title = 'Control Room - Offerte articoli'
    fetchPartners()
    fetchChannels()
  }, [])

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="flex items-center justify-between">
          <PageTitle>{intl.formatMessage({ id: 'OFFERS.TITLE' })}</PageTitle>
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base">
            {false && (
              <button
                type="button"
                className="btn btn-light-primary me-3"
                // onClick={onDownloadExcel}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr078.svg"
                  className="svg-icon-2"
                />
                {intl.formatMessage({ id: 'COMMON.DOWNLOAD_EXCEL' })}
              </button>
            )}
            {canWrite && (
              <Link to="/catalogo/offerte-articoli/nuova-offerta">
                <button type="button" className="btn btn-primary">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {intl.formatMessage({ id: 'COMMON.NEW' })}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>

      <KTCard className="mb-8">
        <CardBody>
          <p
            className="mb-4 font-semibold text-gray-500 text-lg"
            style={{ fontSize: '1.125rem', fontWeight: '600' }}>
            {intl.formatMessage({ id: 'COMMON.FILTERS' })}
          </p>

          <label className="mb-1">
            <span>{intl.formatMessage({ id: 'OFFERS.PARTNER' })}</span>
          </label>
          <form onSubmit={handleSubmit(onSetFilters)}>
            <div className="d-flex flex-wrap gap-3 ">
              <ClipLoader color="#3263e9" loading={partnersLoading} />
              {partners &&
                partners.map(({ id, name }) => (
                  <label key={`partner-${id}`}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value={id}
                      {...register('partner')}
                    />
                    <span
                      className="ml-2"
                      style={{
                        marginLeft: '0.25rem'
                      }}>
                      {name}
                    </span>
                  </label>
                ))}
            </div>
            <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-5 items-end mt-2">
              <label>
                <span>{intl.formatMessage({ id: 'COMMON.NAME' })}</span>
                <input
                  type="text"
                  className="form-control form-control-lg mt-1"
                  {...register('name')}
                />
              </label>
              <label>
                <span>{intl.formatMessage({ id: 'OFFERS.BUSINESS' })}</span>
                <select
                  className="form-control form-control-lg mt-1"
                  {...register('audience')}>
                  <option value="all">Tutti</option>
                  <option value="business">
                    {intl.formatMessage({ id: 'COMMON.YES' })}
                  </option>
                  <option value="private">
                    {intl.formatMessage({ id: 'COMMON.NO' })}
                  </option>
                </select>
              </label>
              <label>
                <span>{intl.formatMessage({ id: 'OFFERS.CHANNEL' })}</span>
                <select
                  className="form-control form-control-lg mt-1"
                  {...register('salesChannel')}>
                  <option value="">Tutti</option>
                  {salesChannels &&
                    salesChannels.map((channel: SalesChannel) => (
                      <option key={channel.id} value={channel.id}>
                        {channel.code}
                      </option>
                    ))}
                </select>
              </label>
              <div className="flex mt-5">
                <button
                  type="submit"
                  className="btn btn-primary fw-bold px-6 mr-3">
                  {intl.formatMessage({ id: 'COMMON.FILTER' })}
                </button>
                <button
                  onClick={onClearFilters}
                  style={{ marginLeft: '5px' }}
                  className="btn btn-light btn-active-light-primary fw-bold me-2 px-6 ml-3">
                  {intl.formatMessage({ id: 'COMMON.CLEAR_FILTER' })}
                </button>
              </div>
            </div>
          </form>
        </CardBody>
      </KTCard>
      <KTCard className="mb-8">
        <canWriteOffersContext.Provider value={canWrite}>
          <OfferteArticoliTable filters={filters} />
        </canWriteOffersContext.Provider>
      </KTCard>
    </>
  )
}

export default OfferteArticoli
