import React from 'react'

import { EstrattoContoSubnetTable } from './table_subtable/EstrattoContoSubnetTable'
import { KTCard } from '../../../_metronic/helpers'

function EstrattiContoSubnetTable() {
  return (
    <>
      <KTCard className="mb-8">
        <EstrattoContoSubnetTable />
      </KTCard>
    </>
  )
}

export default EstrattiContoSubnetTable
