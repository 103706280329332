import React from 'react'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { Provider as AlertProvider, positions } from 'react-alert'
import AlertTemplate from './components/AlertTemplate'
import { CookiesProvider } from 'react-cookie'
import { LoadingProvider } from './context/LoadingContext'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { itIT, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_RIGHT}
          timeout={5000}>
          <CookiesProvider>
            <LoadingProvider>
              <LayoutProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={
                    itIT.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }>
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <AuthInit>
                      <Outlet />
                      <MasterInit />
                    </AuthInit>
                  </QueryParamProvider>
                </LocalizationProvider>
              </LayoutProvider>
            </LoadingProvider>
          </CookiesProvider>
        </AlertProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
