import React, { useContext } from 'react'
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { User } from '../../core/_models'
import { useIntl } from 'react-intl'
import { canWritePianiCompenso } from '../../PianiCompensoTable'

type Props = {
  user: User
}

const PianiCompensiActionsCell: FC<Props> = ({ user }) => {
  const canWrite = useContext(canWritePianiCompenso)
  const intl = useIntl()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Link to={`/estratto-conto/piani-compenso/modifica-piano/${user.id}`}>
          <button
            type="button"
            className="btn btn-light-primary me-3"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end">
            <KTSVG
              path="/media/icons/duotune/general/gen055.svg"
              className="svg-icon-2"
            />
            {canWrite
              ? intl.formatMessage({ id: 'PIANI_COMPENSO.EDIT_VALUES' })
              : intl.formatMessage({ id: 'PIANI_COMPENSO.VIEW_VALUES' })}
          </button>
        </Link>
      </div>
    </>
  )
}

export { PianiCompensiActionsCell }
