import React from 'react'
import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, Policies } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { Admin } from '../../../pages/Anagrafica/users-list/core/_models'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: Admin | undefined
  setCurrentUser: Dispatch<SetStateAction<Admin | undefined>>
  policies: Policies | undefined
  setPolicies: Dispatch<SetStateAction<Policies | undefined>>
  user: number | undefined
  setUser: Dispatch<SetStateAction<number | undefined>>
  canWrite: boolean | undefined
  setCanWrite: Dispatch<SetStateAction<boolean | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  policies: undefined,
  setPolicies: () => [],
  user: undefined,
  setUser: () => {},
  canWrite: false,
  setCanWrite: () => false,
  logout: () => {}
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<Admin | undefined>()
  const [policies, setPolicies] = useState<Policies | undefined>()
  const [user, setUser] = useState<number | undefined>()
  const [canWrite, setCanWrite] = useState<boolean | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setPolicies(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        policies,
        setPolicies,
        user,
        setUser,
        canWrite,
        setCanWrite,
        logout
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, setPolicies } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken()
          if (data) {
            setCurrentUser(data?.user)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser(auth.accessToken)
    } else {
      console.error('logout')
      logout()
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}
