// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useMemo } from 'react'
import { TableRow } from '@windmill/react-ui'
import { useIntl } from 'react-intl'
import { css } from '@emotion/react'
import { formatCurrency } from '../../lib/helpers/formatters'
import { KTCardBody, KTCard } from '../../../_metronic/helpers'

function DettaglioEstrattoTable({
  loading = false,
  items = [],
  totalResults,
  resultsPerPage,
  onPageChange,
  currentPermissions
}: {
  loading: any
  items: any
  totalResults: any
  resultsPerPage: any
  onPageChange: any
  currentPermissions: any
}) {
  const intl = useIntl()

  const clipLoaderCss = css`
    height: 20px;
    width: 20px;
  `

  const hasItems = useMemo(() => items.length > 0, [items])

  return (
    <KTCard>
      <KTCardBody className="py-4">
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                <td>
                  {intl.formatMessage({ id: 'DETTAGLIO_ESTRATTO.AGENT' })}
                </td>
                <td>
                  {intl.formatMessage({ id: 'DETTAGLIO_ESTRATTO.CONTRACT' })}
                </td>
                <td>{intl.formatMessage({ id: 'DETTAGLIO_ESTRATTO.ROLE' })}</td>
                <td>
                  {intl.formatMessage({ id: 'DETTAGLIO_ESTRATTO.TOTAL' })}
                </td>
                <td>
                  {intl.formatMessage({ id: 'DETTAGLIO_ESTRATTO.BILLABLE' })}
                </td>
              </tr>
            </thead>

            <tbody className="text-gray-600 fw-bold">
              {/* {loading && (
            <TableRow>
              <td colSpan='100'>
                <ClipLoader color='#3263e9' css={clipLoaderCss} loading={loading} />
              </td>
            </TableRow>
          )} */}
              {hasItems ? (
                items.map(
                  ({
                    id,
                    users = {},
                    taxable = 0,
                    net = 0
                  }: {
                    id: any
                    users: any
                    taxable: any
                    net: any
                  }) => (
                    <TableRow key={`item-${id}`}>
                      <td>
                        <span className="text-sm">
                          {`${users.username} - ${users.first_name} ${users.last_name}`}
                        </span>
                      </td>
                      <td>
                        <span className="text-sm">
                          {users.fiscalPositions && users.fiscalPositions.name}
                        </span>
                      </td>
                      <td>
                        <span className="text-sm">
                          {users.roles && users.roles.name}
                        </span>
                      </td>
                      <td>
                        <span className="text-sm">
                          {formatCurrency(taxable || 0)}
                        </span>
                      </td>
                      <td>
                        <span className="text-sm">
                          {formatCurrency(net || 0)}
                        </span>
                      </td>
                    </TableRow>
                  )
                )
              ) : (
                <tr>
                  <td colSpan="100">
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {intl.formatMessage({ id: 'COMMON.NO_DATA' })}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default DettaglioEstrattoTable
