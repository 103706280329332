import axios from 'axios'
import { AuthenticatedQuery, PSAQuery } from '../../interfaces/PaginatedQuery'
import { PaginatedResponse } from '../../interfaces/PaginatedResponse'
import Status from '../../interfaces/Status'

export const list = async ({
  token,
  ...params
}: PSAQuery): Promise<PaginatedResponse<Status>> => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/statuses`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

interface GetStatusQuery {
  id: number
}

export const get = async ({
  id,
  token
}: AuthenticatedQuery<GetStatusQuery>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/statuses/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreateStatusQuery {
  data: any
}

export const create = async ({
  data,
  token
}: AuthenticatedQuery<CreateStatusQuery>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/statuses`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface RemoveStatusQuery {
  id: number
}

export const remove = async ({
  id,
  token
}: AuthenticatedQuery<RemoveStatusQuery>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/statuses/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
