import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import RoleForm from '../../../components/Roles/RoleForm'

function NuovaQualificaPresentational({
  currentPermissions,
  onSubmit,
  loading
}: any) {
  const canWrite = currentPermissions.can('write', 'roles')
  const intl = useIntl()
  const history = useNavigate()

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>{intl.formatMessage({ id: 'CREATE_ROLE.TITLE' })}</PageTitle>
      </div>
      <RoleForm
        type="create"
        canWrite={canWrite}
        onCreate={onSubmit}
        loading={loading}
      />
    </>
  )
}

export default NuovaQualificaPresentational
