// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React from 'react'
import { Input } from '@windmill/react-ui'

function BonusValue({ canWrite, offer, role, value, onChangeOfferBonus }: any) {
  const onChangeValue = (e: any) => {
    const value = e.target.value
    onChangeOfferBonus(role, value)
  }
  return (
    <div className="flex items-center">
      <Input
        className="rounded-r-none focus:shadow-none"
        type="text"
        value={value}
        onChange={onChangeValue}
        disabled={!canWrite}
      />
      <span className="px-4 py-2 -m-1 mr-2 leading-5 bg-gray-100 rounded-r-md border border-gray-200">
        {offer.value}
      </span>
    </div>
  )
}

export default BonusValue
