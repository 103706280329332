import React, { useCallback, useRef } from 'react'
import { useMemo } from 'react'
import getColumns from './columns'
import {
  GetContractsListApiArg,
  GetContractsListApiResponse,
  useLazyGetContractsListQuery
} from '../../../../store/api/contracts'
import GenericTable, {
  GenericTableHandle
} from '../../../../components/GenericTable'
import { PaginationMeta } from '../../../../interfaces/PaginatedResponse'

export type ContractsTableProps = {
  filters: GetContractsListApiArg
}

export default ({ filters }: ContractsTableProps) => {
  const ref = useRef<GenericTableHandle>(null)

  const refetch = useCallback(() => {
    ref.current?.refetch()
  }, [ref])

  const columns = useMemo(() => getColumns(refetch), [refetch])

  const [getContractsList] = useLazyGetContractsListQuery()

  const fetch = useCallback(
    async (
      paginationMeta: PaginationMeta
    ): Promise<GetContractsListApiResponse | string> => {
      const { data, isSuccess } = await getContractsList({
        page: paginationMeta.currentPage,
        limit: paginationMeta.itemsPerPage,
        ...filters
      })
      if (isSuccess) return data
      return 'ops'
    },
    [filters]
  )

  return <GenericTable columns={columns} onFetch={fetch} ref={ref} />
}
