import React, { useEffect, useMemo, useState } from 'react'
import GenericTable from './Table'
import { Statistics, list as listStatistics } from '../../lib/fetch/statistics'
import { CustomChart } from './CustomChart'
import { SalesChannel } from '../../interfaces/SalesChannel'
import { useAuth } from '../../context/Auth'
import { selectedAgencyOrUser } from '../../interfaces/SelectedAgencyOrUser'
import { useAlert } from 'react-alert'
import { useIntl } from 'react-intl'

type Props = {
  channel: SalesChannel
  startDate: string
  endDate: string
  selectedAgencyOrUser?: selectedAgencyOrUser
  refetchKey?: number
  onChildData: (hasData: boolean) => void
}

//extract the props from generic ContractsTable
export const ChannelInfoLayout = ({
  channel,
  endDate,
  startDate,
  selectedAgencyOrUser,
  refetchKey,
  onChildData
}: Props) => {
  const intl = useIntl()
  const alert = useAlert()
  const initialChartData = [['Canale', 'Valore']]
  const [loading, setLoading] = useState(false)
  const [statistics, setStatistics] = useState<Statistics>()
  // @ts-ignore
  const [{ token } = {}] = useAuth()

  const chartData = useMemo((): (string | number)[][] => {
    const noData = ['Nessun dato', 1]
    if (!statistics || statistics.data.length < 0)
      return [...initialChartData, noData]

    const chartWorkflowIndexes = statistics.workflows
      .filter(workflow => workflow.isValidForTarget)
      .map(workflow => workflow.id)
    const values = statistics.data.map(data => {
      const totalPartnerValue = chartWorkflowIndexes.reduce((acc, index) => {
        return acc + data.values[index]
      }, 0)
      return [data.partner.name, totalPartnerValue]
    })
    if (values.length === 0) return [...initialChartData, noData]
    return [...initialChartData, ...values]
  }, [statistics])

  const fetchStatistics = async () => {
    setLoading(true)
    setStatistics(undefined)
    const response = await listStatistics({
      token,
      startDate: startDate,
      endDate: endDate,
      salesChannels: [channel.id],
      metrics: channel.rankingMetrics,
      agencies:
        selectedAgencyOrUser?.type === 'agency'
          ? [selectedAgencyOrUser.object?.id]
          : undefined,
      users:
        selectedAgencyOrUser?.type === 'user'
          ? [selectedAgencyOrUser.object?.id]
          : undefined
    })
    if ('error' in response) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    setStatistics(response)
    setLoading(false)
  }

  useEffect(() => {
    fetchStatistics()
  }, [refetchKey])

  const hasData = useMemo(() => {
    return (statistics && statistics.data.length > 0) ?? false
  }, [statistics])

  useEffect(() => {
    onChildData(hasData)
  }, [hasData])

  return hasData ? (
    <div
      className="row"
      style={{
        height: '500px',
        marginBottom: '3rem'
      }}>
      <div className="col-6">
        {statistics && (
          <GenericTable
            key={channel.id}
            workflows={statistics.workflows}
            channel={channel}
            data={statistics.data}
          />
        )}
      </div>
      <div
        className="col-6"
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CustomChart chartData={chartData} />
      </div>
    </div>
  ) : null
}
