import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { list } from '../../../../../lib/fetch/customers'
import { useListView } from '../../core/ListViewProvider'
import { UsersFilterProps, UsersListFilter } from './UsersListFilter'
import { useAuth as useAuthoriz } from '../../../../../context/Auth'
import Moment from 'moment'

const UsersListToolbar = ({
  onFilterApplied,
  onFilterReset
}: UsersFilterProps) => {
  const { setItemIdForUpdate } = useListView()
  const [auth = {}] = useAuthoriz()
  const { token }: any = auth

  async function exportCSV() {
    const data: any = await list({ token, csv: true })
    if (data?.status === 200) {
      const blob = new Blob([data.data], { type: 'text/csv' })

      const a = document.createElement('a')
      a.download = `Export_clienti_${Moment(new Date()).format('DD/MM/YYYY')}`
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  }

  return (
    <div
      className="d-flex justify-content-end"
      data-kt-user-table-toolbar="base">
      <UsersListFilter
        onFilterApplied={onFilterApplied}
        onFilterReset={onFilterReset}
      />

      {/* begin::Export */}
      <button
        type="button"
        onClick={exportCSV}
        className="btn btn-light-primary me-3">
        <KTSVG
          path="/media/icons/duotune/arrows/arr078.svg"
          className="svg-icon-2"
        />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <Link to="/customers/new">
        <button type="button" className="btn btn-primary">
          <KTSVG
            path="/media/icons/duotune/arrows/arr075.svg"
            className="svg-icon-2"
          />
          Aggiunta cliente
        </button>
      </Link>
      {/* end::Add user */}
    </div>
  )
}

export { UsersListToolbar }
