import { ColumnDef } from '@tanstack/react-table'
import Workflow from '../../../../../interfaces/Workflow'
import WorkflowsActionCell from './workflowsActionsCell'

export default function workflowsColumns(
  refetch: () => void = () => {}
): ColumnDef<Workflow>[] {
  return [
    {
      header: 'Nome',
      accessorKey: 'name'
    },
    {
      header: 'Incluso in statistiche',
      accessorFn: row => RenderBoolean(row.isValidForStatistics)
    },
    {
      header: 'Incluso nel target',
      accessorFn: row => RenderBoolean(row.isValidForTarget)
    },
    {
      header: 'Incluso nel ranking',
      accessorFn: row => RenderBoolean(row.isValidForRanking)
    },
    {
      header: 'Azioni',
      cell: ({ row }) => (
        <WorkflowsActionCell workflow={row.original} onDeleteUser={refetch} />
      )
    }
  ]
}

const RenderBoolean = (value?: boolean) => {
  return value ? 'Si' : 'No'
}
