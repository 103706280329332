import { Chart } from 'react-google-charts'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import React from 'react'

export const CustomChart = ({
  chartData
}: {
  chartData: (string | number)[][]
}) => {
  const { mode } = useThemeMode()

  const optionsChart = {
    is3D: true,

    backgroundColor: 'transparent',
    legend: {
      textStyle: {
        color: mode === 'dark' ? '#fff' : '#000'
      }
    }
  }

  return (
    <div
      className="card"
      style={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        flex: '2'
      }}>
      <div className="card-header pt-5">
        <h3 className="card-title text-gray-800 fw-bold">Grafico</h3>
      </div>
      <div className="card-body">
        <Chart
          chartType="PieChart"
          className="StyledChart"
          width="100%"
          height="100%"
          data={chartData}
          options={optionsChart}
        />
      </div>
    </div>
  )
}
