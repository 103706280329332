import React from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import UploadImage from './UploadImage'

const OfferForm = ({
  image,
  onSetImage,
  onPartnerChange = () => {},
  canWrite,
  onSubmit,
  channels,
  partners
}: any) => {
  const intl = useIntl()
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <div className="card mb-5 mb-xl-10">
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <fieldset disabled={!canWrite}>
          <div className="card-body pt-3">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'OFFER_FORM.PARTNER' })}
              </label>

              <div className="col-lg-8 fv-row">
                <select
                  className="form-select form-select-solid form-select-lg"
                  {...register('partnerId', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                  onChange={(e: any) => onPartnerChange(e.target.value)}
                  defaultValue="">
                  <option disabled value="" />
                  {partners.map(({ id, name }: any) => (
                    <option key={`partner-${id}`} value={id}>
                      {name}
                    </option>
                  ))}
                </select>

                {errors.partnerId && errors.partnerId.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.partnerId.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'OFFER_FORM.CHANNEL' })}
              </label>

              <div className="col-lg-8 fv-row">
                <select
                  className="form-select form-select-solid form-select-lg"
                  {...register('channelId', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                  defaultValue="">
                  <option disabled value="" />
                  {channels.map(
                    ({ id, description }: { id: any; description: any }) => (
                      <option key={`partner-${id}`} value={id}>
                        {description}
                      </option>
                    )
                  )}
                </select>
                {errors.channelId && errors.channelId.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.channelId.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'OFFER_FORM.CHANNEL' })}
              </label>

              <div className="col-lg-8 fv-row">
                <select
                  className="form-select form-select-solid form-select-lg"
                  {...register('channelId', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                  defaultValue="">
                  <option disabled value="" />
                  {channels.map(
                    ({ id, description }: { id: any; description: any }) => (
                      <option key={`partner-${id}`} value={id}>
                        {description}
                      </option>
                    )
                  )}
                </select>
                {errors.channelId && errors.channelId.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.channelId.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.NAME' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('name', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                    maxLength: {
                      value: 100,
                      message: `Massimo 100 caratteri `
                    }
                  })}
                />
                {errors.name && errors.name.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.name.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.IMAGE' })}
              </label>

              <div className="col-lg-8 fv-row">
                <UploadImage
                  defaultImage={image}
                  onImageSelected={file => onSetImage(file)}
                />
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'OFFER_FORM.VALUE' })}
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  className="form-control w-150px form-control-lg form-control-solid"
                  type="number"
                  step=".01"
                  {...register('value', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                />
                {errors.value && errors.value.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.value.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'OFFER_FORM.BUSINESS' })}
              </label>

              <div className="col-lg-8 fv-row">
                <select
                  className="form-select form-select-solid form-select-lg mt-1"
                  {...register('business', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}
                  defaultValue="false">
                  <option value="true">
                    {intl.formatMessage({ id: 'COMMON.YES' })}
                  </option>
                  <option value="false">
                    {intl.formatMessage({ id: 'COMMON.NO' })}
                  </option>
                </select>
                {errors.business && errors.business.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.business.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: 'COMMON.ACTIVE' })}
              </label>

              <div className="col-lg-8 fv-row">
                <select
                  className="form-select form-select-solid form-select-lg mt-1"
                  {...register('status', {
                    required: intl.formatMessage({ id: 'COMMON.REQUIRED' })
                  })}>
                  <option value="true">
                    {intl.formatMessage({ id: 'COMMON.YES' })}
                  </option>
                  <option value="false">
                    {intl.formatMessage({ id: 'COMMON.NO' })}
                  </option>
                </select>
                {errors.status && errors.status.message && (
                  <span className="text-xs text-red-400 mt-4">
                    {errors.status.message.toString()}
                  </span>
                )}
              </div>
            </div>
          </div>
        </fieldset>
        {canWrite && (
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              {intl.formatMessage({ id: 'COMMON.SAVE' })}
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default OfferForm
