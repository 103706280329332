// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { useIntl } from 'react-intl'
import { useAuth } from '../../../context/Auth'
import DettaglioEstrattoPresentational from './DettaglioEstrattoPresentational'
import { useLoading } from '../../../context/LoadingContext'
import { get, getItems, update } from '../../../lib/fetch/accountBalances'

const resultsPerPage = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT || 20

function DettaglioEstratto({ currentPermissions }) {
  const intl = useIntl()
  const alert = useAlert()
  const { showLoading, hideLoading } = useLoading()
  const [{ token } = {}] = useAuth()
  const { id } = useParams()
  const [items, setItems] = useState([])
  const [balance, setBalance] = useState()
  const [page, setPage] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [loading, setLoading] = useState(false)

  const fetchBalance = async () => {
    showLoading()
    const { error, data } = await get({
      token,
      id
    })

    hideLoading()
    if (error) {
      setBalance()
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    const { balance } = data
    setBalance(balance)
  }

  const fetchBalanceItems = async () => {
    setLoading(true)
    const { error, data } = await getItems({
      token,
      id,
      page: page,
      limit: resultsPerPage
    })

    setLoading(false)
    if (error) {
      setTotalResults(0)
      setItems([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    const { items = [] } = data
    setTotalResults((data.meta && data.meta.totalItems) || 0)
    setItems(items)
  }

  const onPublishBalance = async () => {
    const { error } = await update({
      id,
      token,
      data: {
        public: true
      }
    })
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    fetchBalanceItems()
  }

  function onPageChange(p) {
    setPage(p)
  }

  useEffect(() => {
    fetchBalance().then(() => fetchBalanceItems())
  }, [page])

  useEffect(() => {
    document.title = 'Control Room - Dettaglio estratto'
  }, [])

  return (
    <DettaglioEstrattoPresentational
      currentPermissions={currentPermissions}
      items={items}
      balance={balance}
      loading={loading}
      onPageChange={onPageChange}
      totalResults={totalResults}
      onPublishBalance={onPublishBalance}
      resultsPerPage={resultsPerPage}
    />
  )
}

export default DettaglioEstratto
