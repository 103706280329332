import React, { useMemo } from 'react'
import { Data, Workflow } from '../../lib/fetch/statistics'
import { SalesChannel } from '../../interfaces/SalesChannel'
import './table.css'
export interface GenericTableProps {
  workflows: Workflow[]
  channel: SalesChannel
  data?: Data[]
}

const GenericTable: React.FC<GenericTableProps> = ({
  workflows,
  channel,
  data
}) => {
  const totalRow = useMemo(() => {
    if (!data) return
    return workflows.map(workflow => {
      return (
        <td key={workflow.id}>
          {data.reduce((acc, row) => acc + row.values[workflow.id], 0)}
        </td>
      )
    })
  }, [data, workflows])

  const headerRow = useMemo(() => {
    return workflows.map((column, index) => (
      <th key={index} scope="col">
        {column.name}
      </th>
    ))
  }, [workflows])

  const bodyRows = useMemo(() => {
    if (!data) return
    return data.map((row, index) => (
      <tr key={row.partner.id}>
        <td>{row.partner.name}</td>
        {workflows.map(workflow => (
          <td key={workflow.id}>{row.values[workflow.id]}</td>
        ))}
      </tr>
    ))
  }, [data, workflows])

  return (
    <div className="card card_container">
      <div className="card-header card_header pt-5">
        <h3 className="card-title text-gray-800 fw-bold">{channel.code}</h3>
        <div className="card-toolbar" />
      </div>
      <div className="card-body main-wrapper">
        <div className="table-responsive responsive-table">
          <table className="table table-striped table">
            <thead>
              <tr>
                <th scope="col">Partner</th>
                {headerRow}
              </tr>
            </thead>
            <tbody className="table_body">
              {bodyRows}
              <tr>
                <td>Totale</td>
                {totalRow}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default GenericTable
