import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {
  ResponseWorkflowDto,
  useDeleteWorkflowMutation
} from '../../../../../store/api/workflows'
import { useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useIntl } from 'react-intl'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { useAuth } from '../../../../../context/Auth'

type Props = {
  workflow: ResponseWorkflowDto
  onDeleteUser: () => void
}

export default function WorkflowsActionCell({ workflow, onDeleteUser }: Props) {
  const intl = useIntl()
  const alert = useAlert()
  const [canWrite] = useAuth()

  const [deleteWorkflow, { data, isLoading, error, isSuccess }] =
    useDeleteWorkflowMutation()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleDeleteUser = (id: number) => {
    deleteWorkflow(id)
  }

  useEffect(() => {
    if (isSuccess) {
      onDeleteUser()
      alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    }
  }, [isSuccess])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {canWrite && (
          <Link to={`/workflows/${workflow.id}`}>
            <button
              type="button"
              className="btn btn-light-primary me-3"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end">
              <KTSVG
                path="/media/icons/duotune/general/gen055.svg"
                className="svg-icon-2"
              />
              Modifica
            </button>
          </Link>
        )}
        <button
          type="button"
          className="btn btn-light-danger"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-users-table-filter="delete_row"
          data-bs-toggle="modal"
          data-bs-target={'#kt_modal_2' + workflow.id}>
          <KTSVG
            path="/media/icons/duotune/general/gen027.svg"
            className="svg-icon-2"
          />
          Elimina
        </button>
      </div>
      <div className="modal fade" tabIndex={-1} id={'kt_modal_2' + workflow.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Elimina workflow</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>Sei sicuro di voler eliminare il workflow {workflow.name}?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleDeleteUser(workflow.id)
                }}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
