import React from 'react'
import { FC } from 'react'
import { User } from '../../core/_models'
import { useIntl } from 'react-intl'
import { MONTHS } from '../../../../lib/constants'

type Props = {
  user: User
}

const EstrattiContoDate: FC<Props> = ({ date }: any) => {
  const intl = useIntl()
  return (
    <>
      <p>
        {MONTHS.find(({ en }) => en === date?.month)?.it} {date?.year}
      </p>
    </>
  )
}

export { EstrattiContoDate }
