import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import { setupListeners } from '@reduxjs/toolkit/query'
import api from './api'
import auth, { invalidateAuth } from './auth'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
  auth,
  [api.reducerPath]: api.reducer
})

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 1,
    storage,
    blacklist: ['api']
  },
  rootReducer
) as unknown as typeof rootReducer

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(api.middleware)
      .prepend(invalidateAuth.middleware)
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
