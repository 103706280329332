import React, { useRef } from 'react'
import { useMemo } from 'react'
import { estrattiContoColumns } from './columns/_columns'
import { useAuth } from '../../../context/Auth'
import { GenericTableHandle } from '../../GenericTable'

const EstrattoContoBackofficeTable = () => {
  const ref = useRef<GenericTableHandle>(null)
  const columns = useMemo(() => estrattiContoColumns, [])
  const [{ token }] = useAuth()

  return <></>
}

export { EstrattoContoBackofficeTable }
