import React from 'react'
import ListaQualifiche from './ListaQualifiche'

export default function ListaQualificheRuoli({ currentPermissions }: any) {
  return (
    <>
      <ListaQualifiche currentPermissions={currentPermissions} />
    </>
  )
}
