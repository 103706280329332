// @ts-nocheck
import React from 'react'
import { Column } from 'react-table'
import { EstrattiContoActionsCell } from './EstrattiContoActionsCell'
import { UserCustomHeader } from './UserCustomHeader'
import { UserTwoStepsCell } from './UserTwoStepsCell'
import { EstrattiContoDate } from './EstrattiContoDate'
import Moment from 'moment'

const estrattiContoColumns: ReadonlyArray<Column<any>> = [
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Data"
        className="min-w-125px"
      />
    ),
    accessor: 'date',
    Cell: ({ ...props }) => (
      <EstrattiContoDate date={props.data[props.row.index]} />
    )
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Effettuato il"
        className="min-w-125px"
      />
    ),
    accessor: 'created_at',
    Cell: ({ ...props }) =>
      Moment(props.data[props.row.index]?.created_at).format('DD-MM-YYYY')
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Index inseriti"
        className="min-w-125px"
      />
    ),
    accessor: 'contractsCount'
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Stato"
        className="min-w-125px"
      />
    ),
    accessor: 'status',
    Cell: ({ ...props }) => (
      <UserTwoStepsCell two_steps={props.data[props.row.index]?.status} />
    )
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="P.IVA"
        className="min-w-125px"
      />
    ),
    accessor: 'public',
    Cell: ({ ...props }) => {
      return props.data[props.row.index]?.public ? 'Pubblicato' : 'Privato'
    }
  },
  {
    Header: props => (
      <UserCustomHeader
        tableProps={props}
        title="Azioni"
        className="text-end min-w-100px"
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) =>
      props.data[props.row.index]?.status ? (
        <EstrattiContoActionsCell user={props.data[props.row.index]} />
      ) : (
        <></>
      )
  }
]

export { estrattiContoColumns }
