import React from 'react'
import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { QueryClient, QueryClientProvider } from 'react-query'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './app/assets/css/custom.scss'
import './app/assets/css/tailwind.css'
import './app/assets/css/index.css'

import myTheme from './app/myTheme'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { Windmill } from '@windmill/react-ui'
import StoreProvider from './app/store/StoreProvider'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <StoreProvider>
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <AuthProvider>
            <Windmill theme={myTheme}>
              <AppRoutes />
            </Windmill>
          </AuthProvider>
        </MetronicI18nProvider>
      </QueryClientProvider>
    </StoreProvider>
  )
}
