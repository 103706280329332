import { api } from './index'
export const addTagTypes = ['Offers'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      createOffer: build.mutation<CreateOfferApiResponse, CreateOfferApiArg>({
        query: queryArg => ({
          url: `/api/offers`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Offers']
      }),
      getOffersList: build.query<GetOffersListApiResponse, GetOffersListApiArg>(
        {
          query: queryArg => ({
            url: `/api/offers`,
            params: {
              page: queryArg.page,
              limit: queryArg.limit,
              name: queryArg.name,
              description: queryArg.description,
              partner: queryArg.partner,
              salesChannel: queryArg.salesChannel,
              audience: queryArg.audience,
              ordering: queryArg.ordering,
              sort: queryArg.sort,
              csv: queryArg.csv
            }
          }),
          providesTags: ['Offers']
        }
      ),
      getOffer: build.query<GetOfferApiResponse, GetOfferApiArg>({
        query: queryArg => ({ url: `/api/offers/${queryArg}` }),
        providesTags: ['Offers']
      }),
      updateOffer: build.mutation<UpdateOfferApiResponse, UpdateOfferApiArg>({
        query: queryArg => ({
          url: `/api/offers/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.createOfferDto
        }),
        invalidatesTags: ['Offers']
      }),
      uploadOfferImage: build.mutation<
        UploadOfferImageApiResponse,
        UploadOfferImageApiArg
      >({
        query: queryArg => ({
          url: `/api/offers/uploads/image`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Offers']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type CreateOfferApiResponse = /** status 200  */ ResponseOfferDto
export type CreateOfferApiArg = CreateOfferDto
export type GetOffersListApiResponse =
  /** status 200  */ PaginatedResponseDto & {
    items?: ResponseOfferDto[]
  }
export type GetOffersListApiArg = {
  page?: number
  limit?: number
  name?: string
  description?: string
  partner?: number[]
  salesChannel?: number[]
  audience?: string
  ordering?: string
  sort?: string
  csv?: boolean
}
export type GetOfferApiResponse = /** status 200  */ ResponseOfferDto
export type GetOfferApiArg = number
export type UpdateOfferApiResponse = /** status 200  */ ResponseOfferDto
export type UpdateOfferApiArg = {
  id: number
  createOfferDto: CreateOfferDto
}
export type UploadOfferImageApiResponse = /** status 200  */ ResponseOfferDto
export type UploadOfferImageApiArg = UploadOfferImageDto
export type ResponsePartnerDto = {
  id: number
  name: string
  status: boolean
  image: string
}
export type ResponseSalesChannelDto = {
  id: number
  code: string
  description: string
  image: string
  rankingMetrics: string
  isRankingValueVisible: boolean
  isRankingVisible: boolean
}
export type ResponseOfferDto = {
  id: number
  name: string
  status: boolean
  business: boolean
  image: string
  value: number
  partner: ResponsePartnerDto
  salesChannel: ResponseSalesChannelDto
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type CreateOfferDto = {
  name: string
  status: boolean
  business: boolean
  image: string
  value: number
  per_agent_values: string
  partnerId: number
  id_sales_channel: number
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  items: string[]
  meta: IPaginationMetaResponseDto
}
export type UploadOfferImageDto = {
  id_offer: number
  file: Blob
}
export const {
  useCreateOfferMutation,
  useGetOffersListQuery,
  useLazyGetOffersListQuery,
  useGetOfferQuery,
  useLazyGetOfferQuery,
  useUpdateOfferMutation,
  useUploadOfferImageMutation
} = injectedRtkApi
