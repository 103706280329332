import {
  PayloadAction,
  createListenerMiddleware,
  createSlice,
  isAnyOf
} from '@reduxjs/toolkit'
import api from './api'
import { GetMeApiResponse } from './api/me'

type State = {
  token?: string
  User?: GetMeApiResponse
}

const { reducer, actions } = createSlice({
  name: 'authentication',
  initialState: {} as State,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    resetAuth: () => ({
      token: undefined,
      user: undefined
    }),
    setUser: (state, action: PayloadAction<GetMeApiResponse>) => {
      state.User = action.payload
    }
  }
})

export const { setToken, resetAuth, setUser } = actions

export default reducer

export const invalidateAuth = createListenerMiddleware()
invalidateAuth.startListening({
  matcher: isAnyOf(actions.setToken, actions.resetAuth),
  effect: (_, { dispatch }) => {
    dispatch(api.util.invalidateTags(['auth']))
  }
})
