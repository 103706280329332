// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect, useState } from 'react'
import PageTitle from '../../components/Typography/PageTitle'
import { Button } from '@windmill/react-ui'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { Navigate, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { FormProvider, useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../context/Auth'
import { list as listPartners } from '../../lib/fetch/partners'
import { list as listChannels } from '../../lib/fetch/salesChannels'
import { create } from '../../lib/fetch/offers'
import OfferForm from '../../components/OfferForm'
import { uploadOfferImage } from '../../lib/fetch/uploads'

function NuovaOfferta({ currentPermissions, location }) {
  const canWrite = currentPermissions.can('write', 'offers')
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  // const {showLoading, hideLoading} = useLoading()
  const alert = useAlert()
  const history = useNavigate()
  const [auth = {}] = useAuth()
  const { token } = auth
  const formContext = useForm()
  //const [partnerId] = useQueryParam('partnerId', NumberParam);
  const [partnerId] = []
  const [partners, setPartners] = useState([])
  const [channels, setChannels] = useState([])
  const [image, setImage] = useState(null)
  const [imageFile, setImageFile] = useState(null)

  async function fetchPartners() {
    let result
    try {
      result = await listPartners({ token })
    } catch (error) {
      setPartners([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    setPartners(result.items)
  }

  async function fetchChannels() {
    setLoading(true)
    setChannels([])
    try {
      const data = await listChannels({ token })
      setChannels(data.items)
    } catch (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    } finally {
      setLoading(false)
    }
  }

  function getPartnerImage(partnerId) {
    if (!partners.length) return null
    return partners.find((({ id }) => id === partnerId) || {}).image
  }

  async function onSubmit(values) {
    const { name, status, business, value, partnerId, channelId } = values

    // showLoading()
    const { error, data } = await create({
      data: {
        name,
        status: status === 'true',
        business: business === 'true',
        value: +value,
        partnerId,
        id_sales_channel: channelId
      },
      token
    })

    // hideLoading()
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    const { id } = data
    imageFile && onUploadImage(imageFile, id)
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    formContext.reset()
    history('/catalogo/offerte-articoli')
  }

  async function onUploadImage(file, id) {
    const { error } = await uploadOfferImage({
      token,
      offerId: id,
      file
    })
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.UPLOAD_IMAGE_ERROR' }))
      return
    }
    setImage(null)
  }
  /*
  function onPartnerChange(partnerId) {
    setImage(getPartnerImage(Number(partnerId)))
  }*/

  /*  useEffect(() => {
    if (!partnerId) return
    formContext.setValue('partnerId', partnerId)
    setImage(getPartnerImage(partnerId))
    
  }, [partnerId, partners])*/

  useEffect(() => {
    fetchPartners()
    fetchChannels()
  }, [])

  useEffect(() => {
    document.title = 'Control Room - Nuova Offerta'
  }, [])

  if (!canWrite && location?.pathname) {
    let pathname = location.pathname.split('/')
    pathname.pop()
    pathname = pathname.join('/')
    return <Navigate push={false} to={{ pathname }} />
  }

  return (
    <>
      <div className="flex items-center">
        <Button
          icon={ArrowLeftIcon}
          className="lightblue-btn rounded-circle mr-4"
          onClick={() => history(-1)}
        />
        <PageTitle>
          {intl.formatMessage({ id: 'CREATE_OFFER.TITLE' })}
        </PageTitle>
      </div>
      <FormProvider {...formContext}>
        <OfferForm
          image={image}
          onSetImage={file => setImageFile(file)}
          canWrite={canWrite}
          partners={partners}
          channels={channels}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </>
  )
}

export default NuovaOfferta
