import React, { useState, useContext, createContext } from 'react'
import { useIntl } from 'react-intl'
import { WindmillSpinnerOverlay } from 'react-spinner-overlay'

const LoadingContext = createContext({
  showLoading: () => {},
  hideLoading: () => {},
  blocking: false
})

const LoadingProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const intl = useIntl()
  const [blocking, setBlocking] = useState(false)

  const showLoading = () => setBlocking(true)
  const hideLoading = () => setBlocking(false)

  const loadingContext = {
    showLoading,
    hideLoading,
    blocking
  }

  return (
    <LoadingContext.Provider value={loadingContext}>
      <WindmillSpinnerOverlay loading={blocking} size={50} />
      {children}
    </LoadingContext.Provider>
  )
}

const useLoading = () => useContext(LoadingContext)

export { LoadingProvider, useLoading }
