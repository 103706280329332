import React, { useContext } from 'react'
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { canWriteOffersContext } from '../../../OfferteArticoli'
import Offer from '../../../../../interfaces/Offer'

type Props = {
  offer: Offer
}

const OfferteArticoliActionsCell: FC<Props> = ({ offer }: Props) => {
  const canWrite = useContext(canWriteOffersContext)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      {canWrite && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Link to={`/catalogo/offerte-articoli/modifica-offerta/${offer.id}`}>
            <button
              type="button"
              className="btn btn-light-primary me-3"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end">
              <KTSVG
                path="/media/icons/duotune/general/gen055.svg"
                className="svg-icon-2"
              />
              Modifica
            </button>
          </Link>
        </div>
      )}
    </>
  )
}

export { OfferteArticoliActionsCell }
