import { ResponseUserDto, UpdateUserApiArg } from '../store/api/users'

export const userToUpdateUser = (user: ResponseUserDto): UpdateUserApiArg => {
  return {
    id: user.id,
    updateUserDto: {
      zipCode: user.zipCode,
      address: user.address,
      // budget: user.budget,
      city: user.city,
      businessName: '',
      email: user.email,
      firstName: user.firstName,
      fiscalCode: user.fiscalCode,
      iban: user.iban,
      agencyId: user.agency?.id,
      fiscalPositionId: user.fiscalPosition?.id,
      // parentId: user.sponsor?.id,
      lastName: user.lastName,
      mobile: '', // user.mobile is not present in the ResponseUserDto
      province: user.province,
      salesChannelIds: user.salesChannels.map(sc => sc.id),
      status: user.status,
      roleId: user.role?.id
    }
  }
}
