import React from 'react'
import { KTCard, KTCardBody } from '../../_metronic/helpers'

function DataTile({ title, value }: { title: any; value: any }) {
  return (
    <KTCard className="mb-8">
      <KTCardBody className="mb-2 flex-1">
        <div className="flex items-center justify-between w-fulls">
          <div className="flex items-center">
            <div className="">
              <p className="font-medium text-sm text-gray-500 font-bold">
                {title}
              </p>
              <p className="text-3xl">{value}</p>
            </div>
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default DataTile
