import { useEffect, useMemo } from 'react'
import useDispatch from './useDispatch'
import useSelector from './useSelector'
import { GetMeApiResponse, useGetMeQuery } from '../store/api/me'
import api from '../store/api'
import Permission from '../interfaces/Permission'
import Capability from '../interfaces/Capability'
import WildcardCapability, {
  wildcardCapability
} from '../interfaces/WildcardCapability'
import { resetAuth } from '../store/auth'
import { NeverImplements } from '../helpers/typescript'

export type can = (
  permission: Permission,
  capability: Capability | WildcardCapability
) => boolean

type LoggedStatus = {
  user: GetMeApiResponse
  can: can
  logout: () => void
}

export type LoginStatus =
  | ({
      isLogged?: false
    } & NeverImplements<LoggedStatus>)
  | ({
      isLogged: true
    } & LoggedStatus)

export default (): LoginStatus => {
  const token = useSelector(state => state.auth.token)

  const dispatch = useDispatch()

  useEffect(() => {
    if (token === undefined) dispatch(api.util.resetApiState())
  }, [token])

  const getMeQuery = useGetMeQuery(undefined, { skip: token === undefined })

  return useMemo<LoginStatus>(() => {
    if (token === undefined)
      return {
        isLogged: false
      }

    if (
      getMeQuery.isLoading ||
      getMeQuery.isUninitialized ||
      getMeQuery.isError
    )
      return {
        isLogged: undefined
      }

    return {
      isLogged: true,
      user: getMeQuery.data,
      can: ((permission, capability) => {
        const capabilities = getMeQuery.data.capabilities

        if (capabilities === undefined) return false

        if (wildcardCapability.includes(capability as WildcardCapability)) {
          const r = new RegExp(`^${capability.replace('*', '.*')}$`)
          return Object.entries(capabilities)
            .filter(([c]) => r.test(c))
            .some(([_, p]) => p.includes(permission))
        } else {
          if (capabilities?.[capability as Capability])
            return capabilities?.[capability as Capability]?.includes(
              permission
            )
        }

        return false
      }) as can,
      logout: () => dispatch(resetAuth())
    }
  }, [getMeQuery, token])
}
