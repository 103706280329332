import React, { useState } from 'react'
import { FC, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { Link } from 'react-router-dom'
import { Admin } from '../../core/_models'
import { useIntl } from 'react-intl'
import { remove, save } from '../../../../lib/fetch/users'
import { useAuth as useAuthoriz } from '../../../../context/Auth'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../../modules/auth'
type Props = {
  admin: Admin
  onDelete?: () => void
  onChangeUserStatus?: () => void
}

const UserActionsCell: FC<Props> = ({
  admin,
  onChangeUserStatus,
  onDelete
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { query } = useQueryResponse()

  const queryClient = useQueryClient()
  const intl = useIntl()
  const [auth = {}] = useAuthoriz()
  const { token }: any = auth
  const alert = useAlert()
  const { canWrite, currentUser } = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  async function handleChangeUserStatus(user: any) {
    const response = await save({
      data: {
        status: !user.status
      },
      id: user.id,
      token
    })
    if (!response) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    onChangeUserStatus && onChangeUserStatus()
  }

  async function onDeleteUser(id: number) {
    const response = await remove({ id, token })

    if (!response) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }
    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    onDelete && onDelete()
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Link to={`/agenzia/back-office/modifica-utente/${admin.id}`}>
          <button
            type="button"
            className="btn btn-light-primary me-3"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end">
            <KTSVG
              path="/media/icons/duotune/general/gen055.svg"
              className="svg-icon-2"
            />
            {canWrite ? 'Modifica' : 'Dettaglio'}
          </button>
        </Link>
        <>
          {canWrite ? (
            <button
              type="button"
              className="btn btn-light-primary me-3"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-users-table-filter="edit_row"
              data-bs-toggle="modal"
              data-bs-target={'#kt_modal_1' + admin.id}>
              <KTSVG
                path="/media/icons/duotune/general/gen040.svg"
                className="svg-icon-2"
              />
              {admin.status ? 'Disattiva' : 'Attiva'}
            </button>
          ) : (
            <></>
          )}
          <button
            type="button"
            className="btn btn-light-danger"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-users-table-filter="delete_row"
            data-bs-toggle="modal"
            onClick={() => setDeleteModalOpen(true)}
            data-bs-target={'#kt_modal_2' + admin.id}>
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-2"
            />
            Elimina
          </button>
        </>
      </div>
      <div className="modal fade" tabIndex={-1} id={'kt_modal_1' + admin.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {admin.status ? 'Disabilita' : 'Abilita'} agente
              </h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>
                Sei sicuro di voler {admin.status ? 'disattivare' : 'attivare'}{' '}
                l'agente {admin.username} {admin.first_name} {admin.last_name}?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleChangeUserStatus(admin)
                }}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id={'kt_modal_2' + admin.id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Elimina agente</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>
                Sei sicuro di voler eliminare l'agente {admin.username}{' '}
                {admin.first_name}
                {admin.last_name}?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal">
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  admin.id && onDeleteUser(admin.id)
                }}>
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { UserActionsCell }
