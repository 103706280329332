import { api } from './index'
export const addTagTypes = ['Workflows'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      createWorkflow: build.mutation<
        CreateWorkflowApiResponse,
        CreateWorkflowApiArg
      >({
        query: queryArg => ({
          url: `/api/workflows`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Workflows']
      }),
      getWorkflowsList: build.query<
        GetWorkflowsListApiResponse,
        GetWorkflowsListApiArg
      >({
        query: queryArg => ({
          url: `/api/workflows`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            name: queryArg.name
          }
        }),
        providesTags: ['Workflows']
      }),
      getWorkflow: build.query<GetWorkflowApiResponse, GetWorkflowApiArg>({
        query: queryArg => ({ url: `/api/workflows/${queryArg}` }),
        providesTags: ['Workflows']
      }),
      updateWorkflow: build.mutation<
        UpdateWorkflowApiResponse,
        UpdateWorkflowApiArg
      >({
        query: queryArg => ({
          url: `/api/workflows/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.createWorkflowDto
        }),
        invalidatesTags: ['Workflows']
      }),
      deleteWorkflow: build.mutation<
        DeleteWorkflowApiResponse,
        DeleteWorkflowApiArg
      >({
        query: queryArg => ({
          url: `/api/workflows/${queryArg}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Workflows']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type CreateWorkflowApiResponse = /** status 200  */ ResponseWorkflowDto
export type CreateWorkflowApiArg = CreateWorkflowDto
export type GetWorkflowsListApiResponse =
  /** status 200  */ PaginatedResponseDto & {
    items?: ResponseWorkflowDto[]
  }
export type GetWorkflowsListApiArg = {
  page?: number
  limit?: number
  name?: string
}
export type GetWorkflowApiResponse = /** status 200  */ ResponseWorkflowDto
export type GetWorkflowApiArg = number
export type UpdateWorkflowApiResponse = /** status 200  */ ResponseWorkflowDto
export type UpdateWorkflowApiArg = {
  id: number
  createWorkflowDto: CreateWorkflowDto
}
export type DeleteWorkflowApiResponse = unknown
export type DeleteWorkflowApiArg = number
export type ResponseWorkflowDto = {
  id: number
  name: string
  isValidForStatistics: boolean
  isValidForTarget: boolean
  isValidForRanking: boolean
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type CreateWorkflowDto = {
  name: string
  isValidForStatistics?: boolean
  isValidForTarget?: boolean
  isValidForRanking?: boolean
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  items: string[]
  meta: IPaginationMetaResponseDto
}
export const {
  useCreateWorkflowMutation,
  useGetWorkflowsListQuery,
  useLazyGetWorkflowsListQuery,
  useGetWorkflowQuery,
  useLazyGetWorkflowQuery,
  useUpdateWorkflowMutation,
  useDeleteWorkflowMutation
} = injectedRtkApi
