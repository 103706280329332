import { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { list as listAgencies } from '../../lib/fetch/agencies'
import { list } from '../../lib/fetch/salesChannels'
import { useAuth } from '../../context/Auth'
import moment from 'moment'
import { ChannelInfoLayout } from './ChannelInfoLayout'
import { Ranking } from './Ranking'
import PageTitle from '../../components/Typography/PageTitle'
import { DashboardFilters } from './Filters'
import { Agency } from '../../interfaces/Agecies'
import { SalesChannel } from '../../interfaces/SalesChannel'
import { useAlert } from 'react-alert'
import { selectedAgencyOrUser } from '../../interfaces/SelectedAgencyOrUser'

const DashboardPage: FC = () => {
  const intl = useIntl()
  const alert = useAlert()
  const [{ token }] = useAuth()
  const [salesChannels, setSalesChannels] = useState<SalesChannel[]>([])
  const [agencies, setAgencies] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedAgencyOrUser, setSelectedAgencyOrUser] = useState<
    selectedAgencyOrUser | undefined
  >()
  const [refetchKey, setRefetchKey] = useState(0)
  const [hasChildData, setHasChildData] = useState<boolean>(false)
  const [startDate, setStartDate] = useState(
    moment().startOf('month').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    moment().endOf('month').format('YYYY-MM-DD')
  )

  const fetchSalesChannels = async () => {
    setLoading(true)
    let result
    try {
      result = await list({
        token
      })
    } catch (error) {
      setLoading(false)
      setSalesChannels([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    }
    if (!result) return
    setLoading(false)
    setSalesChannels(result.items)
  }

  const fetchAgencies = async () => {
    setLoading(true)
    let result
    try {
      result = await listAgencies({ token })
    } catch (error) {
      setLoading(false)
      setAgencies([])
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
    }
    setLoading(false)
    setAgencies(result.items)
  }

  useEffect(() => {
    fetchSalesChannels()
    fetchAgencies()
  }, [])

  const refetchStatistics = () => {
    setRefetchKey(refetchKey + 1)
    setHasChildData(false)
  }

  const orderedSalesChannels = useMemo(() => {
    return salesChannels.sort((a, b) => a.id - b.id)
  }, [salesChannels])

  const handleChildData = (hasData: boolean) => {
    if (hasData) setHasChildData(true)
  }

  return (
    <>
      {/* TODO add loading component */}
      <PageTitle>{intl.formatMessage({ id: 'DASHBOARD.BEST' })}</PageTitle>
      <div className="row">
        {orderedSalesChannels &&
          orderedSalesChannels
            .filter(channel => channel.isRankingVisible)
            .map(channel => <Ranking key={channel.id} channel={channel} />)}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '2rem'
        }}>
        <PageTitle>{intl.formatMessage({ id: 'DASHBOARD.STATS' })}</PageTitle>
        <button
          data-bs-toggle="modal"
          data-bs-target="#filtersModal"
          className="btn btn-primary mb-5">
          {intl.formatMessage({ id: 'DASHBOARD.FILTERS' })}
        </button>
      </div>
      <DashboardFilters
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        fetchData={refetchStatistics}
        agencies={agencies as Agency[]}
        onSelectedObject={object => {
          setSelectedAgencyOrUser(object)
        }}
        selectedObject={selectedAgencyOrUser}
      />
      {orderedSalesChannels &&
        orderedSalesChannels.map(channel => (
          <ChannelInfoLayout
            key={channel.id}
            channel={channel}
            startDate={startDate}
            endDate={endDate}
            selectedAgencyOrUser={selectedAgencyOrUser}
            refetchKey={refetchKey}
            onChildData={handleChildData}
          />
        ))}
      {!hasChildData && (
        <h6 className="mt-5 ml-2">
          {intl.formatMessage({ id: 'DASHBOARD.NO_DATA' })}
        </h6>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
