import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '../../../components/Typography/PageTitle'
import { useIntl } from 'react-intl'
import BackofficeUsersTable from '../../../components/Backoffice/BackofficeUsersTable'
import { KTSVG } from '../../../../_metronic/helpers'

function GestisciBackoffice({ currentPermissions }: any) {
  const canWrite = currentPermissions.can('write', 'admins')
  const intl = useIntl()

  useEffect(() => {
    document.title = 'Control Room - Backoffice'
  }, [])

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="flex items-center justify-between">
          <PageTitle>{intl.formatMessage({ id: 'ADMINS.TITLE' })}</PageTitle>
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base">
            {false && (
              <button type="button" className="btn btn-light-primary me-3">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr078.svg"
                  className="svg-icon-2"
                />
                {intl.formatMessage({ id: 'COMMON.DOWNLOAD_EXCEL' })}
              </button>
            )}
            {canWrite && (
              <Link to="/agenzia/back-office/nuovo-utente">
                <button type="button" className="btn btn-primary">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {intl.formatMessage({ id: 'ADMINS.NEW_ADMIN' })}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <BackofficeUsersTable
        {...{
          currentPermissions
        }}
      />
    </>
  )
}

export default GestisciBackoffice
