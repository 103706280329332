import axios from 'axios'
import { AuthenticatedQuery, PSAQuery } from '../../interfaces/PaginatedQuery'

export interface ListAgenciesFilters {
  name?: string
  csv?: boolean
}

export const list = async ({
  token,
  ...filters
}: PSAQuery<ListAgenciesFilters>) => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/agencies`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: filters
  })

  if (response instanceof Error) {
    throw new Error(response.message)
  }

  return response.data
}

interface GetAgency {
  id: number
}

export const get = async ({ id, token }: AuthenticatedQuery<GetAgency>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/agencies/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface GetAgencyUsers {
  id: number
}

export const getAgencyUsers = async ({
  id,
  token
}: AuthenticatedQuery<GetAgencyUsers>) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/agencies/${id}/users`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        limit: 100
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface CreateAgency {
  data: any
}

export const create = async ({
  data,
  token
}: AuthenticatedQuery<CreateAgency>) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/agencies`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface SaveAgency {
  id: number
  data: any
}

export const save = async ({
  id,
  data,
  token
}: AuthenticatedQuery<SaveAgency>) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/agencies/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}

interface RemoveAgency {
  id: number
}

export const remove = async ({
  id,
  token
}: AuthenticatedQuery<RemoveAgency>) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/agencies/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response instanceof Error) {
      return { error: response }
    }

    return response
  } catch (error) {
    // Sentry TODO
    return { error }
  }
}
