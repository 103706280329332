import { api } from './index'
export const addTagTypes = ['Partners'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      createPartner: build.mutation<
        CreatePartnerApiResponse,
        CreatePartnerApiArg
      >({
        query: queryArg => ({
          url: `/api/partners`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Partners']
      }),
      getPartnersList: build.query<
        GetPartnersListApiResponse,
        GetPartnersListApiArg
      >({
        query: queryArg => ({
          url: `/api/partners`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            name: queryArg.name,
            salesChannel: queryArg.salesChannel,
            ordering: queryArg.ordering,
            sort: queryArg.sort,
            csv: queryArg.csv
          }
        }),
        providesTags: ['Partners']
      }),
      getPartner: build.query<GetPartnerApiResponse, GetPartnerApiArg>({
        query: queryArg => ({ url: `/api/partners/${queryArg}` }),
        providesTags: ['Partners']
      }),
      updatePartner: build.mutation<
        UpdatePartnerApiResponse,
        UpdatePartnerApiArg
      >({
        query: queryArg => ({
          url: `/api/partners/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.createPartnerDto
        }),
        invalidatesTags: ['Partners']
      }),
      uploadPartnerImage: build.mutation<
        UploadPartnerImageApiResponse,
        UploadPartnerImageApiArg
      >({
        query: queryArg => ({
          url: `/api/partners/uploads/image`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Partners']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type CreatePartnerApiResponse = /** status 200  */ ResponsePartnerDto
export type CreatePartnerApiArg = CreatePartnerDto
export type GetPartnersListApiResponse =
  /** status 200  */ PaginatedResponseDto & {
    items?: ResponsePartnerDto[]
  }
export type GetPartnersListApiArg = {
  page?: number
  limit?: number
  name?: string
  salesChannel?: number[]
  ordering?: string
  sort?: string
  csv?: boolean
}
export type GetPartnerApiResponse = /** status 200  */ ResponsePartnerDto
export type GetPartnerApiArg = number
export type UpdatePartnerApiResponse = /** status 200  */ ResponsePartnerDto
export type UpdatePartnerApiArg = {
  id: number
  createPartnerDto: CreatePartnerDto
}
export type UploadPartnerImageApiResponse =
  /** status 200  */ ResponsePartnerDto
export type UploadPartnerImageApiArg = UploadPartnerImageDto
export type ResponsePartnerDto = {
  id: number
  name: string
  status: boolean
  image: string
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type CreatePartnerDto = {
  name: string
  status: boolean
  image: string
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  items: string[]
  meta: IPaginationMetaResponseDto
}
export type UploadPartnerImageDto = {
  id_partner: number
  file: Blob
}
export const {
  useCreatePartnerMutation,
  useGetPartnersListQuery,
  useLazyGetPartnersListQuery,
  useGetPartnerQuery,
  useLazyGetPartnerQuery,
  useUpdatePartnerMutation,
  useUploadPartnerImageMutation
} = injectedRtkApi
