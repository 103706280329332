import { SalesChannel } from '../../interfaces/SalesChannel'
import { api } from './index'
export const addTagTypes = ['Users'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
        query: queryArg => ({
          url: `/api/users`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['Users']
      }),
      getUsersList: build.query<GetUsersListApiResponse, GetUsersListApiArg>({
        query: queryArg => ({
          url: `/api/users`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            first_name: queryArg.firstName,
            last_name: queryArg.lastName,
            username: queryArg.username,
            email: queryArg.email,
            ordering: queryArg.ordering,
            sort: queryArg.sort,
            csv: queryArg.csv,
            type: queryArg['type']
          }
        }),
        providesTags: ['Users']
      }),
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: queryArg => ({ url: `/api/users/${queryArg}` }),
        providesTags: ['Users']
      }),
      updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
        query: queryArg => ({
          url: `/api/users/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.updateUserDto
        }),
        invalidatesTags: ['Users']
      }),
      deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
        query: queryArg => ({
          url: `/api/users/${queryArg}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Users']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type CreateUserApiResponse = /** status 200  */ ResponseUserDto
export type CreateUserApiArg = CreateUserDto
export type GetUsersListApiResponse =
  /** status 200  */ PaginatedResponseDto & {
    items?: ResponseUserDto[]
  }
export type GetUsersListApiArg = {
  page?: number
  limit?: number
  search?: string
  firstName?: string
  lastName?: string
  username?: string
  email?: string
  ordering?: string
  sort?: string
  csv?: boolean
  type?: 'all' | 'backOffice' | 'frontOffice'
}
export type GetUserApiResponse = /** status 200  */ ResponseUserDto
export type GetUserApiArg = number
export type UpdateUserApiResponse = /** status 200  */ ResponseUserDto
export type UpdateUserApiArg = {
  id: number
  updateUserDto: UpdateUserDto
}
export type DeleteUserApiResponse = unknown
export type DeleteUserApiArg = number
export type ResponseAgencyDto = {
  id: number
  name: string
  city?: string
  address?: string
  zip_code?: string
  phone?: string
}
export type ResponseRoleDto = {
  id: number
  name: string
  description: string
  isBackOfficeRole: boolean
  policies: string[]
  capabilities?: {
    agencies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statistics?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    ranking?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    roles?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    policies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    workflows?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    partners?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    offers?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    salesChannels?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statuses?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers|surveys'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|notes'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|attachments'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    notes?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    admins?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    fiscalPositions?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'fiscalPositions|prospectus'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    settings?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    balances?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    privacy?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
  }
}
export type FiscalPositions = {
  id: number
}
export type ResponseUserSummaryDto = {
  id: number
  username: string
  firstName: string
  lastName: string
  fiscalCode?: string
  agency: ResponseAgencyDto
}
export type ResponseUserDto = {
  id: number
  username: string
  firstName: string
  lastName: string
  fiscalCode?: string
  agency: ResponseAgencyDto
  createdAt: string
  email: string
  status: boolean
  role: ResponseRoleDto
  budget: number
  iban: string
  province: string
  city: string
  address: string
  zipCode: string
  fiscalPosition: FiscalPositions
  salesChannels: SalesChannel[]
  capabilities?: {
    agencies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statistics?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    ranking?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    roles?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    policies?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    workflows?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    partners?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    offers?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    salesChannels?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    statuses?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'users@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'customers|surveys'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@all'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@agency'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts@mine'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|notes'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'contracts|attachments'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    notes?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    admins?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    fiscalPositions?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    'fiscalPositions|prospectus'?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    settings?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    balances?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
    privacy?: ('Get' | 'Create' | 'Edit' | 'Delete')[]
  }
  sponsor?: ResponseUserSummaryDto
}
export type ErrorResponse = {
  statusCode: number
  message: string
  error?: string
}
export type CreateUserDto = {
  firstName: string
  lastName: string
  email: string
  password: string
  mobile: string
  businessName: string
  fiscalCode: string
  salesChannelIds: number[]
  // budget: number
  province: string
  city: string
  address: string
  zipCode: string
  iban: string
  roleId: number
  fiscalPositionId: number
  agencyId: number
}
export type IPaginationMetaResponseDto = {
  itemCount: number
  totalItems?: number
  itemsPerPage: number
  totalPages?: number
  currentPage: number
}
export type PaginatedResponseDto = {
  items: string[]
  meta: IPaginationMetaResponseDto
}
export type UpdateUserDto = {
  firstName: string
  lastName: string
  email: string
  status: boolean
  salesChannelIds: number[]
  mobile: string
  businessName: string
  fiscalCode?: string
  // budget: number
  province: string
  city: string
  address: string
  zipCode: string
  iban: string
  fiscalPositionId: number
  agencyId: number
  roleId: number
}
export const {
  useCreateUserMutation,
  useGetUsersListQuery,
  useLazyGetUsersListQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation
} = injectedRtkApi
