import React from 'react'
import PageTitle from '../../../components/Typography/PageTitle'
import { useIntl } from 'react-intl'
import EstrattiContoTable from '../../../components/EstrattiConto/EstrattiContoTable'
import { Link } from 'react-router-dom'
import EstrattiContoBackofficeTable from '../../../components/EstrattiConto/EstrattiContoBackofficeTable'
import EstrattiContoSubnetTable from '../../../components/EstrattiConto/EstrattiContoSubnetTable'
import { KTSVG } from '../../../../_metronic/helpers'

function EstrattiContoPresentational({ currentPermissions, isAdmin }: any) {
  const { can } = currentPermissions
  const canWrite = can('write', 'balances')
  const intl = useIntl()

  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="flex items-center justify-between">
          <PageTitle>
            {intl.formatMessage({ id: 'ESTRATTI_CONTO.TITLE' })}
          </PageTitle>
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base">
            {canWrite && isAdmin && (
              <Link to="/estratto-conto/estratti-conto/nuovo-estratto">
                <button type="button" className="btn btn-primary">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {intl.formatMessage({ id: 'ESTRATTI_CONTO.NEW_BALANCE' })}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>

      {isAdmin ? (
        <EstrattiContoBackofficeTable />
      ) : (
        <>
          <h3 className="text-gray-500 font-semibold mb-2">
            {intl.formatMessage({ id: 'ESTRATTI_CONTO.YOUR_BALANCES' })}
          </h3>
          <EstrattiContoTable currentPermissions={currentPermissions} />
          <h3 className="text-gray-500 font-semibold mb-2">
            {intl.formatMessage({ id: 'ESTRATTI_CONTO.YOUR_NETWORK' })}
          </h3>
          <EstrattiContoSubnetTable />
        </>
      )}
    </>
  )
}

export default EstrattiContoPresentational
