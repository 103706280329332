import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './base/baseQuery'

export const api = createApi({
  baseQuery,
  endpoints: () => ({}),
  refetchOnReconnect: true,
  tagTypes: ['auth']
})

export default api
