import React from 'react'
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  ListPaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren
} from '../../../../../_metronic/helpers'
import { getUsers } from './_requests'
import { User } from './_models'
import { useQueryRequest } from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<User>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest()
  const formattedState = {
    page: state.currentPage,
    limit: state.itemsPerPage,
    ...state
  }
  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(formattedState)
  )
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(formattedState),
    [formattedState]
  )
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}`,
    () => {
      return getUsers(query)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.items || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: ListPaginationState = {
    links: {
      first: '',
      previous: '',
      next: '',
      last: ''
    },
    meta: {
      totalItems: 10,
      itemCount: 10,
      itemsPerPage: 10,
      totalPages: 1,
      currentPage: 1
    },
    ...initialQueryState
  }

  const { response } = useQueryResponse()
  if (!response || !response.meta || !response.links) {
    return defaultPaginationState
  }

  return response
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading
}
