import React, { useCallback, useRef } from 'react'
import { useMemo } from 'react'
import { esitiLavorazioneColumns } from './columns/_columns'
import GenericTable, {
  GenericTableHandle
} from '../../../../components/GenericTable'
import {
  PaginatedResponse,
  PaginationMeta
} from '../../../../interfaces/PaginatedResponse'
import { list } from '../../../../lib/fetch/statuses'
import { useAuth } from '../../../../context/Auth'
import Status from '../../../../interfaces/Status'

export const EsitiLavorazioneTable = () => {
  const [{ token }] = useAuth()
  const ref = useRef<GenericTableHandle>(null)
  const refetch = useCallback(() => {
    ref.current?.refetch()
  }, [ref])
  const columns = useMemo(() => esitiLavorazioneColumns(refetch), [refetch])
  const fetch = useCallback(
    async (
      paginationMeta: PaginationMeta
    ): Promise<PaginatedResponse<Status> | string> => {
      let result
      try {
        result = await list({
          token,
          page: paginationMeta.currentPage,
          limit: paginationMeta.itemsPerPage
        })
      } catch (error) {
        let message = 'Error'
        if (error instanceof Error) {
          message = error.message
        }
        result = message
      }
      return result
    },
    [token]
  )

  return <GenericTable columns={columns} onFetch={fetch} ref={ref} />
}
