import React, { useMemo, useState } from 'react'

import AutoSelect, { AutoSelectProps } from '../AutoSelect'
import {
  ResponsePartnerDto,
  useGetPartnersListQuery
} from '../../../store/api/partners'

export type PartnerSelectProps = Pick<
  AutoSelectProps<ResponsePartnerDto>,
  'value' | 'onChange'
> & {
  salesChannelId?: number
}

export default ({ salesChannelId, ...props }: PartnerSelectProps) => {
  const [searchValue, setSearchValue] = useState<string>()

  const args = useMemo(
    () => ({
      ...(searchValue && { name: searchValue }),
      ...(salesChannelId && { salesChannel: [salesChannelId] }),
      limit: 10
    }),
    [searchValue, salesChannelId]
  )

  const { data, isFetching } = useGetPartnersListQuery(args)

  return (
    <AutoSelect
      options={data?.items ?? []}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onSearchChange={setSearchValue}
      loading={isFetching}
      getOptionLabel={o => o.name}
      {...props}
    />
  )
}
