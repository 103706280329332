// 👇️ ts-nocheck ignores all ts errors in the file

// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useForm, FormProvider } from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useAuth } from '../../../context/Auth'
import { save, get } from '../../../lib/fetch/agencies'
//import { useLoading } from '../../../context/LoadingContext';
import { Navigate, useParams, useNavigate } from 'react-router-dom'
import ModificaSedePresentational from './ModificaSedePresentational'

function ModificaSede({ currentPermissions, location }) {
  const canWrite = currentPermissions.can('write', 'nets')
  const alert = useAlert()
  const [auth = {}] = useAuth()
  //const { showLoading, hideLoading } = useLoading();
  const { token } = auth
  const formContext = useForm()
  const { id } = useParams()
  const [agency, setAgency] = useState(null)
  const intl = useIntl()
  const history = useNavigate()
  async function fetchAgency() {
    //showLoading();
    const { error, data } = await get({
      token,
      id
    })

    //hideLoading();
    if (error) {
      setAgency(null)
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    const { agency = {} } = data
    setAgency(agency)
  }

  async function onSubmit(values) {
    const { name, city, address, zip_code, phone } = values

    //showLoading();
    const { error } = await save({
      id,
      data: {
        name,
        city,
        address,
        zip_code,
        phone
      },
      token
    })

    //hideLoading();
    if (error) {
      alert.error(intl.formatMessage({ id: 'COMMON.GENERIC_ERROR' }))
      return
    }

    alert.success(intl.formatMessage({ id: 'COMMON.SUCCESS' }))
    fetchAgency()
    history(-1)
  }

  useEffect(() => {
    if (!agency) return
    formContext.reset({
      name: agency.name,
      city: agency.city,
      phone: agency.phone,
      zip_code: agency.zip_code,
      address: agency.address
    })
  }, [agency])

  useEffect(() => {
    fetchAgency()
  }, [id])

  useEffect(() => {
    document.title = 'Control Room - Modifica sede'
  }, [])

  if (!canWrite) {
    let pathname = '/'
    if (location && location.pathname) {
      pathname = location.pathname.split('/')
      pathname.pop()
      pathname = pathname.join('/')
    }
    return <Navigate push={false} to={{ pathname }} />
  }

  return (
    <FormProvider {...formContext}>
      <ModificaSedePresentational
        currentPermissions={currentPermissions}
        agency={agency}
        onSubmit={onSubmit}
      />
    </FormProvider>
  )
}

export default ModificaSede
